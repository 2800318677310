import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IonDatetime } from '@ionic/angular';
import { STORAGE_CONSTANT } from 'src/app/shared/constants/app.constants';
import { ProductReportFetchApiResponse, TopSellingItem } from 'src/app/shared/models/product-report.model';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { StorageInterface, StorageService } from 'src/app/shared/services/storage.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-report-product',
  templateUrl: './report-product.page.html',
  styleUrls: ['./report-product.page.scss'],
})
export class ReportProductPage implements OnInit {

  currentPage = 1;
  currentLimit = 10;
  startDateFC = new FormControl(null);
  endDateFC = new FormControl(null);
  totalSize: number;
  filterStartDate: any;
  filterEndDate: any;
  salesItemReportData: TopSellingItem[] = [];
  storageData: StorageInterface;
    // eslint-disable-next-line @typescript-eslint/member-ordering
    @ViewChild('startDatePicker') startDatePicker: IonDatetime;
    // eslint-disable-next-line @typescript-eslint/member-ordering
    @ViewChild('endDatePicker') endDatePicker: IonDatetime;
  constructor(
    private _orderService: OrderService,
    private _storageService: StorageService,
    private _uiService: UiService,
    private excelService: ExcelService
  ) {
    const date = new Date().toISOString().split('T')[0];
    const today = new Date();
    const pastWeekStartDate = new Date(today);
    pastWeekStartDate.setDate(today.getDate() - 7);
    const startDate = formatDate(pastWeekStartDate, 'yyyy-MM-dd', 'en-US');
    this.startDateFC.setValue(startDate);
    this.endDateFC.setValue(date);
  }

  ngOnInit() {}

  ionViewWillEnter() {
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res: any) => {
      this.storageData = res;
      this.fetchProductReport();
    });
  }

  // TODO::: Optimize this code
  async fetchProductReport() {
    this._orderService
      .fetchProductReport(this.storageData?.userDetail?.VendorIdPK, {
        ...(this.startDateFC.value && {
          startDate: this.startDateFC.value,
        }),
        ...(this.endDateFC.value && {
          endDate: this.endDateFC.value,
        }),
      })
      .subscribe((responseData: ProductReportFetchApiResponse) => {
        if (responseData.statusCode === 200) {
          this.salesItemReportData = Object.values(
            responseData.data.topSellingItems
          );
        }
      });
  }
  exportAsXLSX(): void {
    if (this.salesItemReportData.length < 1) {
      this._uiService.presentToast('No data available to export.');
      return;
    }
    this.excelService.exportAsExcelFile(
      this.salesItemReportData,
      'export-to-excel'
    );
  }
  onResetFilter() {
    this.startDateFC.reset();
    this.endDateFC.reset();

    if (this.startDatePicker) {
      this.startDatePicker.value = null;
    }

    if (this.endDatePicker) {
      this.endDatePicker.value = null;
    }

    this.fetchProductReport();
  }
}
