import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandler } from './errorhandler.service';
import { environment } from 'src/environments/environment.prod';
import {
  BannerCreateApiResponse,
  BannerDeleteApiResponse,
  BannerFetchApiResponse,
  BannerUpdateApiResponse,
} from '../models/banner.model';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BANNERS_API } from '../constants/apis.constant';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  constructor(private http: HttpClient, private errorHandler: ErrorHandler) {}

  fetchBanner(data?: any): Observable<BannerFetchApiResponse> {
    const params = new HttpParams({ fromObject: data });
    return this.http
      .get<BannerFetchApiResponse>(`${environment.base_url}${BANNERS_API}`, {
        params,
      })
      .pipe(
        map((_) => {
          _.data.forEach((__) => {
            __.media = environment.base_url + __.media;
          });
          return _;
        }),
        catchError(this.errorHandler.handleError)
      );
  }

  createBanner(userData: any): Observable<BannerCreateApiResponse> {
    return this.http
      .post<BannerCreateApiResponse>(
        `${environment.base_url}${BANNERS_API}`,
        userData
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateBanner(
    id: string | number,
    userData: any
  ): Observable<BannerUpdateApiResponse> {
    return this.http
      .patch<BannerUpdateApiResponse>(
        `${environment.base_url}${BANNERS_API}/${id}`,
        userData
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteBanner(id: string | number): Observable<BannerDeleteApiResponse> {
    return this.http
      .delete<BannerDeleteApiResponse>(
        `${environment.base_url}${BANNERS_API}/${id}`
      )
      .pipe(catchError(this.errorHandler.handleError));
  }
}
