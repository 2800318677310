import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { Observable } from 'rxjs';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';
import { FormErrorService } from '../shared/services/form-error.service';
import { FormService } from '../shared/services/form.service';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.page.html',
  styleUrls: ['./auth.page.scss'],
  providers: [AuthService],
})
export class AuthPage implements OnInit {
  showPassword = false;
  loginFormGroup!: FormGroup;
  login$!: Observable<any>;
  storageData: StorageInterface = {} as StorageInterface;
  allowNotifyFC = new FormControl(false);

  constructor(
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _formErrorService: FormErrorService,
    private _formService: FormService,
    private _authService: AuthService,
    private _uiService: UiService,
    private _storageService: StorageService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.getStorageData();
  }

  getStorageData() {
    this._storageService
      .getObject(STORAGE_CONSTANT)
      .then((res: StorageInterface) => {
        if (res) {
          this.storageData = res;
        }
      });
  }

  buildForm(): void {
    this.loginFormGroup = this._formBuilder.group({
      userLoginId: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  async onLogin() {
    this._formService.markFormAsTouched(this.loginFormGroup);
    if (!this.loginFormGroup.valid) {
      return;
    }

    // Getting latest storage in-case fcm token updated late.
    this.storageData = await this._storageService.getObject(STORAGE_CONSTANT);
    if (!this.storageData) this.storageData = {} as StorageInterface; //need to fix this

    if (!this.storageData.fcmToken && this.allowNotifyFC.value) {
      alert('App needs notification permission to continue');
      return;
    }
    if (this.allowNotifyFC.value)
      this.loginFormGroup.value.fcmToken = this.storageData.fcmToken;

    this.login$ = this._authService.userLoginAPI(this.loginFormGroup.value);

    this.login$.subscribe(
      (res) => {
        if (res?.data?.vendorDetail?.isExpired === true) {
          this._storageService.clearStorage();
          this._router.navigate(['/renew-subscription']);
          return;
        }
        if (res?.data?.vendorDetail?.isActive === false) {
          this._uiService.presentToast(
            'Sorry your account is deactivated now.'
          );
          setInterval(() => {
            this.onLogOut();
          }, 2000);
          return;
        }

        this.storageData.token = res.data.token;
        this.storageData.userType = res.data.type;
        //   this.storageData.userDetail = res.data.userDetail;
        //   this.storageData.currency = '₹';
        //   this._storageService.setObject(STORAGE_CONSTANT, this.storageData);
        //   if (res.data.type === 'KITCHEN') {
        //     this._router.navigateByUrl('/kot-app');
        //     this._uiService.presentToast('Login Sucessfully');
        //   } else if (res.data.type === 'EMPLOYEE' || res.data.type === 'VENDOR') {
        //     this._router.navigate(['/home']);
        //     this._uiService.presentToast('Login Sucessfully');
        //   } else {
        //     this._uiService.presentToast('Not a valid user!');
        //   }
        // },
        const userData = res.data.userDetail;
        if (res.data.type === 'VENDOR') {
          this.storageData.userDetail = userData;
        } else if (res.data.type === 'EMPLOYEE') {
          const employeeUserDetail = {
            VendorIdPK: userData.staff.vendor.VendorIdPK,
            UserIdFK: userData.staff.UserIdFK,
            CityIdFK: userData.staff.vendor.CityIdFK,
            vendorCode: userData.staff.vendor.vendorCode,
            vendorQrCode: userData.staff.vendor.vendorQrCode,
            vendorName: userData.staff.vendor.vendorName,
            vendorStoreName: userData.staff.vendor.vendorStoreName,
            vendorStoreType: userData.staff.vendor.vendorStoreType,
            storeTiming: userData.staff.vendor.storeTiming,
            storeIcon: userData.staff.vendor.storeIcon,
            vendorAppType: userData.staff.vendor.vendorAppType,
            serviceType: userData.staff.vendor.serviceType,
            vendorLocation: userData.staff.vendor.vendorLocation,
            vendorLat: userData.staff.vendor.vendorLat,
            vendorLang: userData.staff.vendor.vendorLang,
            status: userData.staff.vendor.status,
            expirationDate: userData.staff.vendor.expirationDate,
            smsLimit: userData.staff.vendor.smsLimit,
            subscriptionType: userData.staff.vendor.subscriptionType,
            creationDate: userData.staff.vendor.creationDate,
            mobile: userData.staff.mobile,
            alternateMobile: userData.staff.vendor.alternateMobile,
            email: userData.staff.vendor.email,
            employeeName: userData.staff.name,
            token: res.data.token,
            preferences: userData.staff.vendor.preferences,
            printWidth: userData.staff.vendor.printWidth,
            deliveryDistance: userData.staff.vendor.deliveryDistance,
            upi: userData.staff.vendor.upi,
            city: userData.staff.vendor.city,
          };
          this.storageData.userDetail = employeeUserDetail;
        }

        this.storageData.currency = '₹';

        if (res.data.type === 'KITCHEN') {
          this.storageData.userDetail = res.data.userDetail;
          this._router.navigateByUrl('/kot-app');
        } else if (
          res.data.type === 'EMPLOYEE' ||
          res.data.type === 'VENDOR' ||
          res.data.type === 'ADMIN'
        ) {
          this._router.navigateByUrl('/home');
        } else {
          this._uiService.presentToast('Not a valid user!');
          return;
        }
        this._storageService.setObject(STORAGE_CONSTANT, this.storageData);

        this._uiService.presentToast('Login Successfully');
      },
      (error) => {
        //console.log(error);
        this._uiService.presentToast(error);
      }
    );
  }
  onLogOut() {
    this._storageService.onLogout(this.storageData);
  }
  public getErrorMessage(fieldName: string, error: string): string {
    return this._formErrorService.getErrorMessage(fieldName, error);
  }
}
