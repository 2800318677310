import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IonModal, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Customer } from '../shared/services/order.service';
import {
  CustomerInterface,
  CustomerService,
} from '../shared/services/customer.service';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.page.html',
  styleUrls: ['./customers.page.scss'],
  providers: [CustomerService],
})
export class CustomersPage implements OnInit {
  customersData: CustomerInterface[] = [];
  @Input() isModal: boolean = false;
  fetchCustomers$: Observable<any>;
  searchFormControl: FormControl = new FormControl(null);
  nickNameFormControl: FormControl = new FormControl(null);
  isModalOpen = false;
  storageData: StorageInterface;
  selectedCustomer!: CustomerInterface;

  constructor(
    private _router: Router,
    private _uiService: UiService,
    private _modal: ModalController,
    private _customerService: CustomerService,
    private _storageService: StorageService
  ) {}
  ionViewWillEnter() {
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res: any) => {
      this.storageData = res;
    });
  }
  ngOnInit() {
    console.log('Modal Data = ', this.isModal);
    this.fetchCustomers();
    this.loadSearchFilter();
  }

  fetchCustomers(searchData?: string) {
    this.fetchCustomers$ = this._customerService.fetchCustomer({
      ...(searchData && { search: searchData }),
      showDetails: true,
    });

    this.fetchCustomers$.subscribe((res) => {
      this.customersData = res;
    });
  }

  loadSearchFilter() {
    this.searchFormControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(1000),
        tap((res) => {
          this.fetchCustomers(res);
        })
      )
      .subscribe();
  }

  goToWatsapp(customer: CustomerInterface) {
    window.open(
      `https://api.whatsapp.com/send?phone=+91${customer.customer.mobile}&text=Hello`
    );
  }

  goToCall(customer: CustomerInterface) {
    window.open('tel:' + customer.customer.mobile);
  }

  dimissModal() {
    this._modal.dismiss();
  }

  selectCustomer(customer: CustomerInterface) {
    if (this.isModal) {
      this._modal.dismiss(customer);
    }
  }
  onEditDetails(customer: CustomerInterface) {
    this.selectedCustomer = customer;
    this.isModalOpen = true;
    this.nickNameFormControl.patchValue(
      customer.customerNameAlias
        ? customer.customerNameAlias
        : customer.customer.name
    );
  }

  closeEditModal() {
    this.isModalOpen = false;
  }
  onSave() {
    this._customerService
      .updateVendorCustomer(this.selectedCustomer.VendorCustomerIdPK, {
        customerNameAlias: this.nickNameFormControl.value,
      })
      .subscribe({
        next: (res) => {
          this._uiService.presentToast('Updated successfully');
          this.closeEditModal();
        },
        error: (errr) => {
          this._uiService.presentToast(errr);
        },
      });
  }
  onGetReport(customer: CustomerInterface) {
    this._router.navigate(['/report/customer-detail'], {
      queryParams: {
        customerId: customer.CustomerIdFK,
      },
    });
  }
}
