import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { FormErrorService } from '../shared/services/form-error.service';
import { FormService } from '../shared/services/form.service';
import { UiService } from '../shared/services/ui.service';
import { StoreService } from '../store/store.service';
import {
  BannerCreateApiResponse,
  BannerInterface,
  BannerUpdateApiResponse,
} from '../shared/models/banner.model';
import { BannerService } from '../shared/services/banner.service';
import { environment } from 'src/environments/environment.prod';
import { StorageInterface } from '../shared/services/storage.service';

@Component({
  selector: 'app-banner-create',
  templateUrl: './banner-create.component.html',
  styleUrls: ['./banner-create.component.scss'],
})
export class BannerCreateComponent implements OnInit {
  @Input() banner: BannerInterface = {} as BannerInterface;
  @Input() mode;
  @Input() storageData: StorageInterface;
  addBannerFormGroup!: FormGroup;
  baseUrl = environment.base_url;
  itemImage: any;
  imageFile: File = {} as File;
  constructor(
    public modalCtrl: ModalController,
    private _formBuilder: FormBuilder,
    private _uiService: UiService,
    private _bannerService: BannerService,
    private _formErrorService: FormErrorService,
    private _formService: FormService,
    private alertController: AlertController
  ) {}

  ngOnInit(): void {
    this.buildForm();
    if (this.banner) {
      this.addBannerFormGroup.patchValue(this.banner);
    }
  }

  buildForm() {
    this.addBannerFormGroup = this._formBuilder.group({
      vendorIdFK: new FormControl(this.storageData.userDetail.VendorIdPK),
      location: new FormControl('', [Validators.required]),
      title: new FormControl('', [Validators.required]),
    });
  }

  addBanner() {
    this._formService.markFormAsTouched(this.addBannerFormGroup);
    if (!this.addBannerFormGroup.valid) {
      this._uiService.presentToast('Enter Valid Data ');
      return;
    }
    const formData = new FormData();
    formData.append('location', this.addBannerFormGroup.value.location);
    formData.append('title', this.addBannerFormGroup.value.title);
    formData.append('vendorId', this.addBannerFormGroup.value.vendorIdFK);
    formData.append('image', this.imageFile);

    if (this.mode === 'EDIT') {
      this.onUpdateBanner(formData);
      return;
    }
    this._bannerService.createBanner(formData).subscribe({
      next: (res: BannerCreateApiResponse) => {
        this._uiService.presentToast('Banner Create sucessfully!');
        this.addBannerFormGroup.reset();
        this.modalCtrl.dismiss(true);
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  onUpdateBanner(formData: any) {
    this._bannerService
      .updateBanner(this.banner.BannerIdPK, formData)
      .subscribe({
        next: (res: BannerUpdateApiResponse) => {
          this.addBannerFormGroup.reset();
          this._uiService.presentToast('Banner Updated sucessfully!');
          this.modalCtrl.dismiss(true);
        },
        error: (err) => {
          this._uiService.presentToast(err);
        },
      });
  }

  public getErrorMessage(fieldName: string, error: string): string {
    return this._formErrorService.getErrorMessage(fieldName, error);
  }
  async onDelete() {
    const confirmationAlert = await this.alertController.create({
      header: 'Confirm Deletion',
      message: 'Are you sure you want to delete this banner?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Deletion cancelled by the user.');
          },
        },
        {
          text: 'Delete',
          handler: () => {
            this._bannerService.deleteBanner(this.banner.BannerIdPK).subscribe({
              next: (res) => {
                this._uiService.presentToast('Deleted Successfully');
                this.modalCtrl.dismiss({ data: true });
              },
              error: (err) => {
                this._uiService.presentToast('Failed to delete banner.');
                console.error(err);
              },
            });
          },
        },
      ],
    });

    await confirmationAlert.present();
  }
  getFileDetail(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.itemImage = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]); // to trigger onload
    }
    this.imageFile = event.target.files[0] as File;
  }

  onImageLoadError(e: any) {
    e.target.src = '../../../assets/images/icons/menu.png';
  }
}
