import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import {
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { STORAGE_CONSTANT } from './shared/constants/app.constants';
import { OrderService } from './shared/services/order.service';
import {
  StorageInterface,
  StorageService,
} from './shared/services/storage.service';
import { App as CapacitorApp } from '@capacitor/app';
import { UiService } from './shared/services/ui.service';
import { filter } from 'rxjs/operators';
// import firebase from 'firebase/compat/app';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewChecked {
  storageData: StorageInterface = {} as StorageInterface;
  audio;
  newOrdersFound: boolean = false;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  currentUrl;
  constructor(
    private _storageService: StorageService,
    private platform: Platform,
    private _orderService: OrderService,
    private _router: Router,
    private cdref: ChangeDetectorRef,
    private _uiService: UiService
  ) {}

  ngOnInit(): void {
    this.platform.ready().then(() => {
      this.getStorageData();
    });
  }
  onCheckDarkMode() {
    if (this.storageData && this.storageData.isDarkMode !== undefined) {
      if (this.storageData.isDarkMode) {
        document.body.classList.toggle('dark', true);
      }
    }
  }

  getStorageData() {
    this._storageService
      .getObject(STORAGE_CONSTANT)
      .then((res: StorageInterface) => {
        if (res) {
          this.storageData = res;
        }
        this.onCheckVendorSession();
        this.initializeApp();
        this.onCheckDarkMode();
      });
  }

  onCheckVendorSession() {
    if (!this.storageData?.userDetail) {
      return;
    }
    this._orderService
      .checkSession({ vendorId: this.storageData.userDetail?.VendorIdPK })
      .subscribe({
        next: (res) => {
          if (res?.data?.isExpired === true) {
            this._storageService.clearStorage();
            this._router.navigate(['/renew-subscription']);
            return;
          }
          if (res?.data?.isActive === false) {
            this._uiService.presentToast(
              'Sorry your account is deactivated now.'
            );
            setInterval(() => {
              this.onLogOut();
            }, 2000);
            return;
          }
        },
        error: (err) => {
          this._uiService.presentToast(err);
        },
      });
  }
  onLogOut() {
    this._storageService.onLogout(this.storageData);
  }
  initializeApp() {
    if (Capacitor.isPluginAvailable('PushNotifications')) {
      this.initPushNotification();
    }

    // It means platform is web
    if (this.platform.platforms().includes('desktop')) {
      this.requestDesktopPermission();
    }

    this._router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((res) => {
        this.currentUrl = res.url;
      });
    this.platform.backButton.subscribeWithPriority(-1, () => {
      switch (this.currentUrl) {
        case '/home':
          if (
            new Date().getTime() - this.lastTimeBackPress <
            this.timePeriodToExit
          ) {
            CapacitorApp.exitApp();
          } else {
            this._uiService.presentToast('Press back again to exit App');
          }
          this.lastTimeBackPress = new Date().getTime();
          break;
      }
    });
  }

  async initPushNotification() {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      this._uiService.presentToast('User denied permissions!');
    }

    await PushNotifications.register();

    PushNotifications.addListener('registration', (token: Token) => {
      // alert('Push registration success, token: ' + token.value);
      //console.log('fcm', token);
      this.storageData.fcmToken = token.value;
      this._storageService.setObject(STORAGE_CONSTANT, this.storageData);
      PushNotifications.createChannel({
        id: 'PartChan',
        name: 'Partner-Channel',
        description: 'Channel for partners only',
        sound: 'ascent.wav',
        importance: 3,
        visibility: 1,
        vibration: true,
      });
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      alert('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        this.playAudio();
      }
    );
  }

  playAudio() {
    if (this.audio) {
      this.audio.pause();
    }
    this.newOrdersFound = true;
    this.audio = new Audio();
    this.audio.src = 'assets/audio/ascent.mp3';
    this.audio.load();
    this.audio.loop = true;
    this.audio.play();
    this.cdref.detectChanges();

    //console.log('new-order', this.newOrdersFound);
  }

  reloadOrders() {
    this.newOrdersFound = false;
    if (this.audio) {
      this.audio.pause();
    }
    this.audio = undefined;

    if (this._router.url === '/orders/active') {
      //console.log('already there');
      this._orderService.orderReload$.next(true);
    } else {
      this._router.navigate(['/orders/active']);
    }
  }

  requestDesktopPermission() {
    const app = initializeApp(environment.firebase);
    getToken(getMessaging(), { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          console.log('Hurraaa!!! we got the token.....');
          console.log(currentToken);
          this.storageData.fcmToken = currentToken;
          this._storageService.setObject(STORAGE_CONSTANT, this.storageData);
        } else {
          console.log(
            'No registration token available. Request permission to generate one.'
          );
        }
      })
      .catch((err) => {
        alert(
          'An error occurred while getting permission for notification. ' + err
        );
      });

    onMessage(getMessaging(), (payload) => {
      console.log('Message received. ', payload);
      // this.newOrderCount = payload.data.orderCount;
      this.playAudio();
      // this.message=payload;
    });

    const channel = new BroadcastChannel('sw-messages');
    channel.addEventListener('message', (event) => {
      // go the page
      console.log('iamhere', event);
      // this.newOrderCount = 1;
      this.playAudio();
    });
  }

  ngAfterViewChecked(): void {
    this.cdref.detectChanges();
  }
}
