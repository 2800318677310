import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { PurchaseOrderCreateComponent } from '../purchase-order-create/purchase-order-create.component';
import { StoreService } from '../store/store.service';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';

@Component({
  selector: 'app-purchase-orders',
  templateUrl: './purchase-orders.page.html',
  styleUrls: ['./purchase-orders.page.scss'],
})
export class PurchaseOrdersPage implements OnInit {
  fetchPurchaseOrder$!: Observable<any>;
  purchaseOrder: PurchaseOrderInterface[] = [];
  storageData: StorageInterface;
  constructor(
    private modalCtrl: ModalController,
    private _storeService: StoreService,
    private _storageService: StorageService
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.setStorageData();
  }
  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res: any) => {
      this.storageData = res;
      this.fetchPO();
    });
  }

  fetchPO() {
    this.fetchPurchaseOrder$ = this._storeService.fetchPurchaseOrder(
      this.storageData?.userDetail?.VendorIdPK
    );
    this.fetchPurchaseOrder$.subscribe((res) => {
      this.purchaseOrder = res.data;
    });
  }

  async openCouponModal(mode, Order?: PurchaseOrderInterface) {
    const modal = await this.modalCtrl.create({
      component: PurchaseOrderCreateComponent,
      animated: true,
      // cssClass: 'coupons-modal',
      componentProps: {
        mode: mode,
        order: Order,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchPO();
      }
    });
    modal.present();
  }
}
// export interface PurchaseOrderInterface {
//   MerchantMasterIdFK: number;
//   amount: number;
//   createdAt: string;
//   createdBy: number;
//   date: string;
//   discount: number;
//   id: number;
//   paymentMode: string;
//   paymentStatus: string;
//   purchaseId: string;
//   remark: string;
//   tax: number;
//   vendorMobile: string | null;
//   vendorName: string | null;
// }
interface Merchant {
  MerchantMasterIdPK: number;
  VendorIdFK: number;
  name: string;
  mobile: string;
  address: string;
  status: string;
  deleted: boolean;
  gstin: string;
  createdAt: string;
  updatedAt: string;
}

interface InventoryItem {
  InventoryItemIdPK: number;
  InventoryItemMasterIdFK: number;
  InventoryCategoryMasterIdFK: number;
  MerchantIdFK: number | null;
  VendorIdFK: number;
  quantity: number;
  price: number;
  discountType: string | null;
  name: string;
  shortDesc: string | null;
  discountValue: number | null;
  mrp: number;
  expiryDate: string;
  deleted: boolean;
  createdBy: number;
  createdAt: string;
  updatedAt: string;
}

interface InventoryTransaction {
  InventoryTransactionIdPK: number;
  InventoryItemIdFK: number;
  PurchaseOrderIdFK: number;
  VendorIdFK: number;
  qty: number;
  type: string;
  createdBy: number;
  OrderIdFK: number | null;
  createdAt: string;
  updatedAt: string;
  inventory_item: InventoryItem;
}

export interface PurchaseOrderInterface {
  id: number;
  createdBy: number;
  purchaseId: string;
  date: string;
  vendorName: string | null;
  vendorMobile: string | null;
  amount: number;
  tax: number;
  discount: number;
  paymentStatus: string;
  paymentMode: string;
  createdAt: string;
  MerchantMasterIdFK: number;
  remark: string;
  merchant_master: Merchant;
  inventory_transactions: InventoryTransaction[];
}
