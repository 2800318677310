import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { USER_LOGIN_API } from '../shared/constants/apis.constant';
import { ErrorHandler } from '../shared/services/errorhandler.service';

@Injectable()
export class AuthService {
  constructor(private _http: HttpClient, private _errorHandler: ErrorHandler) {}

  userLoginAPI(data: any): Observable<any> {
    return this._http
      .post(environment.base_url + USER_LOGIN_API, data)
      .pipe(catchError(this._errorHandler.handleError));
  }

  validateCustomer(
    data: string,
    fcmToken?: string,
    name?: string
  ): Observable<any> {
    return this._http.post(
      environment.base_url + environment.mobile_validate,
      { mobile: data, ...(fcmToken && { fcmToken }), ...(name && { name }) },
      {
        observe: 'response',
      }
    );
  }
  checkSession(data: any): Observable<any> {
    return this._http.get(environment.base_url + environment.vendor_session, {
      params: data,
    });
  }
}
