import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { STORAGE_CONSTANT } from '../../shared/constants/app.constants';
import { ExcelService } from '../../shared/services/excel.service';
import { OrderService } from '../../shared/services/order.service';
import {
  StorageInterface,
  StorageService,
} from '../../shared/services/storage.service';
import { UiService } from '../../shared/services/ui.service';
import { InventoryService } from '../../shared/services/inventory.service';
import { InventoryTransaction, InventoryTransactionApiResponse } from 'src/app/shared/models/inventory-transaction.model';

@Component({
  selector: 'app-inventory-transactions',
  templateUrl: './inventory-transactions.page.html',
  styleUrls: ['./inventory-transactions.page.scss'],
})
export class InventoryTransactionsPage implements OnInit {
  // Linechart: any = [];
  // Piechart: any = [];
  currentPage = 1;
  currentLimit = 10;
  startDateFC = new FormControl(null);
  endDateFC = new FormControl(null);
  totalSize: number;
  filterStartDate: any;
  filterEndDate: any;
  transactionData: InventoryTransaction[] = [];
  storageData: StorageInterface;
  constructor(
    private _inventoryService: InventoryService,
    private _storageService: StorageService,
    private _uiService: UiService,
    private excelService: ExcelService
  ) {
    const date = new Date().toISOString().split('T')[0];
    this.startDateFC.setValue(date);
    this.endDateFC.setValue(date);
  }

  ngOnInit() {}

  ionViewWillEnter() {
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res: any) => {
      this.storageData = res;
      this.fetch();
    });
  }

  // TODO::: Optimize this code
  async fetch() {
    if (this.endDateFC.value < this.startDateFC.value) {
      this._uiService.presentToast(
        'The start date cannot be earlier than the end date.'
      );
      return;
    }

    this._inventoryService
      .fetchInventoryTransactions({
        vendorId: this.storageData?.userDetail?.VendorIdPK,

        page: this.currentPage,
        limt: this.currentLimit,
        ...(this.startDateFC.value && {
          startDate: this.startDateFC.value,
        }),
        ...(this.endDateFC.value && {
          endDate: this.endDateFC.value,
        }),
      })
      .subscribe((responseData: InventoryTransactionApiResponse) => {
        if (responseData.statusCode === 200) {
          this.transactionData = responseData.data;
          this.totalSize = responseData.totalSize;
        }
      });
  }
  exportAsXLSX(): void {
    if (this.transactionData.length < 1) {
      this._uiService.presentToast('No data available to export.');
      return;
    }
    this.excelService.exportAsExcelFile(
      this.transactionData,
      'export-to-excel'
    );
  }
}
