import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StoreService } from 'src/app/store/store.service';

@Component({
  selector: 'app-menu-popover',
  templateUrl: './menu-popover.component.html',
  styleUrls: ['./menu-popover.component.scss'],
})
export class MenuPopoverComponent implements OnInit {
  categories: any[] = [];
  constructor(
    private modalController: ModalController,
    private _storeService: StoreService
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.categories = this._storeService.getCategories();
  }

  scrollTocategory(categoryData) {
    //console.log(categoryData);
    this._storeService.scrollToCategory.next(categoryData);
    this.dismissPopover();
  }

  dismissPopover(e?) {
    this.modalController.dismiss();
  }
}
