import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Staffs } from 'src/app/shared/models/staff.model';

import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { FormService } from 'src/app/shared/services/form.service';
import { ManageStaffService } from 'src/app/shared/services/manage-staff.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-staff-create',
  templateUrl: './staff-create.component.html',
  styleUrls: ['./staff-create.component.scss'],
})
export class StaffCreateComponent implements OnInit {
  staffUserFormGroup!: FormGroup;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() details: Staffs = {} as Staffs;
  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
  @Input() VendorIdPK: string;
  addStaffUser$!: Observable<any>;
  constructor(
    private modal: ModalController,
    private formErrorService: FormErrorService,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private staffService: ManageStaffService,
    private uiService: UiService
  ) {}

  ngOnInit() {
    this.buildForm();

    // Waiting for Fetch Api Works
    if (this.details) {
      this.staffUserFormGroup.patchValue(this.details);
    }
  }
  buildForm() {
    this.staffUserFormGroup = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      designation: new FormControl('', [Validators.required]),
      type: new FormControl('EMPLOYEE'),
      mobile: new FormControl('', [Validators.required]),
      userLoginId: new FormControl(''),
      salary: new FormControl('', [Validators.required]),
      password: new FormControl('', []),
    });
  }
  dismiss() {
    this.modal.dismiss();
  }
  onAddNewPerson() {
    this.formService.markFormAsTouched(this.staffUserFormGroup);
    if (!this.staffUserFormGroup.valid) {
      return;
    }
    // *********For Checking VendorIdPK is exists ************
    if (this.VendorIdPK) {
      this.staffUserFormGroup.value['VendorIdPK'] = this.VendorIdPK;
    } else {
      this.uiService.presentToast('Vendor Id Not Found');
      return;
    }
    if (!this.details) {
      this.addStaffUser$ = this.staffService.createStaff(
        this.staffUserFormGroup.value
      );
    } else {
      this.staffUserFormGroup.removeControl('password'),
        this.staffUserFormGroup.removeControl('userLoginId'),
        (this.staffUserFormGroup.value['StaffIdPK'] = this.details.StaffIdPK);
      this.addStaffUser$ = this.staffService.updateStaff(
        this.staffUserFormGroup.value
      );
    }

    this.addStaffUser$.subscribe({
      next: (res) => {
        this.uiService.presentToast(
          !this.details ? ' Created Successfully' : 'Updated Successfully'
        );
        this.staffUserFormGroup.reset();
        this.modal.dismiss({data:true});
      },
      error: (err) => {
        this.uiService.presentToast(err);
      },
    });
  }
  onDelete() {
    this.uiService.presentActionSheet('Are you sure to delete this Staff!', [
      {
        text: 'Yes',
        handler: () => {
          this.staffService
            .deleteStaff({ StaffIdPK: this.details.StaffIdPK })
            .subscribe({
              next: (_) => {
                this.uiService.presentToast('deleted successfully');
                this.modal.dismiss({data:true});
              },
            });
        },
      },
      {
        text: 'No',
        role: 'cancel',
      },
    ]);
  }
  public getErrorMessage(fieldName: string, error: string): string {
    return this.formErrorService.getErrorMessage(fieldName, error);
  }
}
