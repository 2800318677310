import { Component, Input, OnInit } from '@angular/core';
import { Staffs } from 'src/app/shared/models/staff.model';
import { OrderInterface } from 'src/app/shared/services/order.service';

@Component({
  selector: 'app-accepted-orders',
  templateUrl: './accepted-orders.page.html',
  styleUrls: ['./accepted-orders.page.scss'],
})
export class AcceptedOrdersPage implements OnInit {
  @Input() orders: OrderInterface[] = [];
  @Input() kitchenUsers: Staffs[] = [];

  constructor() {}

  ngOnInit() {}
}
