import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { subscribeOn } from 'rxjs/operators';
import { STORAGE_CONSTANT } from 'src/app/shared/constants/app.constants';
import {
  StorageInterface,
  StorageService,
} from 'src/app/shared/services/storage.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { StoreService } from 'src/app/store/store.service';
import { environment } from 'src/environments/environment.prod';
import { CreateCategory } from '../create-category.page';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.page.html',
  styleUrls: ['./add-category.page.scss'],
})
export class AddCategoryPage {
  @Input() storageData: StorageInterface;
  @Input() categories: any;
  @Input() index!: number;
  addCategories$: Observable<any>;
  categorUpdate$: Observable<any>;
  baseUrl = environment.base_url;
  categoryImagePreview: any;
  data: any;
  categoryNameFromControl = new FormControl(null, [Validators.required]);
  public file: File = {} as File; // Variable to store file
  shortLink: string = '';
  loading: boolean = false; // Flag variable
  categorDelete$: Observable<any>;

  constructor(
    private _storeService: StoreService,

    private _modal: ModalController,
    private _uiService: UiService,
    private _storageService: StorageService
  ) {}

  ionViewWillEnter() {
    if (this.categories) {
      this.categoryNameFromControl.patchValue(this.categories.name);
    }
  }

  getFileDetail(event: any) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.categoryImagePreview = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]); // to trigger onload
    }
    this.file = event.target.files[0] as File;
  }

  async onAddNewCategory() {
    let id: any = this.storageData?.userDetail?.VendorIdPK;

    if (!this.categoryNameFromControl.valid) {
      this._uiService.presentToast('Category name is required');
      return;
    }

    const formData = new FormData();
    formData.append('name', this.categoryNameFromControl.value);
    formData.append('sortOrder', '0');
    formData.append('VendorIdFK', id);
    formData.append('imageType', 'category');
    formData.append('image', this.file);
    this.addCategories$ = this._storeService.createCategory(formData);
    this.addCategories$.subscribe(
      async (res) => {
        if (res) {
          await this._uiService.presentToast('Category added sucessfully!');
          this.categoryNameFromControl.patchValue(null);
          this._modal.dismiss(true);
          AddCategoryPage;
        }
      },
      (error) => {
        this._uiService.presentToast(error);
      }
    );
  }
  dismiss() {
    this._modal.dismiss();
  }
  async onUpdateCategory() {
    if (!this.categoryNameFromControl) {
      this._uiService.presentToast('Category Name Is Required!');
      return;
    }
    const formData = new FormData();
    formData.append('name', this.categoryNameFromControl.value);
    formData.append('sortOrder', this.categories.sortOrder);
    formData.append('VendorIdFK', this.categories.VendorIdFK);
    formData.append('CategoryIdPK', this.categories.CategoryIdPK);
    formData.append('imageType', 'category');
    if (this.categoryImagePreview) {
      formData.append('image', this.file);
    }
    this.categorUpdate$ = this._storeService.updateCategory(formData);
    this.categorUpdate$.subscribe(
      async (res) => {
        if (res) {
          await this._uiService.presentToast('Category updated sucessfully!');
          this.categoryNameFromControl.patchValue(null);
          this._modal.dismiss(true);
          AddCategoryPage;
        }
      },
      (error) => {
        this._uiService.presentToast(error);
      }
    );
  }
  onImageLoadError(e: any) {
    e.target.src = '../../assets/images/icons/menu.png';
  }

  // TO make the delete functional we need to pass index from parent component
  // For now there is no UI to edit category
  async onDeleteCategory() {
    if (this.index) {
      this._uiService.presentActionSheet(
        'Are you sure to delete this category!',
        [
          {
            text: 'Yes',
            handler: () => {
              this.deleteCategory();
            },
          },
          {
            text: 'No',
            role: 'cancel',
          },
        ]
      );
      return;
    }
  }

  async deleteCategory() {
    this.categorDelete$ = this._storeService.deleteCategory(
      this.categories.CategoryIdPK,
      this.index
    );
    this.categorDelete$.subscribe(
      (responseData) => {
        if (responseData.statusCode === 200) {
          this._storeService.deleteCategoryFromData(this.index);
          this._modal.dismiss(true);
        }
      },
      (error) => {
        if (error.status === 406) {
          alert(error.error.message);
        }
      }
    );
  }

  onClose() {
    this._modal.dismiss();
  }
}
