import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable, Subject } from 'rxjs';
import { StorageService } from '../shared/services/storage.service';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { MenuItem, MenuType } from '../shared/models/menu.model';

@Injectable({ providedIn: 'root' })
export class StoreService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {}

  public categories: any = [];
  categoryChanged = new Subject<any>();
  itemChanged = new Subject<boolean>();
  scrollToCategory = new Subject<any>();
  createCoupon(data): Observable<any> {
    return this.http.post(
      environment.base_url + environment.create_coupon_method,
      data
    );
  }

  fetchCoupons(vendorId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('vendorId', vendorId);
    return this.http
      .get(environment.base_url + environment.fetch_coupons_method, { params })
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }

  updateCoupon(CouponIdPK, data): Observable<any> {
    return this.http.patch(
      environment.base_url +
        environment.update_coupon_method.replace('{id}', CouponIdPK),
      data
    );
  }

  deletCoupon(CouponIdPK): Observable<any> {
    return this.http.delete(
      environment.base_url +
        environment.delete_coupon_method.replace('{id}', CouponIdPK)
    );
  }

  createItem(itemForm: any) {
    return this.http.post(
      environment.base_url + environment.item_add_method,
      itemForm,
      {
        observe: 'response',
      }
    );
  }

  updateItem(updatedData: any) {
    return this.http.put(
      environment.base_url + environment.item_update_method,
      updatedData,
      {
        observe: 'response',
      }
    );
  }

  createCategory(categoryData): Observable<any> {
    return this.http.post(
      environment.base_url + environment.create_category_method,
      categoryData,
      {
        observe: 'response',
      }
    );
  }
  updateCategory(updatedData: any) {
    return this.http.put(
      environment.base_url + environment.category_update_method,
      updatedData,
      {
        observe: 'response',
      }
    );
  }
  deleteCategoryFromData(index: number) {
    this.categories.splice(index, 1);
    this.categoryChanged.next(this.categories.slice());
  }
  deleteCategory(categoryData, index: number) {
    return this.http.delete(
      environment.base_url + environment.category_remove_method,
      { params: { CategoryIdPK: categoryData } }
    );
  }

  fetchCategory(categoryData: any) {
    return this.http.get(
      environment.base_url + environment.category_pag_fetch_method,
      {
        params: categoryData,
      }
    );
  }
  getCategory() {
    return this.http.get(environment.base_url + environment.category_fetch, {
      params: {
        currentPage: '',
        itemsPerPage: '',
        searchData: '',
      },
    });
  }

  getItems(vendorKey) {
    return this.http.get(environment.base_url + environment.item_fetch, {
      params: {
        currentPage: '',
        itemsPerPage: '',
        searchData: '',
        VendorIdPK: vendorKey,
      },
    });
  }

  setCategories(categoryData) {
    this.categories = categoryData;
    this.categoryChanged.next(this.categories.slice());
  }

  getCategories() {
    return [...this.categories];
  }

  getMenu(params: any): Observable<StoreMenuInterface> {
    return this.http
      .get(environment.base_url + environment.fetch_menu_api, {
        params,
      })
      .pipe(
        map((res: { data: StoreMenuInterface }) => {
          console.log('res', res.data);

          this.categories = [];
          res.data.categories.forEach((menu) => {
            this.categories.push({
              CategoryIdPK: menu.items[0].CategoryIdFK,
              image: menu.items[0].category.image,
              name: menu.name,
              itemLength: menu.items.length,
            });
            menu.items.forEach((item) => {
              if (
                (item.variantIds !== '' && item.variantIds !== null) ||
                item.isAddOnExists
              ) {
                item.isCustomizable = true;
                item.variantsCount = item.variantIds?.split(',').length;
              }
              if (item.recommendedIds !== '' && item.recommendedIds !== null) {
                item.recommendedCount = item.recommendedIds?.split(',').length;
              }
            });
          });

          this.setCategories(this.categories);

          return res.data;
        })
      );
  }

  fetchItemByIds(params: any): Observable<any> {
    return this.http
      .get(environment.base_url + environment.fetch_item_by_ids_api, { params })
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }

  fetchAddOns(params: any): Observable<any> {
    return this.http
      .get(environment.base_url + environment.fetch_add_ons, {
        params,
      })
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }

  getAllOrdersInOneCategory(items: ItemMenuCategory[]): ItemMenuCategory[] {
    let allItems: ItemMenu[] = [];
    items.forEach((item) => {
      item.items.forEach((menuItem) => {
        allItems.push(menuItem);
      });
    });
    // for (const key in items) {
    //   if (Object.prototype.hasOwnProperty.call(items, key)) {
    //     const element = items[key];
    //     (items[key] as unknown as MenuItem[]).forEach((res) => {
    //       allItems.push(res);
    //     });
    //   }
    // }

    return [
      {
        name: 'All Items',
        items: allItems,
      },
    ];
  }

  getAllTablesOfStore(VendorIdPK: number): Observable<any> {
    return this.http
      .get(
        environment.base_url +
          environment.fetch_all_tables.replace('{id}', VendorIdPK + '')
      )
      .pipe(
        map((res: any) => {
          let seatIndex, tableIndex;

          res.data.forEach((table, i) => {
            table.seats.forEach((seat, j) => {
              seat.isVisible = true;
              if (seat.tableNumber === 0) {
                tableIndex = i;
                seatIndex = j;
              }
              seat.styleClass =
                seat.tableStatus === 'ENGAGED'
                  ? 'occupied'
                  : seat.tableStatus === 'AVAILABLE' ||
                    seat.tableStatus === 'ACTIVE'
                  ? 'vacant'
                  : seat.tableStatus === 'INACTIVE'
                  ? 'inactive'
                  : '';
              seat.tableDisplayStatus =
                seat.tableStatus === 'ENGAGED'
                  ? 'Occupied'
                  : seat.tableStatus === 'AVAILABLE' ||
                    seat.tableStatus === 'ACTIVE'
                  ? 'Vacant'
                  : seat.tableStatus === 'INACTIVE'
                  ? 'In-Active'
                  : '';
              seat.tableIconName =
                seat.tableStatus === 'ENGAGED'
                  ? 'checkmark-circle-outline'
                  : seat.tableStatus === 'AVAILABLE' ||
                    seat.tableStatus === 'ACTIVE'
                  ? 'add-circle-outline'
                  : seat.tableStatus === 'INACTIVE'
                  ? 'close-circle-outline'
                  : '';
            });
          });

          // res.data[tableIndex].seats.splice(seatIndex, 1);
          return res.data;
        })
      );
  }

  getStoreDiningAreas(VendorIdPK: number): Observable<any> {
    return this.http
      .get(
        environment.base_url +
          environment.fetch_all_dining_areas.replace('{id}', VendorIdPK + '')
      )
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }

  createStoreDiningArea(data: any): Observable<any> {
    return this.http.post(
      environment.base_url + environment.create_dining_area,
      data
    );
  }

  updateDinigArea(DiningAreaIdPK: number, data): Observable<any> {
    return this.http.patch(
      environment.base_url +
        environment.update_dining_area.replace('{id}', DiningAreaIdPK + ''),
      data
    );
  }

  createTableForStore(data): Observable<any> {
    return this.http.post(
      environment.base_url + environment.create_store_table,
      data
    );
  }

  updateTableForStore(data): Observable<any> {
    return this.http.patch(
      environment.base_url + environment.update_store_table,
      data
    );
  }

  updateCategoriesSortOrder(data): Observable<any> {
    return this.http.patch(
      environment.base_url + environment.update_category_sort_method,
      data
    );
  }

  updateItemSortOrder(data): Observable<any> {
    return this.http.patch(
      environment.base_url + environment.update_item_sort_method,
      data
    );
  }

  fetchMealTimes() {
    return this.http.get(environment.base_url + environment.fetch_meal_timings);
  }
  fetchPurchaseOrder(vendorId: number) {
    let params = new HttpParams();
    params = params.append('createdBy', vendorId);

    return this.http.get(
      environment.base_url + environment.fetch_purchase_order_method,
      { params }
    );
  }
  createPurchaseOrder(obj: any): Observable<any> {
    return this.http.post(
      environment.base_url + environment.create_purchase_order_method,
      obj
    );
  }
  updatePurchaseOrder(orderId: any, data: any): Observable<any> {
    return this.http.patch(
      environment.base_url +
        environment.update_purchase_order_method.replace('{id}', orderId),
      data
    );
  }
  deletePurchaseOrder(id: any) {
    return this.http.delete(
      environment.base_url +
        environment.remove_purchase_order_method.replace('{id}', id + '')
    );
  }
  updateMealTimes(MealTimeIdPK, data): Observable<any> {
    return this.http.patch(
      environment.base_url +
        environment.update_meal_time_method.replace('{id}', MealTimeIdPK),
      data
    );
  }

  updateItemStatus(data) {
    return this.http.patch(
      environment.base_url + environment.item_status_update_method,
      data
    );
  }

  createVariant(data: any) {
    return this.http.post(
      `${environment.base_url}${environment.create_variant_method}`,
      data
    );
  }

  updateVariant(data): Observable<any> {
    return this.http.put(
      environment.base_url + environment.update_variant_method,
      data
    );
  }

  deleteVariant(data, updateItemData): Observable<any> {
    return this.http
      .put(environment.base_url + environment.update_variant_method, data)
      .pipe(
        mergeMap((res) => {
          console.log(res);
          return this.http.put(
            environment.base_url + environment.item_update_method,
            updateItemData
          );
        })
      );
  }

  createAddOn(data: any) {
    return this.http.post(
      `${environment.base_url}${environment.create_add_on_method}`,
      data
    );
  }

  deleteAddOn(addOnIdPK: number) {
    return this.http.delete(
      environment.base_url +
        environment.delete_add_on_method.replace('{id}', addOnIdPK + '')
    );
  }

  updateAddOn(addOnIdPK, data): Observable<any> {
    return this.http.patch(
      environment.base_url +
        environment.update_add_on_method.replace('{id}', addOnIdPK + ''),
      data
    );
  }

  fetchTags(params: any) {
    return this.http.get(environment.base_url + environment.fetch_tags_method, {
      params,
    });
  }

  createTag(data: any) {
    return this.http.post(
      `${environment.base_url}${environment.create_tag_method}`,
      data
    );
  }

  updateTagOfItem(data): Observable<any> {
    return this.http.put(
      environment.base_url + environment.update_item_tags_method,
      data
    );
  }

  fetchItem(itemData: any) {
    return this.http.get(
      environment.base_url + environment.item_pag_fetch_method,
      {
        params: itemData,
      }
    );
  }

  updateRecommendations(data): Observable<any> {
    return this.http.put(
      environment.base_url + environment.update_recommendations_method,
      data
    );
  }

  fetchUpsells(params?: any): Observable<any> {
    return this.http
      .get(environment.base_url + environment.fetch_upsell_method, {
        params,
      })
      .pipe(
        map((res: any) => {
          let upsells = res.data as UpSellList[];
          upsells.forEach((upsell) => {
            upsell.items.forEach((item) => {
              upsell.upSellItems.forEach((uItem) => {
                if (uItem.ItemIdPK === item.ItemIdPK) {
                  uItem.orignalPrice = item.price;
                  uItem.name = item.name;
                }
              });
            });
          });
          return res.data;
        })
      );
  }

  createUpSell(data: any) {
    return this.http.post(
      `${environment.base_url}${environment.create_upsell_method}`,
      data
    );
  }

  updateUpSell(upsellId: number, data: any) {
    return this.http.patch(
      `${environment.base_url}${environment.update_upsell_method.replace(
        '${id}',
        upsellId.toString()
      )}`,
      data
    );
  }

  deleteUpSell(upsellId: number) {
    return this.http.delete(
      `${environment.base_url}${environment.delete_upsell_method.replace(
        '${id}',
        upsellId.toString()
      )}`
    );
  }
}

export interface StoreMenuInterface {
  map(arg0: (value: any) => any): any;
  categories: ItemMenuCategory[];
  mealTimes: MealTimeCategory[];
}
export interface ItemMenuCategory {
  name: string;
  items: ItemMenu[];
  isReorderEnable?: boolean;
}
export interface MealTimeCategory {
  name: string;
  items: ItemMenu[];
}
export interface ItemMenu {
  ItemIdPK: number;
  CategoryIdFK: number;
  VendorIdFK: number;
  isAddOnExists: boolean;
  comboIds: string;
  recommendedIds: string;
  mealTimeIds: string;
  variantIds: string;
  name: string;
  image: string;
  price: number;
  gstPercent: number;
  type: string;
  shortDesc: string;
  status: string;
  isVariant: boolean;
  prepTime: number;
  category: Category;
  tags: Tag[];
  isCustomizable?: boolean;
  variantsCount?: number;
  recommendedCount?: number;
  qty?: number;
  offers?: any;
  sortOrder?: number;
}

export interface Category {
  CategoryIdPK: number;
  name: string;
  VendorIdFK: number;
  image?: any;
  sortOrder: number;
}

export interface Tag {
  ItemTagsIdPK: number;
  name: string;
  VendorIdFK?: number;
  isChecked?: boolean;
}

export interface MealTimeList {
  MealTimeIdPK: number;
  VendorIdFK: number;
  endTime: string;
  name: string;
  startTime: string;
}

export interface ItemByIdsInterface {
  ItemIdPK: number;
  CategoryIdFK: number;
  recommendedIds?: any;
  name: string;
  price: number;
  image: string;
  variantIds?: any;
  isAddOnExists: boolean;
  type: string;
  status: string;
}

export interface AddOnsList {
  AddOnIdPK: number;
  VendorIdFK: number;
  ItemIdFK: number;
  name: string;
  price: number;
  status: string;
  InventoryItemMasterIdFK?:number;
  quantity?: number;
}

export interface UpSellList {
  UpsellIdPK: number;
  upSellName?: any;
  VendorIdFK: number;
  cartMinValue: number;
  cartMaxValue: number;
  upSellItems: UpSellItem[];
  status: string;
  items: ItemForUpSell[];
}

export interface UpSellItem {
  ItemIdPK: number;
  price: number;
  orignalPrice?: number;
  name?: string;
}

export interface ItemForUpSell {
  ItemIdPK: number;
  name: string;
  price: number;
}
