import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { STORAGE_CONSTANT } from 'src/app/shared/constants/app.constants';
import { TopSellingCategory } from 'src/app/shared/models/category-report.model';
import { CustomerDetailsReport } from 'src/app/shared/models/customer-details-report.model';
import {
  TopSellingItem,
  OrderApiResponse,
} from 'src/app/shared/models/product-report.model';
import { OrderService } from 'src/app/shared/services/order.service';
import {
  StorageInterface,
  StorageService,
} from 'src/app/shared/services/storage.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-report-customer-detail',
  templateUrl: './report-customer-detail.page.html',
  styleUrls: ['./report-customer-detail.page.scss'],
})
export class ReportCustomerDetailPage implements OnInit {
  filterStartDate: any;
  filterEndDate: any;
  storageData: StorageInterface;
  startDateFC = new FormControl(null);
  endDateFC = new FormControl(null);
  customerDeatils: CustomerDetailsReport = {} as CustomerDetailsReport;
  selectedCustomerId: string;
  constructor(
    private _orderService: OrderService,
    private _storageService: StorageService,
    private _uiService: UiService,
    private _route: ActivatedRoute
  ) {
    const date = new Date().toISOString().split('T')[0];
    this.startDateFC.setValue(date);
    this.endDateFC.setValue(date);
  }

  ngOnInit() {
    this._route.queryParams.subscribe((res) => {
      if (res['customerId']) {
        this.selectedCustomerId = res['customerId'];
      }
    });
  }
  ionViewWillEnter() {
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res: any) => {
      this.storageData = res;
      this.fetchReport();
    });
  }

  async fetchReport() {
    if (this.endDateFC.value < this.startDateFC.value) {
      this._uiService.presentToast(
        'The start date cannot be earlier than the end date.'
      );
      return;
    }
    this._orderService
      .fetchCustomerDetailsReport({
        vendorId: this.storageData?.userDetail?.VendorIdPK,
        status: 'COMPLETED',
        ...(this.startDateFC.value && {
          startDate: this.startDateFC.value,
        }),
        ...(this.selectedCustomerId && {
          customerId: this.selectedCustomerId,
        }),
        ...(this.endDateFC.value && {
          endDate: this.endDateFC.value,
        }),
        showDetails: true,
      })
      .subscribe((responseData: OrderApiResponse) => {
        if (responseData.statusCode === 200) {
          this.customerDeatils = responseData.data[0];
        }
      });
  }
}
