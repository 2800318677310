import { Component, inject, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {
  InventoryCategories,
  InventoryItemMaster,
  InventoryService,
  InvenyoryUnit,
} from 'src/app/shared/services/inventory.service';
import { StorageInterface } from 'src/app/shared/services/storage.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-inventory-master-create',
  templateUrl: './inventory-master-create.page.html',
  styleUrls: ['./inventory-master-create.page.scss'],
})
export class InventoryMasterCreatePage implements OnInit {
  @Input() vendorId!: number;
  @Input() itemMaster!: InventoryItemMaster;
  @Input() categoryId!: number;
  baseUrl = environment.base_url;
  itemImage: any;
  imageFile: File = {} as File;
  itemMasterFG!: FormGroup;
  _formBuilder = inject(FormBuilder);
  _uiService = inject(UiService);
  _service = inject(InventoryService);
  categories: InventoryCategories[] = [];
  units: InvenyoryUnit[] = [];

  constructor(private _modal: ModalController) {}

  ngOnInit() {
    this.buildForm();
    this.fetchCategories();
    this.fetchUnits();
  }

  buildForm() {
    // eslint-disable-next-line no-underscore-dangle
    this.itemMasterFG = this._formBuilder.group({
      categoryId: new FormControl('', [Validators.required]),
      unitId: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required]),
      type: new FormControl('RAW_MATERIAL', Validators.required),
      vendorId: new FormControl(this.vendorId),
      minStockQty: new FormControl(null, [Validators.required]),
    });

    if (this.itemMaster) {
      this.itemMasterFG.patchValue(this.itemMaster);
      this.itemImage = this.baseUrl + this.itemMaster.icon;
    }
  }

  fetchCategories() {
    this._service
      .fetchInventoryCategories({
        status: 'ACTIVE',
        VendorIdFK: this.vendorId,
      })
      .subscribe({
        next: (_) => {
          this.categories = _.data;
          if (this.categoryId) {
            this.itemMasterFG.patchValue({
              categoryId: Number(this.categoryId),
            });
          }
        },
        error: (_) => {
          this._uiService.presentToast(_);
        },
      });
  }

  fetchUnits() {
    this._service.fetchInventoryUnits().subscribe({
      next: (_) => {
        this.units = _.data;
        if (this.itemMaster) {
          this.itemMasterFG.patchValue({
            unitId: this.itemMaster.InventoryUnitIdFK,
          });
        }
      },
      error: (_) => {
        this._uiService.presentToast(_);
      },
    });
  }

  addNewItem() {
    if (!this.itemMasterFG.valid) {
      this._uiService.presentToast('Please fill all the data ');
      return;
    }
    const formData = new FormData();
    for (const key in this.itemMasterFG.value) {
      if (Object.prototype.hasOwnProperty.call(this.itemMasterFG.value, key)) {
        formData.append(key, this.itemMasterFG.value[key]);
      }
    }
    if (this.imageFile) {
      formData.append('imageType', 'item-master');
      formData.append('image', this.imageFile);
    }
    this._service.createInventoryMasterItem(formData).subscribe({
      next: (_) => {
        this._uiService.presentToast('Item Master Created Successfully');
        this.itemMasterFG.reset();
        this._modal.dismiss(true);
      },
      error: (_) => {
        this._uiService.presentToast(_);
      },
    });
  }

  dismiss() {
    this._modal.dismiss();
  }
  getFileDetail(event: any) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.itemImage = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]); // to trigger onload
    }
    this.imageFile = event.target.files[0] as File;
  }

  onImageLoadError(e: any) {
    e.target.src = '../../../assets/images/icons/menu.png';
  }

  onDeleteItem() {
    this._uiService.presentActionSheet('Are you sure to delete this Item!', [
      {
        text: 'Yes',
        handler: () => {
          this._service
            .deleteInventoryMasterItem(this.itemMaster.InventoryItemMasterIdPK)
            .subscribe({
              next: (_) => {
                this._uiService.presentToast('Item deleted successfully');
                this._modal.dismiss(true);
              },
            });
        },
      },
      {
        text: 'No',
        role: 'cancel',
      },
    ]);
  }

  updateItem() {
    if (!this.itemMasterFG.valid) {
      this._uiService.presentToast('Please fill all the data ');
      return;
    }
    const formData = new FormData();
    for (const key in this.itemMasterFG.value) {
      if (Object.prototype.hasOwnProperty.call(this.itemMasterFG.value, key)) {
        formData.append(key, this.itemMasterFG.value[key]);
      }
    }
    if (this.imageFile) {
      formData.append('imageType', 'item-master');
      formData.append('image', this.imageFile);
    }
    this._service
      .updateInventoryMasterItem(
        this.itemMaster.InventoryItemMasterIdPK,
        formData
      )
      .subscribe({
        next: (_) => {
          this._uiService.presentToast('Item Master Updated Successfully');
          this.itemMasterFG.reset();
          this._modal.dismiss(true);
        },
        error: (_) => {
          this._uiService.presentToast(_);
        },
      });
  }
}
