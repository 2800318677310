import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FooterPage } from './components/footer/footer.page';
import { QRCodeModule } from 'angularx-qrcode';
import { QrCodePage } from './components/qr-code/qr-code.page';

@NgModule({
  declarations: [QrCodePage],
  imports: [CommonModule, IonicModule, QRCodeModule],
  exports: [QrCodePage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
