import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { MenuItem } from '../shared/models/menu.model';
import { FormErrorService } from '../shared/services/form-error.service';
import { FormService } from '../shared/services/form.service';
import { StorageInterface } from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import {
  Category,
  ItemByIdsInterface,
  MealTimeList,
  StoreService,
} from '../store/store.service';

@Component({
  selector: 'app-item-variant-create',
  templateUrl: './item-variant-create.component.html',
  styleUrls: ['./item-variant-create.component.scss'],
})
export class ItemVariantCreateComponent implements OnInit {
  @Input() mode;
  @Input() data: {
    categories: Category[];
    mealTimes: MealTimeList[];
    storageData: StorageInterface;
    item?: MenuItem;
  };
  @Input() variant: ItemByIdsInterface;
  addVariantFormGroup: FormGroup;
  createVariant$: Observable<any>;

  constructor(
    public modalCtrl: ModalController,
    private _formBuilder: FormBuilder,
    private _formService: FormService,
    private _formErrorService: FormErrorService,
    private _storeService: StoreService,
    private _uiService: UiService
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.addVariantFormGroup = this._formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      price: new FormControl(null, [Validators.required]),
      status: new FormControl('ACTIVE', [Validators.required]),
    });
    if (this.mode === 'EDIT') {
      this.addVariantFormGroup.patchValue(this.variant);
    }
  }

  onAddVariant() {
    this._formService.markFormAsTouched(this.addVariantFormGroup);
    if (!this.addVariantFormGroup.valid) {
      return;
    }
    let sendData = {
      name: this.addVariantFormGroup.value.name,
      price: this.addVariantFormGroup.value.price,
      type: this.data.item.type,
      itemStatus: this.addVariantFormGroup.value.status,
      CategoryIdPK: this.data.item.CategoryIdFK,
      shortDesc: this.data.item.shortDesc,
      parentId: this.data.item.ItemIdPK,
      ItemIdFK: this.data.item.ItemIdPK,
    };

    this.createVariant$ = this._storeService.createVariant(sendData);

    this.createVariant$.subscribe({
      next: (res) => {
        if (res) {
          this.addVariantFormGroup.reset();
          this._uiService.presentToast('Variant Created Successfully!');
          this.modalCtrl.dismiss({
            addedId: res.data.ItemIdPK,
          });
        }
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  onDeleteVariant() {
    let ids = this.data.item.variantIds.split(',');
    let index = ids.findIndex((id) => +id === +this.variant.ItemIdPK);
    ids.splice(index, 1);
    const formData = new FormData();
    formData.append('ItemIdPK', this.data.item.ItemIdPK.toString());
    formData.append('variantIds', ids.join());

    this.createVariant$ = this._storeService.deleteVariant(
      {
        name: this.addVariantFormGroup.value.name,
        status: 'DELETED',
        price: this.addVariantFormGroup.value.price,
        ItemIdPK: this.variant.ItemIdPK,
      },
      formData
    );

    this.createVariant$.subscribe({
      next: (res) => {
        if (res) {
          console.log(res);
          this._uiService.presentToast('Variant Deleted Successfully!');
          this.modalCtrl.dismiss({
            removedId: this.variant.ItemIdPK,
          });
        }
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  onUpdateVariant() {
    this._formService.markFormAsTouched(this.addVariantFormGroup);
    if (!this.addVariantFormGroup.valid) {
      return;
    }

    this.createVariant$ = this._storeService.updateVariant({
      name: this.addVariantFormGroup.value.name,
      status: this.addVariantFormGroup.value.status,
      price: this.addVariantFormGroup.value.price,
      ItemIdPK: this.variant.ItemIdPK,
    });

    this.createVariant$.subscribe({
      next: (res) => {
        if (res) {
          this._uiService.presentToast('Variant Updated Successfully!');
          this.modalCtrl.dismiss(true);
        }
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  public getErrorMessage(fieldName: string, error: string): string {
    return this._formErrorService.getErrorMessage(fieldName, error);
  }
}
