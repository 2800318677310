import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { StoreService } from 'src/app/store/store.service';
import { environment } from 'src/environments/environment.prod';
import { CartInterface, CartService } from '../../services/cart.service';

@Component({
  selector: 'app-customize-item-cart',
  templateUrl: './customize-item-cart.page.html',
  styleUrls: ['./customize-item-cart.page.scss'],
})
export class CustomizeItemCartPage implements OnInit {
  @Input() data;
  cart: CartInterface;
  fetchItems$: Observable<any>;
  items = [];
  baseUrl = environment.base_url;

  constructor(
    private _cartService: CartService,
    private _storeService: StoreService,
    public _modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.setView();
  }

  setView() {
    this.cart = this._cartService.getCustomizeItemFromCart(this.data.ItemIdPK);
    let itemsIds = this.cart.items.map((item) => {
      return item.ItemIdPK;
    });
    this.fetchItems$ = this._storeService.fetchItemByIds({
      itemIds: '' + itemsIds,
    });

    this.fetchItems$.subscribe((res) => {
      res.forEach((item) => {
        this.cart.items.forEach((cartItem) => {
          item.qty = cartItem.qty;
        });
      });
      this.items = this._cartService.prepareCartDisplayData(res);
      console.log('items', this.items);
    });
  }

  decreaseQty(data, i: number) {
    data.qty = data.qty - 1;
    this._cartService.decreaseQtyFromCart(data.parentItemIdPK, data.cartId);
    if (data.qty <= 0) {
      this.items.splice(i, 1);
      if (this.items.length === 0) {
        this._modalCtrl.dismiss({ empty: true });
      }
    }
  }

  increaseQty(data) {
    data.qty = data.qty + 1;
    this._cartService.increaseQtyFromCart(data.parentItemIdPK, data.cartId);
  }

  onImageLoadError(e: any) {
    e.target.src = environment.base_url + 'images/defaultitem.png';
  }
}
