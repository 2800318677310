export const USER_LOGIN_API = 'admin/login';
export const ORDER_FETCH_API = 'vendor/customer/fetchorder';
export const ORDER_UPDATE_API = 'vendor/customer/order/update';
export const ADD_OR_FETCH_CUSTOMER = 'vendor/addcustomer';
export const ADD_ORDER_API = 'vendor/customer/addorder';
export const FETCH_ALL_TABLES = 'vendor/{_id}/fetchtable';
export const UPDATE_EXISTING_ORDER = 'vendor/customer/editorder';
export const FETCH_DELIVERY_PERSON = 'vendor/staff/fetch';
export const CREATE_DELIVERY_PERSON = 'vendor/staff/add';
export const UPDATE_DELIVERY_PERSON = 'vendor/staff/edit';
export const MERCHANTS_API = 'merchant';
export const FETCH_STAFF_API = 'vendor/staff/fetch';
export const CREATE_STAFF_API = 'vendor/staff/add';
export const UPDATE_STAFF_API = 'vendor/staff/edit';
export const REMOVE_STAFF_API = 'vendor/staff/remove';
export const RECIPE_API = 'inventory/recipes';
export const UPDATE_MENU_RECIPE = 'inventory/menu_recipes/update';
export const MANAGE_INGREDIENTS_API = 'inventory/recipe_ingredients';
export const UPDATE_INGREDIENTS_API = 'inventory/recipe_ingredients/update';
export const CREATE_MENU_RECIPE = 'inventory/menu_recipes';
export const FETCH_MENU_RECIPE = 'inventory/menu_recipes';
export const BANNERS_API = 'banners/promotional_banners';
export const PROMOTIONAL_MESSAGE_API = 'messages/marketing_templates';


