import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Share } from '@capacitor/share';
import { Observable } from 'rxjs';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';
import { OrderService } from '../shared/services/order.service';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import { environment } from 'src/environments/environment.prod';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage {
  selectedStatsTab = 'TODAY';
  storageData: StorageInterface = {} as StorageInterface;
  dashStats$: Observable<any>;
  dashStats: DashboardStatsInterface = {} as DashboardStatsInterface;
  userName: any;
  storeName: any;
  storeIcon: any;
  baseURL = environment.base_url;
  constructor(
    private _uiService: UiService,
    private _storageService: StorageService,
    private _router: Router,
    private _orderService: OrderService,
    private _platform: Platform
  ) {}

  ionViewWillEnter() {
    this.storageData = {} as StorageInterface;
    this.userName = null;
    this.storeName = null;
    this.setStorageData();
  }

  setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      this.storeName = res.userDetail.vendorStoreName;
      this.storeIcon = res.userDetail.storeIcon;

      if (this.storageData.userType === 'EMPLOYEE') {
        this.userName = res.userDetail.employeeName;
      } else if (this.storageData.userType === 'VENDOR') {
        this.userName = this.storageData.userDetail.vendorName;
      } else {
        this.userName = 'Admin';
      }
      this.getDashboardStats(0);
    });
  }

  getDashboardStats(days?: number) {
    this.dashStats$ = this._orderService.statsFetch(
      this.storageData?.userDetail?.VendorIdPK,
      {
        ...(days !== undefined && { lastNDays: days }),
      }
    );

    this.dashStats$.subscribe((res) => {
      this.dashStats = res.data;
    });
  }

  swithStatsTab(tabName) {
    this.selectedStatsTab = tabName;
    switch (tabName) {
      case 'LAST7':
        this.getDashboardStats(7);
        break;
      case 'TODAY':
        this.getDashboardStats(0);
        break;
      case 'LAST30':
        this.getDashboardStats(30);
        break;
      case 'LIFETIME':
        this.getDashboardStats(undefined);
        break;
    }
  }

  async shareOnWhatsapp() {
    this._uiService.share({
      title: `Hello Foodie!
      Welcome to ${this.storageData.userDetail.vendorStoreName}`,
      text:
        'Take a look at our delicious offerings and order directly from your mobile. ' +
        'https://easeinn.app/menu/' +
        this.storageData.userDetail.vendorCode.slice(
          2,
          this.storageData.userDetail.vendorCode.length
        ) +
        'D0T0',
      url:
        'https://easeinn.app/menu/' +
        this.storageData.userDetail.vendorCode.slice(
          2,
          this.storageData.userDetail.vendorCode.length
        ) +
        'D0T0',

      dialogTitle: 'Share with foodies',
    });
  }

  navigateToWaitingOrders() {
    this._router.navigateByUrl('/orders/active');
  }

  navigateToAcceptedOrders() {
    this._router.navigate(['/orders/active'], {
      queryParams: {
        isPast: true,
      },
    });
  }

  navigateToStore() {
    if (this.storageData.appPrefs) {
      if (this.storageData.appPrefs.defaultQuickOrder) {
        this._router.navigateByUrl('/store/quick-order');
        return;
      }
    }
    this._router.navigateByUrl('/store');
  }
}

export interface DashboardStatsInterface {
  activeOrder: number;
  waitingOrder: number;
  totalOrder: number;
  totalSales: number;
  totalDiscount: number;
}
