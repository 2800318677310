import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { ErrorHandler } from './errorhandler.service';

@Injectable()
export class CustomerService {
  _errorHandler = inject(ErrorHandler);
  constructor(private httpClient: HttpClient) {}

  fetchCustomers(search?: string): Observable<any> {
    return this.httpClient
      .get(environment.base_url + environment.fetch_customers, {
        params: { ...(search && { search }) },
      })
      .pipe(
        map((_: any) => {
          _.data.forEach((customer: CustomerInterface) => {
            console.log(customer.customerNameAlias);

            customer.displayName =
              customer.customerNameAlias && customer.customerNameAlias !== ''
                ? customer.customerNameAlias
                : customer.customer.name;
          });
          return _.data;
        }),
        catchError(this._errorHandler.handleError)
      );
  }
  fetchCustomer(data?: any): Observable<any> {
    return this.httpClient
      .get(environment.base_url + environment.fetch_customers, {
        params: data,
      })
      .pipe(
        map((_: any) => {
          _.data.forEach((customer: CustomerInterface) => {
            console.log(customer.customerNameAlias);

            customer.displayName =
              customer.customerNameAlias && customer.customerNameAlias !== ''
                ? customer.customerNameAlias
                : customer.customer.name;
          });
          return _.data;
        }),
        catchError(this._errorHandler.handleError)
      );
  }

  updateVendorCustomer(VendorCustomerIdPK: number, body: any): Observable<any> {
    const url = `${environment.base_url}${environment.vendor_edit_customers}?VendorCustomerIdPK=${VendorCustomerIdPK}`;
    return this.httpClient
      .patch(url, body)
      .pipe(catchError(this._errorHandler.handleError));
  }
}

export interface CustomerInterface {
  customer: {
    CustomerIdPK: number;
    name: string;
    mobile: string;
    status: string;
    token: string;
    otpSentTime: string;
  };
  VendorCustomerIdPK: number;
  CustomerIdFK: number;
  VendorIdFK: number;
  customerNameAlias: string;
  createdAt: string;
  updatedAt: string;
  totalOrder: number;
  totalOrderValue: string;
  lastOrderDate: string | null;
  lastOrderValue: string;
  displayName?: string;
}
