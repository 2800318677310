import { Component, Input, OnInit } from '@angular/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { UiService } from '../../services/ui.service';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.page.html',
  styleUrls: ['./qr-code.page.scss'],
})
export class QrCodePage implements OnInit {
  @Input() qrData: any;
  @Input() qrName!: string;
  elementType = 'canvas';

  constructor(private uiService: UiService, private platform: Platform) {}

  ngOnInit() {
    console.log('qr', this.qrData);
  }
  async onDownloadQr(parent) {
    let parentElement = null;

    if (this.elementType === 'canvas') {
      // fetches base 64 data from canvas
      parentElement = parent.qrcElement.nativeElement
        .querySelector('canvas')
        .toDataURL('image/png');
    } else if (this.elementType === 'img' || this.elementType === 'url') {
      // fetches base 64 data from image
      // parentElement contains the base64 encoded image src
      // you might use to store somewhere
      parentElement = parent.qrcElement.nativeElement.querySelector('img').src;
    } else {
      alert("Set elementType to 'canvas', 'img' or 'url'.");
    }

    if (parentElement) {
      // // converts base 64 encoded image to blobData
      let blobData = this.convertBase64ToBlob(parentElement);
      // // saves as image
      const blob = new Blob([blobData], { type: 'image/png' });
      // const url = window.URL.createObjectURL(blob);
      // const link = document.createElement('a');
      // link.href = url;
      // // name of the file
      // link.download = this.qrName;
      // link.click();
      var reader = new FileReader();

      try {
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
          var base64data = reader.result;
          if (
            this.platform.platforms().includes('desktop') ||
            this.platform.platforms().includes('mobileweb')
          ) {
            var a = document.createElement('a');
            a.href = base64data as string;

            // Set the file name
            a.download = this.qrName + '.png';

            // Trigger the click event on the anchor element
            a.click();
            this.uiService.presentToast('QR downloaded and saved');
          } else {
            // const savedFile = await Filesystem.writeFile({
            //   path: `${this.qrName}.png`,
            //   data: base64data as any,
            //   directory: Directory.Documents,
            // });

            window.open(
              `https://pos.easeinn.app/web-qr?storeUrl=${this.qrData}&currentQrName=${this.qrName}`,
              '_self'
            );
          }
        };

        // await Filesystem.writeFile({
        //   path: 'easeinn/' + this.qrName,
        //   data: parentElement,
        //   directory: Directory.Documents,
        //   encoding: Encoding.UTF8,
        // });
        // this.uiService.presentToast('File saved in documents folder');
      } catch (e) {
        this.uiService.presentToast(e);
      }
    }
  }

  private convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(';base64,');
    // hold the content type
    const imageType = parts[0].split(':')[1];
    // decode base64 string
    const decodedData = window.atob(parts[1]);
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType });
  }

  onClickQr(event) {
    const gh = event.target.src;
    const a = document.createElement('a');
    a.href = gh;
    a.download = this.qrData + '.png';
    a.click();
  }
}
