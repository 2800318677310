import { Pipe, PipeTransform } from '@angular/core';
import { MenuItem, MenuType } from '../models/menu.model';

@Pipe({ name: 'menupipe' })
export class MenuPipe implements PipeTransform {
  transform(value: MenuType): any {
    let keys = [];
    for (let key in value) {
      keys.push({ key: key, value: value[key] });
    }
    return keys;
  }
}
