import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { StoreService } from 'src/app/store/store.service';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-repeat-last-modal',
  templateUrl: './repeat-last-modal.page.html',
  styleUrls: ['./repeat-last-modal.page.scss'],
})
export class RepeatLastModalPage implements OnInit {
  @Input() data;
  fetchItem$: Observable<any>;
  item: any;
  lastItemFromCart;

  constructor(
    public _modalCtrl: ModalController,
    private _cartService: CartService,
    private _storeService: StoreService
  ) {}

  ngOnInit() {
    this.lastItemFromCart = this._cartService.getCartPreviousItem(
      this.data.ItemIdPK
    );

    this.fetchData();
  }

  fetchData() {
    this.fetchItem$ = this._storeService.fetchItemByIds({
      itemIds: this.lastItemFromCart.ItemIdPK,
    });

    this.fetchItem$.subscribe((res) => {
      console.log(res);
      if (res) {
        this.lastItemFromCart.addOnNames =
          '' + this.lastItemFromCart.addOnDetails.map((addOn) => addOn.name);
        this.item = res[0];
      }
    });
  }

  onSelectOpiton(isRepeat = false) {
    this._modalCtrl.dismiss({
      isRepeat,
    });
  }
}
