import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { FormService } from '../../services/form.service';
import { UiService } from '../../services/ui.service';
@Component({
  selector: 'app-ask-customer-name',
  templateUrl: './ask-customer-name.page.html',
  styleUrls: ['./ask-customer-name.page.scss'],
  providers: [AuthService],
})
export class AskCustomerNamePage implements OnInit {
  customerInfoFormGroup: FormGroup;
  mobileValidate$: Observable<any>;
  @Input() mobileNumber;
  constructor(
    private _modalController: ModalController,
    private _formBuilder: FormBuilder,
    private _formService: FormService,
    private _uiService: UiService,
    private _authService: AuthService
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.customerInfoFormGroup = this._formBuilder.group({
      mobileNumber: new FormControl(this.mobileNumber, [Validators.required]),
      name: new FormControl(null, [Validators.required]),
    });
  }

  dismiss() {}

  onConfirm() {
    this._formService.markFormAsTouched(this.customerInfoFormGroup);

    if (!this.customerInfoFormGroup.valid) {
      this._uiService.presentToast('Name and Number both is required!');
      return;
    }

    this.mobileValidate$ = this._authService.validateCustomer(
      this.customerInfoFormGroup.value.mobileNumber,
      '',
      this.customerInfoFormGroup.value.name
    );

    this.mobileValidate$.subscribe((res) => {
      this._modalController.dismiss({
        mobileNumber: this.customerInfoFormGroup.value.mobileNumber,
        name: this.customerInfoFormGroup.value.name,
        CustomerIdPk: res.body.data.CustomerIdPK,
      });
    });
  }
}
