import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AccountPage } from './account/account.page';
import { AuthPage } from './auth/auth.page';
import { CartPage } from './cart/cart.page';
import { CouponsPage } from './coupons/coupons.page';
import { CategoryPage } from './create-category/create-category.page';
import { CustomersPage } from './customers/customers.page';
import { HomePage } from './home/home.page';
import { ItemsListPage } from './items-list/items-list.page';
import { ManageQrsPage } from './manage-qrs/manage-qrs.page';
import { MealTimingsPage } from './meal-timings/meal-timings.page';
import { MorePage } from './more/more.page';
import { ActiveOrdersPage } from './orders/active-orders/active-orders.page';
import { PastOrdersPage } from './orders/past-orders/past-orders.page';
import { PurchaseOrdersPage } from './purchase-orders/purchase-orders.page';
import { SettingsPage } from './settings/settings.page';
import { CustomizeItemCartPage } from './shared/components/customize-item-cart/customize-item-cart.page';
import { AuthGuard } from './shared/services/auth.guard';
import { SessionGuard } from './shared/services/session.guard';
import { QuickOrderPage } from './store/quick-order/quick-order.page';
import { StorePage } from './store/store.page';
import { SupportPage } from './support/support.page';
import { DiningAreasPage } from './tables/dining-areas/dining-areas.page';
import { TablesPage } from './tables/tables.page';
import { UpsellOffersPage } from './upsell-offers/upsell-offers.page';
import { WelcomeScreenPage } from './welcome-screen/welcome-screen.page';
import { InventoryDashboardPage } from './inventory/inventory-dashboard/inventory-dashboard.page';
import { InventoryListPage } from './inventory/inventory-list/inventory-list.page';
import { MerchantsListPage } from './merchants/merchants-list/merchants-list.page';
import { MerchantCreatePage } from './merchants/merchant-create/merchant-create.page';
import { RecipesListPage } from './recipes/recipes-list/recipes-list.page';
import { RecipeCreatePage } from './recipes/recipe-create/recipe-create.page';
import { StaffListingPage } from './manage-staff/staff-listing/staff-listing.page';
import { ReportSalesPage } from './reports/report-sales/report-sales.page';
import { ReportsDashboardPage } from './reports/reports-dashboard/reports-dashboard.page';
import { WebQrPage } from './web-qr/web-qr.page';
import { StockInPage } from './inventory/stock-in/stock-in.page';
import { StockOutPage } from './inventory/stock-out/stock-out.page';
import { BannersPage } from './banners/banners.page';
import { PromotionalMessagePage } from './promotional-message/promotional-message.page';
import { InventoryTransactionsPage } from './inventory/inventory-transactions/inventory-transactions.page';
import { ReportProductPage } from './reports/report-product/report-product.page';
import { ReportCategoryPage } from './reports/report-category/report-category.page';
import { ReportCustomerDetailPage } from './reports/report-customer-detail/report-customer-detail.page';
import { RenewSubscriptionPage } from './renew-subscription/renew-subscription.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    component: AuthPage,
    canActivate: [SessionGuard],
  },
  {
    path: 'home',
    component: HomePage,
    canActivate: [AuthGuard],
  },
  {
    path: 'welcome',
    component: WelcomeScreenPage,
  },
  {
    path: 'store',
    component: StorePage,
  },
  {
    path: 'orders/active',
    component: ActiveOrdersPage,
  },
  {
    path: 'order/past',
    component: PastOrdersPage,
  },
  {
    path: 'account',
    component: AccountPage,
  },
  {
    path: 'more',
    component: MorePage,
  },
  {
    path: 'support',
    component: SupportPage,
  },
  {
    path: 'settings',
    component: SettingsPage,
  },
  {
    path: 'cart',
    component: CartPage,
  },
  {
    path: 'customers',
    component: CustomersPage,
  },
  {
    path: 'store/quick-order',
    component: QuickOrderPage,
  },
  {
    path: 'customize-item-cart',
    component: CustomizeItemCartPage,
  },
  {
    path: 'tables',
    component: TablesPage,
  },
  {
    path: 'dining-areas',
    component: DiningAreasPage,
  },
  {
    path: 'items',
    component: ItemsListPage,
  },
  {
    path: 'categories',
    component: CategoryPage,
  },
  {
    path: 'items-list',
    component: ItemsListPage,
  },
  {
    path: 'meal-timings',
    component: MealTimingsPage,
  },
  {
    path: 'purchase-orders',
    component: PurchaseOrdersPage,
  },
  {
    path: 'coupons',
    component: CouponsPage,
  },
  {
    path: 'upsell-offers',
    component: UpsellOffersPage,
  },
  {
    path: 'manage-qrs',
    component: ManageQrsPage,
  },
  {
    path: 'delivery-person-listing',
    loadChildren: () =>
      import(
        './delivery-person/delivery-person-listing/delivery-person-listing.module'
      ).then((m) => m.DeliveryPersonListingPageModule),
  },
  {
    path: 'kot-user-listing',
    loadChildren: () =>
      import('./kot-user/kot-user-listing/kot-user-listing.module').then(
        (m) => m.KotUserListingPageModule
      ),
  },
  {
    path: 'kot-app',
    loadChildren: () =>
      import('./KOT-APP/kot-app.module').then((m) => m.KotAppModule),
  },
  {
    path: 'inventory/dashboard',
    component: InventoryDashboardPage,
  },
  {
    path: 'inventory/list',
    component: InventoryListPage,
  },
  {
    path: 'merchants/list',
    component: MerchantsListPage,
  },
  {
    path: 'merchant/create',
    component: MerchantCreatePage,
  },
  {
    path: 'recipes/list',
    component: RecipesListPage,
  },
  {
    path: 'recipe/create',
    component: RecipeCreatePage,
  },

  {
    path: 'staff-listing',
    component: StaffListingPage,
  },
  {
    path: 'report/sales',
    component: ReportSalesPage,
  },
  {
    path: 'report/dashboard',
    component: ReportsDashboardPage,
  },
  {
    path: 'web-qr',
    component: WebQrPage,
  },
  {
    path: 'stock-in',
    component: StockInPage,
  },
  {
    path: 'stock-out',
    component: StockOutPage,
  },
  {
    path: 'banners',
    component: BannersPage,
  },
  {
    path: 'promotional-message',
    component: PromotionalMessagePage,
  },
  {
    path: 'inventory-transactions',
    component: InventoryTransactionsPage,
  },
  {
    path: 'report/category',
    component: ReportCategoryPage,
  },
  {
    path: 'report/product',
    component: ReportProductPage,
  },
  {
    path: 'report/customer-detail',
    component: ReportCustomerDetailPage,
  },
  {
    path: 'renew-subscription',
    component: RenewSubscriptionPage,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
