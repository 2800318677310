import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-order-alert',
  templateUrl: './new-order-alert.component.html',
  styleUrls: ['./new-order-alert.component.scss'],
})
export class NewOrderAlertComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
