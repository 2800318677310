import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { STORAGE_CONSTANT } from '../constants/app.constants';
import { StorageInterface, StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class SessionGuard implements CanActivate {
  constructor(private router: Router, private storageService: StorageService) {}

  async canActivate() {
    this.storageService
      .getObject(STORAGE_CONSTANT)
      .then((data: StorageInterface) => {
        if (!data) {
          return true;
        }
        if (data.token) {
          this.router.navigateByUrl('/home', { replaceUrl: true });
          return false;
        }
      });
    return true;
  }
}
