import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { STORAGE_CONSTANT } from 'src/app/shared/constants/app.constants';
import { ManageStaffService } from 'src/app/shared/services/manage-staff.service';
import {
  StorageInterface,
  StorageService,
} from 'src/app/shared/services/storage.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { environment } from 'src/environments/environment.prod';
import { StaffCreateComponent } from '../staff-create/staff-create.component';
import { StaffFetch, Staffs } from 'src/app/shared/models/staff.model';

@Component({
  selector: 'app-staff-listing',
  templateUrl: './staff-listing.page.html',
  styleUrls: ['./staff-listing.page.scss'],
})
export class StaffListingPage implements OnInit {
  staff: Staffs[] = [];
  baseUrl = environment.base_url;
  fetchstaff$!: Observable<StaffFetch>;
  searchFormControl = new FormControl(null);
  storageData: StorageInterface;
  searchText = '';
  constructor(
    private staffService: ManageStaffService,
    private storageService: StorageService,
    private modalCtrl: ModalController,
    private uiService: UiService
  ) {}
  ngOnInit(): void {}
  ionViewWillEnter() {
    this.setStorageData();
    this.loadSearchFilter();
  }
  setStorageData() {
    this.storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      this.fetchstaff();
    });
  }

  fetchstaff() {
    this.fetchstaff$ = this.staffService.fetchStaff({
      VendorIdPK: this.storageData?.userDetail?.VendorIdPK,
      type: 'EMPLOYEE',
      ...(this.searchText && { search: this.searchText }),
    });
    this.fetchstaff$.subscribe({
      next: (res: StaffFetch) => {
        this.staff = res.data;
      },
      error: (err) => {
        this.uiService.presentToast(err);
      },
    });
  }

  async openAddstaffModal(userDetails?: Staffs) {
    const modal = await this.modalCtrl.create({
      component: StaffCreateComponent,
      animated: true,
      componentProps: {
        VendorIdPK: this.storageData?.userDetail?.VendorIdPK,
        details: userDetails,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchstaff();
      }
    });
    modal.present();
  }
  loadSearchFilter() {
    this.searchFormControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((res: any) => {
          this.searchText = res;
          this.fetchstaff();
        })
      )
      .subscribe();
  }
}
