import { Injectable } from '@angular/core';
import { Share } from '@capacitor/share';
import {
  ToastController,
  LoadingController,
  AlertController,
  ActionSheetController,
  Platform,
} from '@ionic/angular';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UiService {
  TOAST_TIMER = 5000;
  // for custom loading
  loadingChecker = new Subject();

  constructor(
    private toastController: ToastController,
    private loadingController: LoadingController,
    private alertController: AlertController,
    public actionSheetController: ActionSheetController,
    private _platform: Platform
  ) {}

  loading: any;

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: +this.TOAST_TIMER,
      position: 'bottom',
    });
    await toast.present();
  }

  async presentLoading(msg: string) {
    this.loading = await this.loadingController.create({
      message: msg,
      spinner: null,
      duration: 5000,
      translucent: false,
      cssClass: 'custom-class custom-loading',
    });
    await this.loading.present();
  }

  async dismissLoading() {
    await this.loading.dismiss();
  }

  presentAlert(
    header: string,
    subHeader: string,
    message: string,
    mode?: any,
    buttons?: any
  ) {
    return new Promise(async (res) => {
      const alert = await this.alertController.create({
        header,
        subHeader,
        message,
        buttons,
        mode,
      });
      await alert.present();
    });
  }

  async presentActionSheet(message: string, buttons) {
    const actionSheet = await this.actionSheetController.create({
      header: message,
      mode: 'ios',
      cssClass: 'home-action-sheet',
      backdropDismiss: false,
      buttons,
    });
    await actionSheet.present();
  }

  async share(content: {
    title: string;
    text: string;
    url?: string;
    dialogTitle?: string;
    customerNumber?: string;
  }) {
    if (this._platform.platforms().includes('desktop')) {
      // if (navigator.canShare) {
      //   navigator.share({
      //     text: content.text,
      //     url: content.url,
      //     title: content.title,
      //   });
      // } else {
      //   this.presentAlert(
      //     'Not Supported',
      //     '',
      //     'Your browser does not support share. Try different browser for this',
      //     'ios',
      //     [
      //       {
      //         text: 'ok',
      //         role: 'cancel',
      //       },
      //     ]
      //   );
      // }
      if (content.customerNumber)
        window.open(
          `https://api.whatsapp.com/send?phone=+91${content.customerNumber}&text=${content.text}`
        );
      else window.open(`whatsapp://send?text=${content.text}`);
      return;
    }

    try {
      await Share.share({
        title: content.title,
        text: content.text,
        url: content.url,
        dialogTitle: content.dialogTitle,
      });
    } catch (e) {
      this.presentToast('ERROR: - ' + e);
    }
  }
}
