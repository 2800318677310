import { Component, inject, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MerchantCreatePage } from '../merchant-create/merchant-create.page';
import { MerchantService } from 'src/app/shared/services/merchant.service';
import { STORAGE_CONSTANT } from 'src/app/shared/constants/app.constants';
import {
  StorageInterface,
  StorageService,
} from 'src/app/shared/services/storage.service';
import {
  Merchant,
  MerchantsFetchApiResponse,
} from 'src/app/shared/models/merchants.model';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-merchants-list',
  templateUrl: './merchants-list.page.html',
  styleUrls: ['./merchants-list.page.scss'],
})
export class MerchantsListPage implements OnInit {
  merchant: Merchant[] = [];
  storageData: StorageInterface = {} as StorageInterface;
  _uiService = inject(UiService);

  constructor(
    public modalCtrl: ModalController,
    private merchantService: MerchantService,
    private storageService: StorageService
  ) {}

  async ngOnInit() {
    this.storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      this.onFetch();
    });
  }

  async openMerchantModal(item?: any) {
    const modal = await this.modalCtrl.create({
      component: MerchantCreatePage,
      animated: true,
      cssClass: 'coupons-modal',
      componentProps: {
        VendorId: this.storageData?.userDetail?.VendorIdPK,
        details: item,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.onFetch();
      }
    });
    modal.present();
  }
  onFetch() {
    this.merchantService
      .fetchMerchant({ vendorId: this.storageData?.userDetail?.VendorIdPK })
      .subscribe({
        next: (res: MerchantsFetchApiResponse) => {
          this.merchant = res.data;
        },
        error: (err) => {
          this._uiService.presentToast(err);
        },
      });
  }
}
