import { Component, inject, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment.prod';
import {
  InventoryCategories,
  InventoryService,
} from '../../shared/services/inventory.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-inventory-category-create',
  templateUrl: './inventory-category-create.page.html',
  styleUrls: ['./inventory-category-create.page.scss'],
})
export class InventoryCategoryCreatePage implements OnInit {
  @Input() VendorIdFK!: number;
  @Input() category!: InventoryCategories;

  _formBuilder = inject(FormBuilder);
  _uiService = inject(UiService);
  _service = inject(InventoryService);

  categoryForm!: FormGroup;

  constructor(private _modal: ModalController) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.categoryForm = this._formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      VendorIdFK: new FormControl(this.VendorIdFK),
      status: new FormControl('ACTIVE'),
    });

    if (this.category) {
      this.categoryForm.patchValue(this.category);
    }
  }

  addNewItem() {
    if (!this.categoryForm.valid) {
      this._uiService.presentToast('Please fill all the data');
      return;
    }

    this._service.createInventoryCategory(this.categoryForm.value).subscribe({
      next: (_) => {
        this._uiService.presentToast('Category added successfully!');
        this.categoryForm.reset();
        this._modal.dismiss(true);
      },
      error: (_) => {
        this._uiService.presentToast(_);
      },
    });
  }

  onDeleteCategory() {
    this._uiService.presentActionSheet(
      'Are you sure to delete this Category!',
      [
        {
          text: 'Yes',
          handler: () => {
            this._service
              .deleteInventoryCategories(
                this.category.InventoryCategoryMasterIdPK
              )
              .subscribe({
                next: (_) => {
                  this._uiService.presentToast('Category deleted successfully');
                  this._modal.dismiss(true);
                },
              });
          },
        },
        {
          text: 'No',
          role: 'cancel',
        },
      ]
    );
  }

  onUpdateCategory() {
    if (!this.categoryForm.valid) {
      this._uiService.presentToast('Please fill all the data');
      return;
    }

    this._service
      .updateInventoryCategory(
        this.category.InventoryCategoryMasterIdPK,
        this.categoryForm.value
      )
      .subscribe({
        next: (_) => {
          this._uiService.presentToast('Category updated successfully!');
          this.categoryForm.reset();
          this._modal.dismiss(true);
        },
        error: (_) => {
          this._uiService.presentToast(_);
        },
      });
  }

  dismiss() {
    this._modal.dismiss();
  }
}
