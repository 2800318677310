import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { OrderPreferenceModalComponent } from '../shared/components/order-preference-modal/order-preference-modal.component';
import { PaymentModalComponent } from '../shared/components/payment-modal/payment-modal.component';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';
import {
  CartService,
  VendorDetailResponseInterface,
} from '../shared/services/cart.service';
import { OrderService } from '../shared/services/order.service';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { Seat, TablesPage } from '../tables/tables.page';
import { DeliveryPersonService } from '../shared/services/delivery-person.service';
import { Staffs } from '../shared/models/staff.model';
import { MenuItem } from '../shared/models/menu.model';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.page.html',
  styleUrls: ['./cart.page.scss'],
})
export class CartPage implements OnInit, OnDestroy {
  fetchCartObservable: Observable<any>;
  placeOrderObservable: Observable<any>;
  updateUserStatusObservable: Observable<any>;
  updateUserNumberObs: Observable<any>;
  cartDetails;
  localCardData;
  totalPrice = 0;
  // modal;
  isCartEmpty = false;
  // currentOtp;
  // otpTimer;
  alertUserOtpConfirmation;
  // otpTimerString = '';
  // subscriptionModalDismiss: Subscription;
  baseUrl = environment.base_url;
  storageData: StorageInterface;
  vendorDetail: VendorDetailResponseInterface;
  isScheduleOrderAvailable = false;
  selectedTimeSlot;
  paymentData: {
    discount?: number | null;
    paymentMethod: string;
    paymentMode: string;
    markAsComplete?: boolean;
    KitchenIdFK?: number;
    isTakeaway?: number;
    tokenNumber?: string;
  };
  placeOrder$: Subject<any> = new Subject();
  cartCount = 0;
  shareRecieptOnWhatsapp = new FormControl(true);
  navigateTo = '/store';
  selectedTable: string;
  selectedSeat: Seat;
  kitchenUsers: Staffs[] = [];

  // TODO::: Optimize
  @Input() isHybridMode = false;
  @Input() storeEvents$: Subject<string> = new Subject();

  constructor(
    private cartService: CartService,
    private router: Router,
    private storageService: StorageService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    public modalController: ModalController,
    private _orderService: OrderService,
    private _location: Location,
    private activatedRoute: ActivatedRoute,
    private _staffService: DeliveryPersonService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((param) => {
      if (param.hasOwnProperty('from')) {
        this.navigateTo = param.from;
      }
    });

    this.listerStoreEvents();
    // that means store & cart are avaialble in the UI same time
    if (this.isHybridMode) this.setStorageData();
  }

  listerStoreEvents() {
    this.storeEvents$.subscribe({
      next: (_) => {
        this.setCart();
        // if (_ === 'ITEM_INCREMENT') {
        // }
        // if (_ === 'ITEM_DECREMENT') {
        //   this.setCart();
        // }
        // if (_ === 'ITEM_ADD') {
        //   this.setCart();
        // }
      },
    });
  }

  // TODO:::Optimize
  ngOnChanges() {
    // this.setStorageData();
  }

  ionViewWillEnter() {
    if (!this.isHybridMode) this.setStorageData();
  }
  decreaseQty(data, i: number) {
    data.qty = data.qty - 1;
    this.cartService.decreaseQtyFromCart(data.parentItemIdPK, data.cartId);
    if (data.qty <= 0) {
      this.cartDetails.splice(i, 1);
    }
    this.setCart();
    this.totalPrice = this.cartService.getCartTotalPrice(this.cartDetails);
  }

  increaseQty(data) {
    data.qty = data.qty + 1;
    this.cartService.increaseQtyFromCart(data.parentItemIdPK, data.cartId);
    this.totalPrice = this.cartService.getCartTotalPrice(this.cartDetails);
    this.setCart();
  }

  async setStorageData() {
    this.storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      // Setting default values if app prefs not set till yet
      if (!this.storageData.appPrefs) {
        this.storageData.appPrefs = {
          whatsappPref: false,
          defaultQuickOrder: false,
        };
      }
      this.setCart();
      this.fetchAllTables();
      // Check for KOT
      const preference = JSON.parse(this.storageData.userDetail.preferences);
      if (preference.hasOwnProperty('ISKOTENABLE')) {
        if (preference.ISKOTENABLE === 'YES') {
          this.fetchKitchens();
        }
      }
    });
  }

  fetchAllTables() {
    this._orderService
      .fetchAllTables('' + this.storageData?.userDetail?.VendorIdPK)
      .subscribe(
        (result: any) => {
          result.forEach((diningArea) => {
            if (diningArea.floorName === 'Not Available') {
              diningArea.seats.forEach((seat) => {
                if (seat.tableNumber === 0) {
                  this.storageData.tableIdPK = seat.TableIdPK;
                  this.storageData.tableNumber = seat.tableNumber;
                  this.selectedTable = null;
                }
              });
            }
          });
          this.storageService
            .setObject(STORAGE_CONSTANT, this.storageData)
            .then((res) => {});
          if (this.storageData.userDetail.vendorStoreType === 'MULTITABLE') {
            this.selectedTable = 'SELECT TABLE';
          } else {
            result.forEach((_) => {
              _.seats.forEach((__) => {
                if (__.tableNumber === 0) {
                  this.selectedSeat = __;
                  this.selectedTable = 'Take-Away';
                }
              });
            });
          }
          this.getVendorDetails();
        },
        (error) => {
          this.showErrorToast(error);
        }
      );
  }

  getVendorDetails() {
    this.cartService
      .fetchVendorDetail(
        this.storageData.userDetail.vendorCode,
        this.storageData.tableNumber
      )
      .subscribe((res: VendorDetailResponseInterface) => {
        this.vendorDetail = res;
        if (this.vendorDetail.data.preferences) {
          let preference = JSON.parse(this.vendorDetail.data.preferences);
          if (preference.hasOwnProperty('StoreIsScheduleOrderAvailable')) {
            if (preference.StoreIsScheduleOrderAvailable === 'YES') {
              this.isScheduleOrderAvailable = true;
            }
          }
        }
      });
  }

  setCart() {
    this.cartService.setCartData();
    this.localCardData = this.cartService.getFullCartData();
    if (this.localCardData.length !== 0) {
      this.isCartEmpty = false;
      this.fetchCartObservable = this.cartService.fetchCartDetail();
      this.fetchCartObservable.subscribe(
        (responseData) => {
          this.cartDetails = responseData;
          this.totalPrice = this.cartService.getCartTotalPrice(
            this.cartDetails
          );
          this.cartCount = this.cartService.getCartLength();
          console.log(this.cartDetails);
        },
        (error) => {
          //console.log(error);
        }
      );
    } else {
      this.isCartEmpty = true;
    }
  }

  fetchKitchens() {
    this._staffService
      .fetchDeliveryPerson({
        VendorIdPK: this.storageData.userDetail.VendorIdPK,
        type: 'KITCHEN',
      })
      .subscribe({
        next: (_) => {
          this.kitchenUsers = _.data;
        },
      });
  }

  placeOrder(e) {
    this.paymentData = e;
    if (this.storageData.customerIdPK) {
      this.cartService
        .checkRunningOrderStatus(
          this.storageData.customerIdPK,
          'INSTANT',
          this.storageData?.userDetail?.VendorIdPK
        )
        .subscribe((responseData: any) => {
          if (responseData.statusCode === 200) {
            if (responseData.orderPlace === true) {
              this.onPlaceOrder();
            } else {
              this.addOrderInPreviousOrder(responseData.OrderIdPK);
            }
          }
        });
    } else {
      this.onPlaceOrder();
    }
  }

  onPlaceOrder() {
    if (this.selectedTable === 'SELECT TABLE') {
      this.openTableSelect();
      return;
    }
    if (
      this.selectedSeat.tableStatus === 'ENGAGED' &&
      this.selectedSeat.tableNumber !== 0
    ) {
      this.addOrderInPreviousOrderForTable(this.selectedSeat.OrderIdFK);
      return;
    }

    this.placeOrderObservable = this._orderService.addOrder(
      this.cartDetails,
      {
        storageData: this.storageData,
        totalPrice: this.totalPrice,
        discount: this.paymentData.discount ? this.paymentData.discount : 0,
        paymentMode: this.paymentData.paymentMode,
        TableIdPK: this.selectedSeat.TableIdPK,
        KitchenIdFK: this.paymentData.KitchenIdFK,
        isTakeaway: this.paymentData.isTakeaway,
        tokenNumber: this.paymentData.tokenNumber,
      },
      this.paymentData.markAsComplete
    );
    this.placeOrderObservable.subscribe(
      (responseData) => {
        if (responseData.statusCode === 200) {
          this.afterOrderTaskDone('Order Placed Successfully!', responseData);
        }
      },
      (error) => {
        //console.log(error);
        this.showErrorToast(error);
      }
    );
  }

  async openTableSelect() {
    const modal = await this.modalController.create({
      component: TablesPage,
      cssClass: 'item-customization-modal',
      componentProps: { isModal: true },
    });

    modal.onDidDismiss().then((res) => {
      if (res.data) {
        this.selectedSeat = res.data;
        this.selectedTable =
          this.selectedSeat.tableNumber !== 0
            ? 'Table No. ' + this.selectedSeat.tableNumber
            : 'Take-Away';
      }
    });
    await modal.present();
  }

  async showErrorToast(data: any) {
    let toast = await this.toastCtrl.create({
      message: data,
      duration: 3000,
      position: 'bottom',
    });

    toast.present();
  }

  async addOrderInPreviousOrder(OrderIdPK) {
    this.alertUserOtpConfirmation = await this.alertController.create({
      header: 'Info!',
      message:
        'You already have a running order, current order will append to running order. Do you like to continue?',
      buttons: [
        {
          text: 'Yes',
          handler: () => {
            this.onUpdateOrder(OrderIdPK);
          },
        },
        'No',
      ],
    });

    await this.alertUserOtpConfirmation.present();
  }

  async addOrderInPreviousOrderForTable(OrderIdPK) {
    this.alertUserOtpConfirmation = await this.alertController.create({
      header: 'Info!',
      message:
        'You already have a running order on this table. Do you like to continue?',
      buttons: [
        {
          text: 'Yes',
          handler: () => {
            this.onUpdateOrder(OrderIdPK);
          },
        },
        'No',
      ],
    });

    await this.alertUserOtpConfirmation.present();
  }

  onUpdateOrder(OrderIdPK) {
    this.placeOrderObservable = this._orderService.addItemInExistingOrder({
      cartDetails: this.cartDetails,
      currentOrderId: OrderIdPK,
      totalPrice: this.totalPrice,
    });

    this.placeOrderObservable.subscribe(
      (responseData) => {
        if (responseData.statusCode === 200) {
          this.afterOrderTaskDone('Order Updated Successfully');
        }
        //console.log(responseData);
      },
      (error) => {
        //console.log(error);
        this.showErrorToast('Server side error, Try Again!');
      }
    );
  }

  async presentPaymentModal() {
    this.placeOrder$.next(true);
  }

  afterOrderTaskDone(message: string, responsedata?) {
    if (
      responsedata &&
      this.storageData.customerMobileNumber !== 'Not Available' &&
      this.storageData.customerMobileNumber &&
      this.shareRecieptOnWhatsapp.value &&
      this.storageData.appPrefs.whatsappPref
    ) {
      this.sendWhatsappConfirmation(responsedata);
    }
    this.storageData.customerIdPK = null;
    this.storageData.customerMobileNumber = null;
    this.storageData.customerName = null;
    this.storageData.customerStatus = null;
    this.storageService
      .setObject(STORAGE_CONSTANT, this.storageData)
      .then((res) => {
        this.cartService.clearCartFromStorage();
        this.cartDetails = [];
        this.isCartEmpty = true;
        this.showErrorToast(message);
        this.router.navigate([this.navigateTo]);
      });
  }

  sendWhatsappConfirmation(responsedata) {
    console.log('whatsapp', responsedata);
    this._orderService.senWhatsappMessageOnOrderCreate(
      responsedata,
      this.storageData
    );
  }

  onImageLoadError(e: any) {
    e.target.src = environment.base_url + 'images/defaultitem.png';
  }

  goToPreviousPage() {
    this._location.back();
  }

  navigateToStore() {
    if (this.storageData.appPrefs) {
      if (this.storageData.appPrefs.defaultQuickOrder) {
        this.router.navigateByUrl('/store/quick-order');
        return;
      }
    }
    this.router.navigateByUrl('/store');
  }

  ngOnDestroy(): void {
    this.storeEvents$.unsubscribe();
  }
}
