import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RecipeCreatePage } from '../recipe-create/recipe-create.page';
import { RecipeIngredientsPage } from '../recipe-ingredients/recipe-ingredients.page';
import { RecipeFetch, Recipes } from 'src/app/shared/models/recipes.model';
import { environment } from 'src/environments/environment.prod';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import {
  StorageInterface,
  StorageService,
} from 'src/app/shared/services/storage.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { RecipesService } from 'src/app/shared/services/recipes.service';
import { STORAGE_CONSTANT } from 'src/app/shared/constants/app.constants';

@Component({
  selector: 'app-recipes-list',
  templateUrl: './recipes-list.page.html',
  styleUrls: ['./recipes-list.page.scss'],
})
export class RecipesListPage implements OnInit {
  recipes: Recipes[] = [];
  baseUrl = environment.base_url;
  fetchRecipe$!: Observable<RecipeFetch>;
  storageData: StorageInterface;
  constructor(
    public modalCtrl: ModalController,
    private storageService: StorageService,
    private uiService: UiService,
    private recipeService: RecipesService
  ) {}

  ngOnInit() {}
  ionViewWillEnter() {
    this.setStorageData();
  }
  setStorageData() {
    this.storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      this.fetch();
    });
  }
  fetch() {
    this.fetchRecipe$ = this.recipeService.fetchRecipes({
      vendorId: this.storageData?.userDetail?.VendorIdPK,
    });
    this.fetchRecipe$.subscribe({
      next: (res: RecipeFetch) => {
        this.recipes = res.data;
      },
      error: (err) => {
        this.uiService.presentToast(err);
      },
    });
  }
  async openRecipeModal(item?: Recipes) {
    const modal = await this.modalCtrl.create({
      component: RecipeCreatePage,
      animated: true,
      // cssClass: 'coupons-modal',
      componentProps: {
        vendorIdPK: this.storageData?.userDetail?.VendorIdPK,
        details: item,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetch();
      }
    });
    modal.present();
  }

  async openIngredientsModal(item: Recipes) {
    const modal = await this.modalCtrl.create({
      component: RecipeIngredientsPage,
      animated: true,
      componentProps: {
        vendorIdPK: this.storageData?.userDetail?.VendorIdPK,
        details: item,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetch();
      }
    });
    modal.present();
  }
  onDelete(recipeId: string | number) {
    this.uiService.presentActionSheet('Are you sure to delete this Recipe!', [
      {
        text: 'Yes',
        handler: () => {
          this.recipeService.deleteRecipes(recipeId).subscribe({
            next: (_) => {
              this.uiService.presentToast('deleted successfully');
              this.fetch();
            },
          });
        },
      },
      {
        text: 'No',
        role: 'cancel',
      },
    ]);
  }
}
