import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support',
  templateUrl: './support.page.html',
  styleUrls: ['./support.page.scss'],
})
export class SupportPage implements OnInit {
  messageAlertOptions = {
    header: 'Select title',
    translucent: true,
  };
  constructor() {}

  ngOnInit() {}

  onCallUs() {
    window.open('tel:9039335274');
  }

  onWhatsapp() {
    window.open(
      `https://api.whatsapp.com/send?phone=+91${9039335274}&text=Hello`
    );
  }
}
