import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoDataFoundComponent } from './no-data-found.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [NoDataFoundComponent],
  imports: [CommonModule, IonicModule],
  exports: [NoDataFoundComponent],
})
export class NoDataFoundModule {}
