import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { MealTimingCreateComponent } from '../meal-timing-create/meal-timing-create.component';
import { StorageService } from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import { StoreService } from '../store/store.service';

@Component({
  selector: 'app-meal-timings',
  templateUrl: './meal-timings.page.html',
  styleUrls: ['./meal-timings.page.scss'],
})
export class MealTimingsPage implements OnInit {
  mealTiming: MealTimingInterface[] = [];
  fetchMeal$!: Observable<any>;
  constructor(
    private modalCtrl: ModalController,
    private _storeService: StoreService,
    private _router: Router,
    private _uiService: UiService
  ) {}

  ngOnInit() {
    this.fetchMeals();
  }
  fetchMeals() {
    this.fetchMeal$ = this._storeService.fetchMealTimes();
    this.fetchMeal$.subscribe((res) => {
      this.mealTiming = res.data;
    });
  }

  async openMealTimingModal(mode, mealItem: MealTimingInterface) {
    const modal = await this.modalCtrl.create({
      component: MealTimingCreateComponent,
      animated: true,
      cssClass: 'variant-modal',
      componentProps: {
        mode: mode,
        meal: mealItem,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchMeals();
      }
    });
    modal.present();
  }
}
export interface MealTimingInterface {
  MealTimeIdPK: number;
  VendorIdFK: number;
  endTime: string;
  name: string;
  startTime: string;
}
