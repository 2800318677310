import { Component, OnInit } from '@angular/core';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { Staffs, StaffFetch } from '../shared/models/staff.model';
import { ManageStaffService } from '../shared/services/manage-staff.service';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import { PromotionalMessageService } from '../shared/services/promotional-message.service';
import {
  PromotionalMessageCreateApiResponse,
  PromotionalMessageFetchApiResponse,
  PromotionalMessageInterface,
} from '../shared/models/promotional-message.model';
import { FormErrorService } from '../shared/services/form-error.service';
import { FormService } from '../shared/services/form.service';

@Component({
  selector: 'app-promotional-message',
  templateUrl: './promotional-message.page.html',
  styleUrls: ['./promotional-message.page.scss'],
})
export class PromotionalMessagePage implements OnInit {
  sharePromotionalMessage: PromotionalMessageInterface =
    {} as PromotionalMessageInterface;
  invoicePromotionalMessage: PromotionalMessageInterface =
    {} as PromotionalMessageInterface;

  baseUrl = environment.base_url;
  storageData: StorageInterface;
  shareFG!: FormGroup;
  invoiceFG!: FormGroup;

  constructor(
    private _pmService: PromotionalMessageService,
    private storageService: StorageService,
    private uiService: UiService,
    private formErrorService: FormErrorService,
    private formBuilder: FormBuilder,
    private formService: FormService
  ) {}
  ngOnInit(): void {
    this.setStorageData();
    this.buildForm();
  }

  setStorageData() {
    this.storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      this.fetch();
    });
  }
  buildForm() {
    this.shareFG = this.formBuilder.group({
      type: new FormControl('WHATSAPP'),
      title: new FormControl('',[Validators.required]),
      body: new FormControl('',[Validators.required]),
      templateName: new FormControl('HOME_SHARE'),
    });
    this.invoiceFG = this.formBuilder.group({
      type: new FormControl('WHATSAPP'),
      title: new FormControl('',[Validators.required]),
      body: new FormControl('',[Validators.required]),
      templateName: new FormControl('INVOICE'),
    });
  }
  fetch() {
    this._pmService
      .fetchMessage({
        vendorId: this.storageData?.userDetail?.VendorIdPK,
      })
      .subscribe({
        next: (res: PromotionalMessageFetchApiResponse) => {
          res.data.forEach((msg: PromotionalMessageInterface) => {
            if (msg.templateName === 'INVOICE') {
              this.invoiceFG.patchValue({
                title: msg.title,
                body: msg.body,
              });
              this.invoicePromotionalMessage = msg;
            }
            if (msg.templateName === 'HOME_SHARE') {
              this.shareFG.patchValue({
                title: msg.title,
                body: msg.body,
              });
              this.invoicePromotionalMessage = msg;
            }
          });
        },
        error: (err) => {
          this.uiService.presentToast(err);
        },
      });
  }
  onCreate(type: string) {
  
    let formData;
    if (type === 'Invoice') {
      this.formService.markFormAsTouched(this.invoiceFG);
      if (!this.invoiceFG.valid) {
        this.uiService.presentToast('Enter Valid Data ');
        return;
      }

      this.invoiceFG.value.VendorIdFK =
        this.storageData?.userDetail?.VendorIdPK;
      formData = this.invoiceFG.value;
    } else {
      this.formService.markFormAsTouched(this.shareFG);
      if (!this.shareFG.valid) {
        this.uiService.presentToast('Enter Valid Data ');
        return;
      }
      this.shareFG.value.VendorIdFK = this.storageData?.userDetail?.VendorIdPK;
      formData = this.shareFG.value;
    }
    this._pmService.createMessage(formData).subscribe({
      next: (res: PromotionalMessageCreateApiResponse) => {
        this.uiService.presentToast(type + ' ' + 'Updated Successfully!');
        this.fetch();
      },
      error: (err) => {
        this.uiService.presentToast(err);
      },
    });
  }
  public getErrorMessage(fieldName: string, error: string): string {
    return this.formErrorService.getErrorMessage(fieldName, error);
  }
}
