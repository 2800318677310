import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IonDatetime, ModalController } from '@ionic/angular';
import {
  Merchant,
  MerchantsFetchApiResponse,
} from 'src/app/shared/models/merchants.model';
import {
  InventoryCategories,
  InventoryItem,
  InventoryItemMaster,
  InventoryService,
  InvenyoryUnit,
} from 'src/app/shared/services/inventory.service';
import { MerchantService } from 'src/app/shared/services/merchant.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-stock-in',
  templateUrl: './stock-in.page.html',
  styleUrls: ['./stock-in.page.scss'],
})
export class StockInPage implements OnInit {
  @Input() vendorId!: number;
  @Input() createdBy!: number;
  @ViewChild('expiryDate') expiryDate!: IonDatetime;

  baseUrl = environment.base_url;
  itemMasters: InventoryItemMaster[] = [];
  allItemMasters: InventoryItemMaster[] = [];

  itemInventoryFG!: FormGroup;
  _formBuilder = inject(FormBuilder);
  _uiService = inject(UiService);
  _service = inject(InventoryService);
  _merchantService = inject(MerchantService);
  categories: InventoryCategories[] = [];
  units: InvenyoryUnit[] = [];
  merchants: Merchant[] = [];

  constructor(private _modal: ModalController) {}

  ngOnInit() {
    this.buildForm();
    this.fetchMerchants();
    this.fetchItemMasters();
  }

  ionViewWillEnter() {}

  buildForm() {
    this.itemInventoryFG = this._formBuilder.group({
      itemMasterSelection: new FormControl(null, [Validators.required]), // this control will use for ui puprose to show proper name or other fields
      categoryId: new FormControl(null),
      itemMasterId: new FormControl(null, [Validators.required]),
      unitId: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required]),
      shortDesc: new FormControl(''),
      vendorId: new FormControl(this.vendorId),
      createdBy: new FormControl(this.createdBy),
      merchantId: new FormControl(''), // need to remove this later on
      mrp: new FormControl(null, [Validators.required]),
      price: new FormControl(null, [Validators.required]),
      quantity: new FormControl(null, [Validators.required]),
      expiryDate: new FormControl(new Date().toISOString(), [
        Validators.required,
      ]),
    });
  }

  fetchItemMasters() {
    this._service
      .fetchInventoryMasterItem({
        vendorId: this.vendorId,
      })
      .subscribe({
        next: (_) => {
          this.itemMasters = _.data;
          this.allItemMasters = JSON.parse(JSON.stringify(_.data));
        },
      });
  }

  fetchMerchants() {
    this._merchantService.fetchMerchant({ vendorId: this.vendorId }).subscribe({
      next: (res: MerchantsFetchApiResponse) => {
        this.merchants = res.data;
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  addNewItem() {
    if (!this.itemInventoryFG.valid) {
      console.log(this.itemInventoryFG);

      this._uiService.presentToast('Please fill all the data ');
      return;
    }
    const formData = new FormData();
    for (const key in this.itemInventoryFG.value) {
      if (
        Object.prototype.hasOwnProperty.call(this.itemInventoryFG.value, key)
      ) {
        formData.append(key, this.itemInventoryFG.value[key]);
      }
    }
    this._service.createInventoryItem(formData).subscribe({
      next: (_) => {
        this._uiService.presentToast('Item Created Successfully');
        this.itemInventoryFG.reset();
        this._modal.dismiss(true);
      },
      error: (_) => {
        this._uiService.presentToast(_);
      },
    });
  }

  onItemMasterSearch(e: any) {
    this.itemMasters = this.allItemMasters.filter((item) => {
      if (item && e) {
        return item.name.toLowerCase().includes(e.toLowerCase());
      }
    });
  }

  onItemMasterSelect(e: InventoryItemMaster) {
    this.units = [];
    this.units.push(e.inventory_item_unit);
    this.itemInventoryFG.patchValue({
      categoryId: e.InventoryCategoryMasterIdFK,
      itemMasterId: e.InventoryItemMasterIdPK,
      unitId: e.InventoryUnitIdFK,
      name: e.name,
    });
  }
  onSelectExpiry(e: any) {
    this.itemInventoryFG.patchValue({
      expiryDate: e.detail.value,
    });
  }

  dismiss() {
    this._modal.dismiss();
  }
}
