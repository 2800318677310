import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { STORAGE_CONSTANT } from 'src/app/shared/constants/app.constants';
import {
  StorageInterface,
  StorageService,
} from 'src/app/shared/services/storage.service';
import { StoreService } from 'src/app/store/store.service';
import { AddDiningAreaComponent } from '../add-dining-area/add-dining-area.component';

@Component({
  selector: 'app-dining-areas',
  templateUrl: './dining-areas.page.html',
  styleUrls: ['./dining-areas.page.scss'],
})
export class DiningAreasPage implements OnInit {
  diningAreas: DiningAreaInterface[] = [];
  storageData: StorageInterface;
  fetchDiningAreas$: Observable<any>;
  constructor(
    private _modal: ModalController,
    private _storageService: StorageService,
    private _storeService: StoreService,
    private _router: Router
  ) {}

  ngOnInit() {
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      this.fetchAllDiningAreas();
    });
  }

  fetchAllDiningAreas() {
    this.fetchDiningAreas$ = this._storeService.getStoreDiningAreas(
      this.storageData?.userDetail?.VendorIdPK
    );

    this.fetchDiningAreas$.subscribe((res) => {
      this.diningAreas = res;
    });
  }

  async openAddDiningModal(diningArea?: DiningAreaInterface) {
    const modal = await this._modal.create({
      component: AddDiningAreaComponent,
      cssClass: 'add-table-modal',
      componentProps: {
        storageData: this.storageData,
        ...(diningArea && { diningArea: diningArea }),
      },
    });

    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchAllDiningAreas();
      }
    });
    modal.present();
  }

  openEditModal(diningArea: DiningAreaInterface) {
    this.openAddDiningModal(diningArea);
  }

  navigateToTables() {
    this._router.navigateByUrl('/tables');
  }
}

export interface DiningAreaInterface {
  DiningAreaIdPK: number;
  VendorIdFK: number;
  name: string;
  totalTables?: number;
}
