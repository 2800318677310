import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import {
  ORDER_STATUS_ENUMS,
  STORAGE_CONSTANT,
} from 'src/app/shared/constants/app.constants';
import { Staffs } from 'src/app/shared/models/staff.model';
import { DeliveryPersonService } from 'src/app/shared/services/delivery-person.service';
import {
  OrderInterface,
  OrderService,
} from 'src/app/shared/services/order.service';
import {
  StorageInterface,
  StorageService,
} from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-active-orders',
  templateUrl: './active-orders.page.html',
  styleUrls: ['./active-orders.page.scss'],
})
export class ActiveOrdersPage implements OnInit {
  currentSelectedTab = 'WAITING';
  fetchOrders$: Observable<any>;
  waitingOrders: OrderInterface[] = [];
  acceptedOrders: OrderInterface[] = [];
  storageData: StorageInterface;
  kitchenUsers: Staffs[] = [];

  constructor(
    private _orderService: OrderService,
    private _storageService: StorageService,
    private _activatedRoute: ActivatedRoute,
    private _staffService: DeliveryPersonService
  ) {
    _activatedRoute.queryParamMap.subscribe((param) => {
      if (param.has('isPast')) {
        this.currentSelectedTab = 'ACCEPTED';
      } else {
        this.currentSelectedTab = 'WAITING';
      }
    });
    _orderService.orderReload$.subscribe((res) => {
      if (res) {
        this.fetchOrders();
      }
    });
  }

  ngOnInit() {
    this.getStorageData();
  }

  getStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res) => {
      if (res) {
        this.storageData = res;
        this.fetchOrders();
        const preference = JSON.parse(this.storageData.userDetail.preferences);
        if (preference.hasOwnProperty('ISKOTENABLE')) {
          if (preference.ISKOTENABLE === 'YES') {
            this.fetchKitchens();
          }
        }
      }
    });
  }

  fetchKitchens() {
    this._staffService
      .fetchDeliveryPerson({
        VendorIdPK: this.storageData.userDetail.VendorIdPK,
        type: 'KITCHEN',
      })
      .subscribe({
        next: (_) => {
          this.kitchenUsers = _.data;
        },
      });
  }

  fetchOrders(event?) {
    this.fetchOrders$ = this._orderService.fetchOrder({
      VendorIdPK: this.storageData?.userDetail?.VendorIdPK,
      status: ORDER_STATUS_ENUMS.ACTIVE,
    });

    this.fetchOrders$.subscribe((res) => {
      if (res) {
        if (event) {
          event.target.complete();
        }
        this.waitingOrders = this._orderService.getWaitingOrders();
        this.acceptedOrders = this._orderService.getAcceptedOrders();
      }
    });
  }

  segmentChanged(e) {
    this.currentSelectedTab = e.detail.value;
  }

  doRefresh(event) {
    this.fetchOrders(event);
  }
}
