import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-with-back',
  templateUrl: './header-with-back.component.html',
  styleUrls: ['./header-with-back.component.scss'],
})
export class HeaderWithBackComponent implements OnInit {
  @Input() backLink;
  @Input() title;
  constructor() {}

  ngOnInit() {}
}
