import { Component, Input, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { MenuItem } from '../shared/models/menu.model';
import { FormErrorService } from '../shared/services/form-error.service';
import { FormService } from '../shared/services/form.service';
import { StorageInterface } from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import { Category, MealTimeList, StoreService } from '../store/store.service';
import { RecipeFetch, Recipes } from '../shared/models/recipes.model';
import { RecipesService } from '../shared/services/recipes.service';
import { Item } from '../KOT-APP/services/order.service';
import {
  InventoryCategories,
  InventoryService,
  InvenyoryUnit,
} from '../shared/services/inventory.service';

@Component({
  selector: 'app-item-create',
  templateUrl: './item-create.component.html',
  styleUrls: ['./item-create.component.scss'],
})
export class ItemCreateComponent implements OnInit {
  @Input() data: {
    categories: Category[];
    mealTimes: MealTimeList[];
    storageData: StorageInterface;
    item?: MenuItem;
  };
  baseUrl = environment.base_url;
  itemImage: any;
  // data: any;
  public file: File = {} as File;
  shortLink: string = '';
  loading: boolean = false;
  activeSegment = 'item';
  @Input() mode: string;
  @Input() type: string;
  addItemFormGroup!: FormGroup;
  addItem$: Observable<any>;
  categories: Category[];
  mealTimes: MealTimeList[];
  storageData: StorageInterface;
  _service = inject(InventoryService);
  category: InventoryCategories[] = [];
  units: InvenyoryUnit[] = [];
  constructor(
    private _modal: ModalController,
    private _formErrorService: FormErrorService,
    private _formBuilder: FormBuilder,
    private _formService: FormService,
    private _storeService: StoreService,
    private _uiService: UiService
  ) {}

  ngOnInit(): void {
    this.categories = this.data.categories;
    this.mealTimes = this.data.mealTimes;
    this.storageData = this.data.storageData;
    this.buildForm();
    this.fetchCategories();
    this.fetchUnits();
    if (this.data.item?.InventoryItemMasterIdFK) {
      this.addItemFormGroup.value.inventoryCategoryMasterId =
        this.data.item.InventoryItemMasterIdFK;
    }
  }

  buildForm() {
    this.addItemFormGroup = this._formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      CategoryIdFK: new FormControl(null, [Validators.required]),
      shortDesc: new FormControl(null),
      price: new FormControl(null, [Validators.required]),
      gstPercent: new FormControl(null),
      type: new FormControl(null, [Validators.required]),
      prepTime: new FormControl(),
      mealTimeIds: new FormControl(null),
      inventoryCategoryMasterId: new FormControl(null),
      inventoryUnitId: new FormControl(null),
      minStockQty: new FormControl(null),
      prepTimeChecked: new FormControl(null),
    });
    if (this.mode === 'EDIT') {
      this.addItemFormGroup.patchValue(this.data.item);
      this.addItemFormGroup.patchValue({
        gstPercent: this.data.item.gstPercent.toString(),
      });
      if (this.data.item.prepTime === -1) {
        this.addItemFormGroup.get('prepTimeChecked').setValue(true);
        this.addItemFormGroup
          .get('inventoryCategoryMasterId')
          .setValue(this.data.item.InventoryItemMasterIdFK);
      }

      if (this.data.item.mealTimeIds) {
        let ids = [];
        this.data.item.mealTimeIds.split(',').forEach((meal) => {
          ids.push(+meal);
        });
        this.addItemFormGroup.patchValue({
          mealTimeIds: ids,
        });
      }
    }
  }
  fetchUnits() {
    this._service.fetchInventoryUnits().subscribe({
      next: (_) => {
        this.units = _.data;
      },
      error: (_) => {
        this._uiService.presentToast(_);
      },
    });
  }

  fetchCategories() {
    this._service
      .fetchInventoryCategories({
        status: 'ACTIVE',
        VendorIdFK: this.storageData?.userDetail?.VendorIdPK,
      })
      .subscribe({
        next: (_) => {
          this.category = _.data;
          if (this.data.item.InventoryItemMasterIdFK) {
            this.addItemFormGroup.value.inventoryCategoryMasterId =
              this.data.item.InventoryItemMasterIdFK;
          }
        },
        error: (_) => {
          this._uiService.presentToast(_);
        },
      });
  }
  getFileDetail(event: any) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.itemImage = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]); // to trigger onload
    }
    this.file = event.target.files[0] as File;
  }

  onAddNewItem() {
    this._formService.markFormAsTouched(this.addItemFormGroup);
    if (!this.addItemFormGroup.valid) {
      return;
    }
    const formData = new FormData();
    if (this.addItemFormGroup.value.prepTimeChecked) {
      formData.append('prepTime', '-1');
      if (
        this.addItemFormGroup.value.inventoryCategoryMasterId &&
        this.addItemFormGroup.value.inventoryUnitId &&
        this.addItemFormGroup.value.minStockQty
      ) {
        formData.append(
          'inventoryCategoryMasterId',
          this.addItemFormGroup.value.inventoryCategoryMasterId
        );
        formData.append(
          'inventoryUnitId',
          this.addItemFormGroup.value.inventoryUnitId
        );
        formData.append('minStockQty', this.addItemFormGroup.value.minStockQty);
      } else {
        this._uiService.presentToast('Please Select Category or Unit or Stock');
        return;
      }
    } else {
      if (this.addItemFormGroup.value.prepTime) {
        formData.append('prepTime', this.addItemFormGroup.value.prepTime);
      }
    }
    formData.append('CategoryIdPK', this.addItemFormGroup.value.CategoryIdFK);
    formData.append(
      'VendorIdPK',
      this.storageData?.userDetail?.VendorIdPK.toString()
    );
    formData.append('name', this.addItemFormGroup.value.name);
    formData.append('price', this.addItemFormGroup.value.price);
    formData.append('itemStatus', 'ACTIVE');
    if (this.addItemFormGroup.value.shortDesc) {
      formData.append('shortDesc', this.addItemFormGroup.value.shortDesc);
    }
    formData.append('type', this.addItemFormGroup.value.type);
    if (this.addItemFormGroup.value.mealTimeIds) {
      formData.append('mealTimeIds', this.addItemFormGroup.value.mealTimeIds);
    }

    formData.append('sortOrder', '0');

    if (this.itemImage) {
      formData.append('image', this.file);
    }
    if (this.mode === 'EDIT') {
      formData.append('ItemIdPK', this.data.item.ItemIdPK.toString());
      this.onUpdateItem(formData);
      return;
    }
    this.addItem$ = this._storeService.createItem(formData);
    this.addItem$.subscribe(
      async (res) => {
        await this._uiService.presentToast('Item added sucessfully!');
        this.addItemFormGroup.patchValue(null);
        this._modal.dismiss(true);
      },
      (error) => {
        this._uiService.presentToast(error);
      }
    );
  }
  onUpdateItem(formData: FormData) {
    this.addItem$ = this._storeService.updateItem(formData);

    this.addItem$.subscribe((res) => {
      if (res) {
        this._uiService.presentToast('Item Updated Successfully!');
        this._modal.dismiss(true);
      }
    });
  }

  onImageLoadError(e: any) {
    e.target.src = '../../assets/images/icons/menu.png';
  }

  onDeleteItem() {
    this.addItem$ = this._storeService.updateItemStatus({
      VendorIdPK: this.storageData?.userDetail?.VendorIdPK,
      ItemIdPK: this.data.item.ItemIdPK,
      status: 'DELETED',
    });

    this.addItem$.subscribe({
      next: (res) => {
        this._uiService.presentToast('Item deleted successfully!');
        this._modal.dismiss(true);
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  dismiss() {
    this._modal.dismiss();
  }

  segmentChanged(e) {
    this.activeSegment = e.target.value;
  }
  public getErrorMessage(fieldName: string, error: string): string {
    return this._formErrorService.getErrorMessage(fieldName, error);
  }
}
