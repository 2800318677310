import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ErrorHandler } from './errorhandler.service';

@Injectable({ providedIn: 'root' })
export class CartService {
  cartData: CartInterface[] = [];

  constructor(
    private storageService: StorageService,
    private http: HttpClient,
    private errorHandler: ErrorHandler
  ) {}
  private dismissModalSubject = new Subject<any>();
  public cartDataChanged = new Subject<any>();
  currentCartItem;

  dismissModal() {
    return this.dismissModalSubject.asObservable();
  }

  setCurrentCartItem(data) {
    this.currentCartItem = data;
  }

  getCurrentCartItem() {
    return this.currentCartItem;
  }

  actionToDismissModal() {
    this.dismissModalSubject.next(true);
  }

  setCartData() {
    //console.log(this.storageService.getCartFromStorage());
    if (
      this.storageService.getCartFromStorage() !== null &&
      this.storageService.getCartFromStorage() !== undefined
    ) {
      this.cartData = this.storageService.getCartFromStorage();
    } else {
      this.cartData = [];
    }
    // //console.log(this.storageService.getCartFromStorage());
  }

  addCartData(data: CartInterface) {
    data.cartId = '' + Date.now();
    this.cartData.push(data);
    this.cartDataChanged.next(this.cartData.slice());
    this.storageService.setCartStorage(this.cartData.slice());
  }

  updateCartData(newItem: CartInterface) {
    if (newItem.isExtra) {
      newItem.items[0].cartId = '' + Date.now();
    }
    if (this.cartData === null || this.cartData === undefined) {
      this.addCartData(newItem);
    } else if (
      this.cartData.find((detail) => detail.ItemIdPK === newItem.ItemIdPK) ===
      undefined
    ) {
      this.addCartData(newItem);
    } else {
      //console.log('updatecartdata');

      let index = this.cartData.findIndex(
        (detail) => +detail.ItemIdPK === +newItem.ItemIdPK
      );
      if (newItem.isExtra) {
        this.cartData[index].items.push(newItem.items[0]);
        this.cartData[index].qty = newItem.qty;
      } else {
        this.cartData[index].qty = newItem.qty;
      }
      this.cartDataChanged.next(this.cartData.slice());
      this.storageService.setCartStorage(this.cartData.slice());
    }
  }

  increaseQtyFromCart(parentItemIdPK, cartId) {
    let index = this.cartData.findIndex(
      (detail) => +detail.ItemIdPK === +parentItemIdPK
    );

    if (this.cartData[index].isExtra) {
      this.cartData[index].items.forEach((item) => {
        if (item.cartId === cartId) {
          item.qty += 1;
        }
      });
    }

    this.cartData[index].qty += 1;
    this.cartDataChanged.next(this.cartData.slice());
    this.storageService.setCartStorage(this.cartData.slice());
  }

  decreaseQtyFromCart(parentItemIdPK, cartId?) {
    let index = this.cartData.findIndex(
      (detail) => +detail.ItemIdPK === +parentItemIdPK
    );

    if (this.cartData[index].isExtra) {
      this.cartData[index].items.forEach((item, i) => {
        if (item.cartId === cartId) {
          item.qty -= 1;
        }
        if (item.qty === 0) {
          this.cartData[index].items.splice(i, 1);
        }
      });
    }

    this.cartData[index].qty -= 1;

    if (this.cartData[index].qty === 0) {
      this.cartData.splice(index, 1);
    }

    this.cartDataChanged.next(this.cartData.slice());
    this.storageService.setCartStorage(this.cartData.slice());
  }

  repeatCartItem(ItemIdPK: number) {
    let index = this.cartData.findIndex(
      (detail) => +detail.ItemIdPK === +ItemIdPK
    );

    this.cartData[index].items[this.cartData[index].items.length - 1].qty += 1;
    this.cartData[index].qty += 1;

    this.cartDataChanged.next(this.cartData.slice());
    this.storageService.setCartStorage(this.cartData.slice());
  }

  getCartPreviousItem(ItemIdPK) {
    let index = this.cartData.findIndex(
      (detail) => +detail.ItemIdPK === +ItemIdPK
    );

    return this.cartData[index].items[this.cartData[index].items.length - 1];
  }

  deleteCartData(ItemIdPK) {
    const index = this.cartData.findIndex(
      (detail) => +detail.ItemIdPK === +ItemIdPK
    );
    this.cartData.splice(index, 1);
    this.cartDataChanged.next(this.cartData.slice());
    this.storageService.setCartStorage(this.cartData.slice());
  }

  isItemRemovePossible(ItemIdPK): boolean {
    const index = this.cartData.findIndex(
      (detail) => +detail.ItemIdPK === +ItemIdPK
    );
    return this.cartData[index].items.length > 1 ? false : true;
  }

  decreaseQtyOfCustomizableItem(ItemIdPK) {
    console.log('dec');

    const index = this.cartData.findIndex(
      (detail) => +detail.ItemIdPK === +ItemIdPK
    );
    this.cartData[index].qty -= 1;
    this.cartData[index].items[this.cartData[index].items.length - 1].qty -= 1;
    if (this.cartData[index].qty <= 0) {
      this.cartData.splice(index, 1);
    }

    this.cartDataChanged.next(this.cartData.slice());
    this.storageService.setCartStorage(this.cartData.slice());
  }

  manageCustomizableItem(data, res) {
    let addOnDetails = [];
    if (res.data.addOnDetails.length) {
      addOnDetails = res.data.addOnDetails;
    }
    if (res.data.hasOwnProperty('variant')) {
      this.updateCartData({
        ItemIdPK: data.ItemIdPK,
        qty: data.qty,
        isExtra: true,
        price: +data.price,
        items: [
          {
            ItemIdPK: res.data.variant.ItemIdPK,
            qty: 1,
            addOnDetails: addOnDetails,
            price: +res.data.variant.price,
          },
        ],
      });
    } else {
      this.updateCartData({
        ItemIdPK: data.ItemIdPK,
        qty: data.qty,
        isExtra: true,
        price: +data.price,
        items: [
          {
            ItemIdPK: data.ItemIdPK,
            qty: 1,
            addOnDetails: addOnDetails,
            price: +data.price,
          },
        ],
        ...(data.InventoryItemMasterIdFK && {
          InventoryItemMasterIdFK: data.InventoryItemMasterIdFK,
        }),
      });
    }
  }

  getCartData(ItemIdPK) {
    // const updateItem = this.cartData.find(ItemIdPK);
    //console.log(this.cartData);
    if (this.cartData === null || this.cartData === undefined) {
      return;
    }
    return this.cartData.find((detail) => detail.ItemIdPK === ItemIdPK);
  }

  getFullCartData() {
    return [...this.cartData];
  }

  getCartLength(): number {
    let qty = 0;
    this.cartData.forEach((cart) => {
      if (cart.isExtra) {
        cart.items.forEach((item) => {
          qty = qty + item.qty;
        });
      } else {
        qty = qty + cart.qty;
      }
    });
    return qty;
  }

  fetchCartDetail() {
    let itemIds = '';
    this.cartData.forEach((element) => {
      if (element.isExtra) {
        element.items.forEach((item) => {
          if (itemIds === '') {
            itemIds = '' + item.ItemIdPK;
          } else {
            itemIds = itemIds + ',' + item.ItemIdPK;
          }
        });
      } else {
        if (itemIds === '') {
          itemIds = '' + element.ItemIdPK;
        } else {
          itemIds = itemIds + ',' + element.ItemIdPK;
        }
      }
    });
    //console.log(itemIds);
    return this.http
      .get(environment.base_url + environment.fetch_item_by_ids_api, {
        params: {
          itemIds: itemIds,
        },
      })
      .pipe(
        map((res: any) => {
          return this.prepareCartDisplayData(res.data, this.cartData);
        })
      );
  }

  prepareCartDisplayData(res, localCartData?) {
    let sendData = [];

    this.cartData.forEach((cart) => {
      if (cart.isExtra) {
        cart.items.forEach((item) => {
          res.forEach((data) => {
            if (+data.ItemIdPK === +item.ItemIdPK) {
              data.cartPrice = +item.qty * +data.price;
              if (item.addOnDetails.length) {
                data.addOnNames =
                  '' + item.addOnDetails.map((addOn) => addOn.name);
                item.addOnDetails.forEach((addOn) => {
                  data.cartPrice += +item.qty * +addOn.price;
                });
              }
              data.qty = item.qty;
              data.parentItemIdPK = cart.ItemIdPK;
              data.addOnDetails = item.addOnDetails;
              data.cartId = item.cartId;
              localCartData.forEach((localCart) => {
                if (
                  +localCart.ItemIdPK === +cart.ItemIdPK &&
                  localCart.InventoryItemMasterIdFK
                ) {
                  data.InventoryItemMasterIdFK =
                    localCart.InventoryItemMasterIdFK;
                }
              });

              sendData.push(JSON.parse(JSON.stringify(data)));
              //console.log('it-data', data);
            }
          });
        });
      } else {
        res.forEach((data) => {
          if (+data.ItemIdPK === +cart.ItemIdPK) {
            data.cartPrice = +cart.qty * +data.price;
            data.qty = cart.qty;
            data.parentItemIdPK = cart.ItemIdPK;
            data.cartId = cart.cartId;
            localCartData.forEach((localCart) => {
              if (
                +localCart.ItemIdPK === +cart.ItemIdPK &&
                localCart.InventoryItemMasterIdFK
              ) {
                data.InventoryItemMasterIdFK =
                  localCart.InventoryItemMasterIdFK;
              }
            });
            sendData.push(data);
          }
        });
      }
    });
    return sendData;
  }

  placeOrder(
    totalPrice,
    data,
    customerKey,
    vendorKey,
    currentTablePK,
    schedule?
  ) {
    return this.http.post(
      environment.base_url + 'mobile/' + customerKey + '/addorder',
      {
        VendorIdPK: vendorKey,
        totalPrice,
        status: 'WAITING',
        TableIdPK: currentTablePK,
        orderDetail: data,
        ...(schedule && { scheduleTime: schedule }),
      },
      {
        observe: 'response',
      }
    );
  }

  updateOrder(totalPrice, data) {
    return this.http.post(
      environment.base_url + 'mobile/customer/editorder',
      {
        totalPrice,
        orderDetail: data,
        status: 'PARTIALLY-ACCEPTED',
      },
      {
        observe: 'response',
      }
    );
  }

  senOtp(mobile) {
    return this.http.post(
      environment.base_url + environment.otp_send,
      {
        mobile,
      },
      {
        observe: 'response',
      }
    );
  }

  updateUserStatus(status, customerKey) {
    return this.http.patch(
      environment.base_url +
        environment.edit_customer +
        '?CustomerIdPK=' +
        customerKey,
      { status }
    );
  }

  checkRunningOrderStatus(CustomerIdPK, orderType: string, VendorIdPK) {
    return this.http.get(
      `${environment.base_url}${environment.check_running_order}`,
      {
        params: {
          CustomerIdPK,
          orderType,
          VendorIdPK,
        },
      }
    );
  }

  fetchVendorDetail(vendorCode, tableNumber): Observable<any> {
    return this.http
      .get(environment.base_url + environment.vendor_fetch, {
        params: {
          vendorCode,
          tableNumber,
        },
      })
      .pipe(catchError(this.errorHandler.handleError));
  }

  getCartTotalPrice(data): number {
    let total = 0;
    // this.cartData.forEach((cart) => {
    //   if (cart.isExtra) {
    //     cart.items.forEach((item) => {
    //       total = total + this.getPriceFromCartItem(data, item.ItemIdPK);
    //       // if (item.addOnDetails.length) {
    //       //   item.addOnDetails.forEach((addOn) => {
    //       //     total = total + addOn.price;
    //       //     console.log('addon', addOn.price);
    //       //   });
    //       // }
    //     });
    //   } else {
    //     total = total + this.getPriceFromCartItem(data, cart.ItemIdPK);
    //   }
    // });
    // console.log('total', total);

    data.forEach((item) => {
      total = total + item.cartPrice;
    });
    return total;
  }

  getPriceFromCartItem(data, ItemIdPK): number {
    let price = 0;
    data.forEach((itemData) => {
      if (+itemData.ItemIdPK === +ItemIdPK) {
        price = +itemData.qty * +itemData.cartPrice;
      }
    });

    return price;
  }

  getCustomizeItemFromCart(ItemIdPK: number): CartInterface {
    return this.cartData.find((cart) => {
      return +cart.ItemIdPK === +ItemIdPK;
    });
  }

  getCartTotalPriceForStore(): number {
    let total = 0;
    this.cartData.forEach((cart) => {
      if (cart.isExtra) {
        cart.items.forEach((item) => {
          total += +item.qty * +item.price;
          if (item.addOnDetails.length) {
            item.addOnDetails.forEach((addOn) => {
              total += +addOn.price;
            });
          }
        });
      } else {
        total += +cart.qty * +cart.price;
      }
    });
    return total;
  }

  clearCartFromStorage() {
    localStorage.removeItem('cart');
    this.cartData = [];
    this.cartDataChanged.next(this.cartData);
  }
}

export interface VendorDetailResponseInterface {
  message: string;
  statusCode: number;
  TableIdPK: number;
  data: Data;
}

export interface Data {
  VendorIdPK: number;
  UserIdFK: number;
  CityIdFK: number;
  vendorCode: string;
  vendorQrCode?: any;
  vendorName: string;
  vendorStoreName: string;
  vendorStoreType: string;
  storeTiming?: any;
  vendorAppType?: any;
  serviceType?: any;
  vendorLocation: string;
  vendorLat: string;
  vendorLang: string;
  status: string;
  expirationDate: string;
  smsLimit: number;
  subscriptionType: string;
  creationDate: string;
  mobile: string;
  alternateMobile: string;
  email: string;
  token: string;
  preferences: string;
  printWidth: number;
  deliveryDistance?: any;
}

export interface AddOnCartItem {
  id: number;
  name: string;
  price: number;
}

export interface CartItems {
  ItemIdPK: number;
  addOnDetails: AddOnCartItem[];
  qty: number;
  cartId?: string;
  price: number;
}

export interface CartInterface {
  ItemIdPK: number;
  items?: CartItems[];
  qty: number;
  isExtra?: boolean; // this will use to identify addons and variant
  cartId?: string;
  price: number;
  InventoryItemMasterIdFK?: number; // will be use to send in order add so that in BE inventory deduction can work properly
}
