import { Component, Input, OnInit } from '@angular/core';
import { OrderInterface } from 'src/app/shared/services/order.service';

@Component({
  selector: 'app-cancelled-orders',
  templateUrl: './cancelled-orders.page.html',
  styleUrls: ['./cancelled-orders.page.scss'],
})
export class CancelledOrdersPage implements OnInit {
  @Input() orders: OrderInterface[] = [];
  constructor() {}

  ngOnInit() {}
}
