import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import {
  ADD_ORDER_API,
  ADD_OR_FETCH_CUSTOMER,
  FETCH_ALL_TABLES,
  ORDER_FETCH_API,
  ORDER_UPDATE_API,
  UPDATE_EXISTING_ORDER,
} from '../constants/apis.constant';
import {
  ORDER_STATUS_ENUMS,
  ORDER_TYPES_ENUM,
} from '../constants/app.constants';
import { ErrorHandler } from './errorhandler.service';
import { StorageInterface } from './storage.service';
import {
  OrderApiResponse,
  ProductReportFetchApiResponse,
} from '../models/product-report.model';
import { CategoryReportFetchApiResponse } from '../models/category-report.model';

@Injectable({ providedIn: 'root' })
export class OrderService {
  orderDetails: OrderInterface[] = [];
  orderReload$: Subject<boolean> = new Subject<boolean>();

  constructor(private _http: HttpClient, private _errorHandler: ErrorHandler) {}

  fetchOrder(params: any): Observable<any> {
    return this._http
      .get(environment.base_url + ORDER_FETCH_API, { params })
      .pipe(
        catchError(this._errorHandler.handleError),
        map((res: any) => {
          res = this.preparedOrderDetail(res);
          this.orderDetails = res.data;
          return res;
        })
      );
  }
  checkSession(data: any): Observable<any> {
    return this._http.get(environment.base_url + environment.vendor_session, {
      params: data,
    });
  }
  updateWaitingOrder(order: OrderInterface): Observable<any> {
    return this._http
      .patch(
        environment.base_url + ORDER_UPDATE_API,
        this.prepareOrderUpdateForWaitingOrders(order)
      )
      .pipe(
        catchError(this._errorHandler.handleError),
        map((res) => {
          if (res) {
            this.orderReload$.next(true);
          }
          return res;
        })
      );
  }

  updateAcceptedOrder(order: OrderInterface): Observable<any> {
    return this._http
      .patch(
        environment.base_url + ORDER_UPDATE_API,
        this.prepareOrderUpdateForAcceptedOrders(order)
      )
      .pipe(
        catchError(this._errorHandler.handleError),
        map((res) => {
          if (res) {
            this.orderReload$.next(true);
          }
          return res;
        })
      );
  }

  updateAcceptedOrderToOutForDelivery(
    order: OrderInterface,
    deliveryPersonId: number
  ): Observable<any> {
    return this._http
      .patch(
        environment.base_url + ORDER_UPDATE_API,
        this.prepareOrderUpdateForOutForDelivery(order, deliveryPersonId)
      )
      .pipe(
        catchError(this._errorHandler.handleError),
        map((res) => {
          if (res) {
            this.orderReload$.next(true);
          }
          return res;
        })
      );
  }

  prepareOrderUpdateForOutForDelivery(
    order: OrderInterface,
    deliveryPersonId: number
  ) {
    return {
      OrderIdPK: order.OrderIdPK,
      update: {
        status: 'OUT_FOR_DELIVERY',
        DeliveryPersonIdFK: deliveryPersonId,
      },
      totalPrice: order.totalPrice,
    };
  }

  updatePartiallyAcceptedOrder(order: OrderInterface) {
    return this._http
      .patch(
        environment.base_url + ORDER_UPDATE_API,
        this.prepareOrderUpdateForPartiallyAcceptedOrders(order)
      )
      .pipe(
        catchError(this._errorHandler.handleError),
        map((res) => {
          if (res) {
            this.orderReload$.next(true);
          }
          return res;
        })
      );
  }

  rejectOrder(order: OrderInterface): Observable<any> {
    return this._http
      .patch(
        environment.base_url + ORDER_UPDATE_API,
        this.prepareOrderUpdateFormRejectedOrders(order)
      )
      .pipe(
        catchError(this._errorHandler.handleError),
        map((res) => {
          if (res) {
            this.orderReload$.next(true);
          }
          return res;
        })
      );
  }

  addOrder(
    data,
    obj: {
      storageData: StorageInterface;
      totalPrice: any;
      paymentMode: string;
      discount: number;
      TableIdPK: number;
      KitchenIdFK?: number;
      isTakeaway?: number;
      tokenNumber?: string;
    },
    isCompleted?: boolean
  ) {
    let sendData = JSON.parse(JSON.stringify(data));

    sendData.forEach((value) => {
      value.price = +value.cartPrice;
    });
    return this._http
      .post(
        environment.base_url + ADD_ORDER_API,
        this.prepareOrderAdd(sendData, obj, isCompleted)
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  getOrAddCustomer(data) {
    return this._http
      .post(environment.base_url + ADD_OR_FETCH_CUSTOMER, data)
      .pipe(catchError(this._errorHandler.handleError));
  }

  fetchAllTables(vendorId: string) {
    return this._http
      .get<any>(
        environment.base_url + FETCH_ALL_TABLES.replace('{_id}', vendorId),
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(
        map((response) => {
          if (response.status === 200) {
            return response.body.data;
          } else {
            return [];
          }
        }),
        catchError(this._errorHandler.handleError)
      );
  }

  addItemInExistingOrder(obj: { cartDetails; currentOrderId; totalPrice }) {
    return this._http
      .post(
        environment.base_url + UPDATE_EXISTING_ORDER,
        this.prepareOrderUpdate(obj)
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  prepareOrderUpdate(obj: { cartDetails; currentOrderId; totalPrice }): any {
    const orderDetail: any = [];
    let tempObj;
    let i = 0;
    obj.cartDetails.forEach((element) => {
      tempObj = {
        OrderIdFK: obj.currentOrderId,
        CategoryIdFK: element.CategoryIdFK,
        ItemIdFK: element.ItemIdPK,
        quantity: element.qty,
        price: element.cartPrice,
        status: 'ACCEPTED',
        addOnDetails: JSON.stringify(element.addOnDetails),
      };
      orderDetail[i] = tempObj;
      i++;
    });

    return {
      totalPrice: obj.totalPrice,
      orderDetail,
    };
  }

  prepareOrderAdd(
    cartDetails,
    obj: {
      storageData: StorageInterface;
      totalPrice: any;
      paymentMode: string;
      discount: number;
      TableIdPK: number;
      KitchenIdFK?: number;
      isTakeaway?: number;
      tokenNumber?: string;
    },
    isCompleted?: boolean
  ): any {
    const orderDetail: any = [];
    let tempObj;
    let i = 0;
    cartDetails.forEach((element) => {
      tempObj = {
        CategoryIdFK: element.CategoryIdFK,
        ItemIdFK: element.ItemIdPK,
        quantity: element.qty,
        price: element.price,
        status: element.InventoryItemMasterIdFK
          ? ORDER_STATUS_ENUMS.PREPARED
          : ORDER_STATUS_ENUMS.ACCEPTED,
        addOnDetails: JSON.stringify(element.addOnDetails),
        ...(obj.storageData.customerIdPK && {
          CustomerIdPK: obj.storageData.customerIdPK,
        }),
        ...(element.InventoryItemMasterIdFK && {
          InventoryItemMasterIdFK: element.InventoryItemMasterIdFK,
        }),
      };
      orderDetail[i] = tempObj;
      i++;
    });

    return {
      VendorIdPK: obj.storageData.userDetail.VendorIdPK,
      totalPrice: obj.totalPrice,
      status: isCompleted
        ? ORDER_STATUS_ENUMS.COMPLETED
        : ORDER_STATUS_ENUMS.ACCEPTED,
      TableIdPK: obj.TableIdPK,
      orderDetail,
      CustomerIdPK: obj.storageData.customerIdPK
        ? obj.storageData.customerIdPK
        : 0,
      addedByOperatorId: obj.storageData.userDetail.UserIdFK,
      paymentMode: obj.paymentMode,
      discount: obj.discount,
      ...(obj.KitchenIdFK && { KitchenIdFK: obj.KitchenIdFK }),
      ...(obj.isTakeaway && { isTakeaway: obj.isTakeaway }),
      ...(obj.tokenNumber && { tokenNumber: obj.tokenNumber }),
    };
  }

  prepareOrderUpdateForAcceptedOrders(order: OrderInterface): any {
    return {
      OrderIdPK: order.OrderIdPK,
      update: {
        status: 'COMPLETED',
        discount: order.discount ? order.discount : 0,
        paymentMode: order.paymentMode,
      },
      totalPrice: order.totalPrice,
    };
  }

  prepareOrderUpdateForPartiallyAcceptedOrders(order: OrderInterface): any {
    const acceptedOrdersId = [];
    const rejectedOrdersId = [];
    order.orderdetails.forEach((element) => {
      if (element.status === ORDER_STATUS_ENUMS.WAITING && element.checked) {
        acceptedOrdersId.push(element.id);
        order.actualWaitingPrice =
          +order.actualWaitingPrice - +element.quantity * +element.price;
      } else if (
        element.status === ORDER_STATUS_ENUMS.WAITING &&
        !element.checked
      ) {
        rejectedOrdersId.push(element.id);
      }
    });

    return {
      OrderIdPK: order.OrderIdPK,
      update: {
        status: ORDER_STATUS_ENUMS.ACCEPTED,
        paymentMode: order.paymentMode,
        discount: order.discount ? order.discount : 0,
      },
      acceptedOrderDetailIds: acceptedOrdersId,
      rejectedOrderDetailIds: rejectedOrdersId,
      totalPrice: order.totalPrice,
    };
  }

  prepareOrderUpdateForWaitingOrders(order: OrderInterface): any {
    const acceptedOrdersId = [];
    const rejectedOrdersId = [];
    order.orderdetails.forEach((element) => {
      if (element.checked) {
        acceptedOrdersId.push(element.id);
      } else {
        if (element.status === ORDER_STATUS_ENUMS.WAITING) {
          order.actualWaitingPrice =
            +order.actualWaitingPrice - +element.quantity * +element.price;
        }
        rejectedOrdersId.push(element.id);
      }
    });

    return {
      OrderIdPK: order.OrderIdPK,
      update: {
        status: ORDER_STATUS_ENUMS.ACCEPTED,
        paymentMode: order.paymentMode,
        discount: order.discount ? order.discount : 0,
        ...(order.tokenNumber && { tokenNumber: order.tokenNumber }),
        ...(order.KitchenIdFK && { KitchenIdFK: order.KitchenIdFK }),
        ...(order.isTakeaway && { isTakeaway: order.isTakeaway }),
      },
      orderDetails: order.orderdetails,
      acceptedOrderDetailIds: acceptedOrdersId,
      rejectedOrderDetailIds: rejectedOrdersId,
      totalPrice: order.totalPrice,
    };
  }

  prepareOrderUpdateFormRejectedOrders(order: OrderInterface): any {
    return {
      OrderIdPK: order.OrderIdPK,
      update: {
        status: ORDER_STATUS_ENUMS.REJECTED,
      },
      rejectedOrderDetailIds: order.orderdetails.map((_) => _.id),
      totalPrice: order.actualWaitingPrice,
      cancellationReason: order.cancellationReason,
    };
  }

  preparedOrderDetail(res: any) {
    res.data.forEach((element: OrderInterface) => {
      element.orderdetails.forEach((elementNested) => {
        if (elementNested.addOnDetails) {
          elementNested.addOnDetails = JSON.parse(elementNested.addOnDetails);
          elementNested.addOnNames =
            '' + elementNested.addOnDetails.map((addOn) => addOn.name);
        }
        if (
          element.status !== ORDER_STATUS_ENUMS.REJECTED &&
          element.status !== ORDER_STATUS_ENUMS.COMPLETED
        ) {
          element.timer =
            (new Date().valueOf() -
              new Date(element.orderPlacedTime).valueOf()) /
            (60 * 1000);
        }
        element.actualWaitingPrice = element.actualWaitingPrice
          ? element.actualWaitingPrice
          : 0;
        element.actualAcceptedPrice = element.actualAcceptedPrice
          ? element.actualAcceptedPrice
          : 0;

        element.orderButtonText =
          element.status === ORDER_STATUS_ENUMS.WAITING
            ? 'Accept Order'
            : ORDER_STATUS_ENUMS.PARTIALLY_ACCEPTED
            ? 'Update Order'
            : element.paymentMode === 'POSTPAID'
            ? 'Complete Payment'
            : 'Complete Order';

        // For Delivery flow if order type is HOME_DELIVERY then we will make order button text
        // as 'Assign Order' and open delivery person list

        if (
          element.type === ORDER_TYPES_ENUM.HOME_DELIVERY &&
          (element.status === ORDER_STATUS_ENUMS.ACCEPTED ||
            element.status === ORDER_STATUS_ENUMS.PREPARED)
        ) {
          element.orderButtonText = 'Assign Order';
        }

        if (elementNested.status === ORDER_STATUS_ENUMS.REJECTED) {
          elementNested.checked = false;
        } else {
          elementNested.checked = true;
        }

        if (
          elementNested.status === ORDER_STATUS_ENUMS.ACCEPTED ||
          elementNested.status === ORDER_STATUS_ENUMS.PREPARED ||
          elementNested.status === ORDER_STATUS_ENUMS.PREPARING
        ) {
          element.actualAcceptedPrice =
            element.actualAcceptedPrice + +elementNested.price;
          elementNested.price = +elementNested.price;

          // if (elementNested.addOnDetails) {
          //   elementNested.addOnDetails.forEach((addOn) => {
          //     element.actualAcceptedPrice += +addOn.price;
          //     elementNested.price += +addOn.price;
          //   });
          // }
        }

        if (elementNested.status === ORDER_STATUS_ENUMS.WAITING) {
          element.actualWaitingPrice =
            element.actualWaitingPrice + +elementNested.price;
          elementNested.price = +elementNested.price;
          // if (elementNested.addOnDetails) {
          //   elementNested.addOnDetails.forEach((addOn) => {
          //     element.actualWaitingPrice += +addOn.price;
          //     elementNested.price += +addOn.price;
          //   });
          // }
        }
      });
      element.totalPrice =
        element.actualWaitingPrice + element.actualAcceptedPrice;

      // check for invalid date
      if (element.orderPlacedTime.includes('NaN')) {
        element.orderPlacedTime = null;
      }

      // check for customer name
      if (element.customer) {
        if (!element.customer.name) {
          element.customer.name = 'Customer';
        }
        if (!element.customer.mobile) {
          element.customer.mobile = 'NA';
        }
      } else {
        element.customer = {} as Customer;
        element.customer.name = 'Customer';
        element.customer.mobile = 'NA';
      }

      // delivery address

      if (element.deliveryAddress)
        element.deliveryAddressData = JSON.parse(element.deliveryAddress);
    });

    return res;
  }

  getWaitingOrders(): OrderInterface[] {
    return this.orderDetails.filter((order) => {
      if (
        order.status === ORDER_STATUS_ENUMS.WAITING ||
        order.status === ORDER_STATUS_ENUMS.PARTIALLY_ACCEPTED
      ) {
        return order;
      }
    });
  }

  getAcceptedOrders(): OrderInterface[] {
    return this.orderDetails.filter((order) => {
      if (
        order.status === ORDER_STATUS_ENUMS.ACCEPTED ||
        order.status === ORDER_STATUS_ENUMS.OUT_FOR_DELIVERY ||
        order.status === ORDER_STATUS_ENUMS.PARTIALLY_ACCEPTED ||
        order.status === ORDER_STATUS_ENUMS.PREPARING ||
        order.status === ORDER_STATUS_ENUMS.PREPARED
      ) {
        return order;
      }
    });
  }

  getCompletedOrders(): OrderInterface[] {
    return this.orderDetails.filter((order) => {
      if (order.status === ORDER_STATUS_ENUMS.COMPLETED) {
        return order;
      }
    });
  }

  getRejectedOrders(): OrderInterface[] {
    return this.orderDetails.filter((order) => {
      if (order.status === ORDER_STATUS_ENUMS.REJECTED) {
        return order;
      }
    });
  }

  // View Detailed Invoice at easeinn.app/[url]

  senWhatsappMessageOnOrderCreate(responseData, storageData: StorageInterface) {
    let message = `Hello Foodie!%0D%0A%0D%0AThank you for visiting ${
      storageData.userDetail.vendorStoreName
    }! %0D%0A%0D%0ATotal Bill Amount is ₹${
      responseData.data.order.totalPrice
    }  %0D%0ADate: ${
      responseData.data.order.orderPlacedTime
    }  %0D%0A%0D%0ATo checkout our latest offers and delicious menu, click on the blow link:  %0D%0A%0D%0Ahttps://easeinn.app/menu/${storageData.userDetail.vendorCode.slice(
      2,
      storageData.userDetail.vendorCode.length
    )}0D0T0`;

    window.open(
      `https://api.whatsapp.com/send?phone=+91${storageData.customerMobileNumber}&text=${message}`
    );
  }

  statsFetch(id?: number, params?: any): Observable<any> {
    return this._http.get(
      environment.base_url +
        environment.fetch_dashboard_stats.replace('{id}', id.toString()),
      { ...(params && { params }) }
    );
  }

  fetchVendorSalesReport(VendorIdPK: number, params: any): Observable<any> {
    return this._http.get(
      `${environment.base_url}vendor/${VendorIdPK}/fetchsalesreport`,
      {
        params: params,
      }
    );
    // return this._http.get(ORDER_FETCH_API, this.httpParams).pipe(
    //   map((res: any) => {
    //     console.log(res);
    //   })
    // );
  }
  fetchCustomerDetailsReport(params: any): Observable<any> {
    return this._http.get(
      `${
        environment.base_url + environment.fetch_customer_details_report_method
      }`,
      {
        params: params,
      }
    );
    // return this._http.get(ORDER_FETCH_API, this.httpParams).pipe(
    //   map((res: any) => {
    //     console.log(res);
    //   })
    // );
  }

  fetchProductReport(
    vendorId: number,
    data: any
  ): Observable<ProductReportFetchApiResponse> {
    return this._http
      .get<ProductReportFetchApiResponse>(
        environment.base_url +
          environment.fetch_product_report_method.replace(
            '{id}',
            vendorId.toString()
          ),
        {
          params: data,
        }
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  fetchOrderReport(vendorId: number, data: any): Observable<OrderApiResponse> {
    return this._http
      .get<OrderApiResponse>(
        environment.base_url +
          environment.fetch_order_report_method.replace(
            '{id}',
            vendorId.toString()
          ),
        {
          params: data,
        }
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  fetchCategoryStatsReport(vendorId: number, data?: any): Observable<any> {
    return this._http
      .get<any>(
        environment.base_url +
          environment.fetch_category_stats_method.replace(
            '{id}',
            vendorId.toString()
          ),
        {
          params: data,
        }
      )
      .pipe(catchError(this._errorHandler.handleError));
  }
  fetchCategoryReport(
    vendorId: number,
    data: any
  ): Observable<CategoryReportFetchApiResponse> {
    return this._http
      .get<CategoryReportFetchApiResponse>(
        environment.base_url +
          environment.fetch_category_report_method.replace(
            '{id}',
            vendorId.toString()
          ),
        {
          params: data,
        }
      )
      .pipe(catchError(this._errorHandler.handleError));
  }
}

export interface OrderInterface {
  OrderIdPK: number;
  CustomerIdFK: number;
  VendorIdFK: number;
  orderPlacedId: string;
  orderPlacedTime: string;
  scheduleTime?: any;
  totalPrice: string | number;
  gstCharges?: any;
  deliveryCharges?: any;
  discount: number;
  status: string;
  type: string;
  TableIdFK: number;
  paymentMode?: any;
  addedByOperatorId?: any;
  customer: Customer;
  orderdetails: Orderdetail[];
  user?: any;
  actualWaitingPrice?: number;
  actualAcceptedPrice?: number;
  timer?: any;
  showList?: boolean;
  showReject?: boolean;
  orderButtonText?: string;
  cancellationReason?: string;
  seating_table?: {
    DiningAreaIdFK: number | null;
    OrderIdFK: number;
    TableIdPK: number;
    VendorIdFK: number;
    maxCapacity: number;
    tableDescription: null | number;
    tableNumber: number;
    tableShape: null | string;
    tableStatus: string;
    dining_area?: {
      name: string;
    };
  };
  deliveryAddress?: any;
  deliveryAddressData?: {
    location: string;
    latitude: string;
    longitude: string;
    area: string;
    landmark: string;
    city: string;
  };
  isTakeaway?: number;
  tokenNumber?: string;
  KitchenIdFK?: number;
}

export interface Customer {
  CustomerIdPK: number;
  name?: any;
  mobile: string;
  status: string;
  token: string;
  otpSentTime: string;
}

export interface Item {
  ItemIdPK: number;
  CategoryIdFK: number;
  VendorIdFK: number;
  name: string;
  image: string;
  price: number;
  gstPercent: number;
  type: string;
  shortDesc: string;
  status: string;
  InventoryItemMasterIdFK?: number;
}

export interface Orderdetail {
  id: number;
  OrderIdFK: number;
  CategoryIdFK: number;
  ItemIdFK: number;
  quantity: number;
  price: number;
  status: string;
  item: Item;
  checked?: boolean;
  addOnDetails?: any;
  addOnNames?: string;
  InventoryItemMasterIdFK?: number;
}
