import { Location } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { InventoryCreatePage } from '../inventory-create/inventory-create.page';
import { ModalController } from '@ionic/angular';
import { InventoryMasterCreatePage } from '../inventory-master-create/inventory-master-create.page';
import {
  StorageInterface,
  StorageService,
} from 'src/app/shared/services/storage.service';
import { STORAGE_CONSTANT } from 'src/app/shared/constants/app.constants';
import {
  InventoryItem,
  InventoryItemMaster,
  InventoryList,
  InventoryReportInterface,
  InventoryService,
  InventoryStatsResponse,
} from 'src/app/shared/services/inventory.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { StockInPage } from '../stock-in/stock-in.page';
import { ActivatedRoute } from '@angular/router';
import { StockOutPage } from '../stock-out/stock-out.page';

@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.page.html',
  styleUrls: ['./inventory-list.page.scss'],
})
export class InventoryListPage implements OnInit {
  _storageService = inject(StorageService);
  _service = inject(InventoryService);
  _uiService = inject(UiService);
  _route = inject(ActivatedRoute);
  storageData!: StorageInterface;
  inventoryList: InventoryList[] = [];
  selectedSegment = 'ALL';
  report: InventoryReportInterface = {} as InventoryReportInterface;
  categoryId;
  isNoDataFound: boolean = false;
  constructor(private _location: Location, private _modal: ModalController) {}

  ngOnInit() {}

  listenRouteChanges() {
    this._route.queryParams.subscribe({
      next: (_) => {
        if (_.hasOwnProperty('categoryId')) {
          this.categoryId = _.categoryId;
        }
        if (_.hasOwnProperty('segment')) {
          this.selectedSegment = _.segment;
        }
        this.fetchInventoryList();
        this.fetchInventoryReportByCategory(this.categoryId);
      },
    });
  }

  ionViewWillEnter() {
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res: any) => {
      this.storageData = res;
      this.listenRouteChanges();
    });
  }

  fetchInventoryList() {
    this._service
      .fetchInventoryList(this.storageData?.userDetail?.VendorIdPK, {
        ...(this.selectedSegment === 'NEAR_EXPIRY' && { near_expiry: true }),
        ...(this.selectedSegment === 'LOW_STOCK' && { low_stock: true }),
        ...(this.categoryId && { categoryId: this.categoryId }),
      })
      .subscribe({
        next: (_) => {
          this.inventoryList = _.data;
        },
        error: (_) => {
          this._uiService.presentToast(_);
        },
      });
  }

  fetchInventoryReportByCategory(isCategoryId?: number) {
    // InventoryCategoryMasterIdFK
    this._service
      .fetchInventoryStats(this.storageData?.userDetail?.VendorIdPK, {
        ...(isCategoryId && { InventoryCategoryMasterIdFK: isCategoryId }),
      })
      .subscribe({
        next: (_: InventoryStatsResponse) => {
          this.report = _.data;
        },
        error: (_) => {
          this._uiService.presentToast(_);
        },
      });
  }

  goToPreviousScreen() {
    this._location.back();
  }

  async openAddItemMasterModal(itemMaster?: InventoryItemMaster) {
    const modal = await this._modal.create({
      component: InventoryMasterCreatePage,
      animated: true,
      componentProps: {
        vendorId: this.storageData?.userDetail?.VendorIdPK,
        itemMaster,
        categoryId: this.categoryId,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchInventoryList();
      }
    });
    modal.present();
  }

  async openAddItemModal(
    itemMaster?: InventoryItemMaster,
    inventoryItem?: InventoryItem
  ) {
    const modal = await this._modal.create({
      component: InventoryCreatePage,
      animated: true,
      componentProps: {
        vendorId: this.storageData?.userDetail?.VendorIdPK,
        createdBy: this.storageData?.userDetail?.UserIdFK,
        itemMaster,
        inventoryItem,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchInventoryList();
      }
    });
    modal.present();
  }

  onChangeSegment(e: any) {
    if (e.detail.value === 'NEAR_EXPIRY') {
      this.isNoDataFound = this.report.nearExpiryItemCount === 0 ? true : false;
    }
    if (e.detail.value === 'LOW_STOCK') {
      this.isNoDataFound = this.report.lowStockItemCount === 0 ? true : false;
    }
    if (e.detail.value === 'ALL') {
      this.isNoDataFound = this.report.totalItemCount === 0 ? true : false;
    }

    this.fetchInventoryList();
  }

  async openAddStockInModal() {
    const modal = await this._modal.create({
      component: StockInPage,
      animated: true,
      componentProps: {
        vendorId: this.storageData?.userDetail?.VendorIdPK,
        createdBy: this.storageData?.userDetail?.UserIdFK,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchInventoryList();
      }
    });
    modal.present();
  }
  async openAddStockOutModal() {
    const modal = await this._modal.create({
      component: StockOutPage,
      animated: true,
      componentProps: {
        vendorId: this.storageData?.userDetail?.VendorIdPK,
        userId: this.storageData?.userDetail?.UserIdFK,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchInventoryList();
      }
    });
    modal.present();
  }
}
