import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MenuItem } from '../shared/models/menu.model';
import { StorageInterface } from '../shared/services/storage.service';
import { Category, MealTimeList } from '../store/store.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { RecipeFetch, Recipes } from '../shared/models/recipes.model';
import { RecipesService } from '../shared/services/recipes.service';
import { UiService } from '../shared/services/ui.service';
import {
  MenuRecipeInterface,
  MenuRecipeFetch,
} from '../shared/models/menu-recipe.model';

@Component({
  selector: 'app-item-recipe',
  templateUrl: './item-recipe.component.html',
  styleUrls: ['./item-recipe.component.scss'],
})
export class ItemRecipeComponent implements OnInit {
  @Input() data: {
    categories: Category[];
    mealTimes: MealTimeList[];
    storageData: StorageInterface;
    item?: MenuItem;
  };
  searchFormControl: FormControl = new FormControl();
  fetchRecipe$!: Observable<RecipeFetch>;
  recipes: Recipes[] = [];
  allRecipes: Recipes[] = [];
  menuRecipes: MenuRecipeInterface[] = [];

  constructor(
    private recipeService: RecipesService,
    private _uiService: UiService
  ) {}

  ngOnInit() {
    this.fetchRecipes();
  }
  fetchRecipes() {
    this.fetchRecipe$ = this.recipeService.fetchRecipes({
      vendorId: this.data.storageData?.userDetail?.VendorIdPK,
    });
    this.fetchRecipe$.subscribe({
      next: (res: RecipeFetch) => {
        this.recipes = res.data;
        this.allRecipes = JSON.parse(JSON.stringify(this.recipes));
        this.loadSearchFilter();
        this.fetchMenuRecipes();
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }
  fetchMenuRecipes() {
    this.recipeService
      .fetchMenuRecipes({
        itemId: this.data.item.ItemIdPK,
      })
      .subscribe({
        next: (res: MenuRecipeFetch) => {
          this.menuRecipes = res.data;
          this.patchValues();
        },
        error: (err) => {
          this._uiService.presentToast(err);
        },
      });
  }
  // patchValues() {
  //   const menuRecipeIds = new Set<number>(
  //     this.menuRecipes.map((item) => +item.RecipeIdFK)
  //   );
  //   this.recipes.forEach((recipe: Recipes) => {
  //     if (menuRecipeIds.has(recipe.RecipeIdPK)) {
  //       recipe.isChecked = true;
  //       recipe.MenuRecipeIdPK = this.menuRecipes[0].MenuRecipeIdPK;
  //     }
  //   });
  // }

  patchValues() {
    const menuRecipeIds = new Map<number, number>();
    this.menuRecipes.forEach((item) => {
      menuRecipeIds.set(+item.RecipeIdFK, item.MenuRecipeIdPK);
    });

    this.recipes.forEach((recipe: Recipes) => {
      if (menuRecipeIds.has(recipe.RecipeIdPK)) {
        recipe.isChecked = true;
        recipe.MenuRecipeIdPK = menuRecipeIds.get(recipe.RecipeIdPK);
      }
    });
  }
  onSelectRecipe(e, i: number) {
    this.allRecipes[i].isChecked = e.detail.checked;
    this.recipes[i].isChecked = e.detail.checked;
  }

  onAddRecipe() {
    let value = [];
    this.recipes.forEach((recipe) => {
      if (recipe.isChecked) {
        value.push({
          RecipeIdFK: recipe.RecipeIdPK,
          ItemIdFK: this.data.item.ItemIdPK,
        });
      }
    });
    this.recipeService.createMenuRecipes({ menu_recipes: value }).subscribe({
      next: (res) => {
        if (res) {
          this._uiService.presentToast(' Created Successfully!');
          this.fetchMenuRecipes();
        }
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  loadSearchFilter() {
    this.searchFormControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((search) => {
          if (!search && search === '') {
            this.recipes = this.allRecipes;
            return;
          }

          this.recipes = this.allRecipes.filter((item) => {
            return item.name.toLowerCase().includes(search.toLowerCase());
          });
        })
      )
      .subscribe();
  }

  onUpdateMenuRecipe() {
    let value = [];
    this.recipes.forEach((recipe) => {
      if (recipe.isChecked) {
        value.push({
          RecipeIdFK: recipe.RecipeIdPK,
          ItemIdFK: this.data.item.ItemIdPK,
          MenuRecipeIdPK: recipe.MenuRecipeIdPK,
        });
      }
    });

    this.recipeService.updateMenuRecipes({ menu_recipes: value }).subscribe({
      next: (res) => {
        if (res) {
          this._uiService.presentToast('Recipe Updated Successfully!');
          this.fetchMenuRecipes();
        }
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }
}
