import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { MenuItem } from '../shared/models/menu.model';
import { FormErrorService } from '../shared/services/form-error.service';
import { FormService } from '../shared/services/form.service';
import { StorageInterface } from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import { Category, MealTimeList, StoreService } from '../store/store.service';

@Component({
  selector: 'app-item-combo-create',
  templateUrl: './item-combo-create.page.html',
  styleUrls: ['./item-combo-create.page.scss'],
})
export class ItemComboCreatePage implements OnInit {
  @Input() data: {
    categories: Category[];
    mealTimes: MealTimeList[];
    storageData: StorageInterface;
    item?: MenuItem;
  };
  baseUrl = environment.base_url;
  itemImage: any;
  // data: any;
  public file: File = {} as File;
  shortLink: string = '';
  loading: boolean = false;
  activeSegment = 'item';
  @Input() mode: string;
  @Input() type: string;
  addItemFormGroup!: FormGroup;
  addItem$: Observable<any>;
  categories: Category[];
  mealTimes: MealTimeList[];
  storageData: StorageInterface;
  itemFetch$: Observable<any>;
  items: any[] = [];
  allItems: any[] = [];
  initialItems: any[] = [];
  itemComboFetch$: Observable<any>;

  constructor(
    private _modal: ModalController,
    private _formErrorService: FormErrorService,
    private _formBuilder: FormBuilder,
    private _formService: FormService,
    private _storeService: StoreService,
    private _uiService: UiService
  ) {}

  ngOnInit(): void {
    this.categories = this.data.categories;
    this.mealTimes = this.data.mealTimes;
    this.storageData = this.data.storageData;
    this.buildForm();
    if (this.type === 'COMBO' && this.mode === 'EDIT') {
      this.fetchComboItems();
    } else {
      this.fetchItems();
    }
  }

  buildForm() {
    this.addItemFormGroup = this._formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      CategoryIdFK: new FormControl(null, [Validators.required]),
      shortDesc: new FormControl(null),
      price: new FormControl(null, [Validators.required]),
      gstPercent: new FormControl(null),
      type: new FormControl(null, [Validators.required]),
      prepTime: new FormControl(),
      mealTimeIds: new FormControl(null),
      comboIds: new FormControl(null),
    });
    if (this.mode === 'EDIT') {
      this.addItemFormGroup.patchValue(this.data.item);
      this.addItemFormGroup.patchValue({
        gstPercent: this.data.item.gstPercent.toString(),
      });

      if (this.data.item.mealTimeIds) {
        let ids = [];
        this.data.item.mealTimeIds.split(',').forEach((meal) => {
          ids.push(+meal);
        });
        this.addItemFormGroup.patchValue({
          mealTimeIds: ids,
        });
      }
    }
  }

  fetchComboItems() {
    this.itemComboFetch$ = this._storeService.fetchItemByIds({
      itemIds: this.data.item.comboIds,
    });

    this.itemComboFetch$.subscribe((res) => {
      if (res) {
        this.initialItems = res;
        this.removeDuplication();
        this.addItemFormGroup.patchValue({
          comboIds: this.items.map((item) => {
            return item;
          }),
        });
      }
    });
  }

  fetchItems(search = '') {
    const itemSendData = {
      // currentPage: 1,
      // itemsPerPage: 10,
      searchData: search,
      CategoryIdPK: '',
      VendorIdPK: this.data.storageData.userDetail.VendorIdPK,
      skipParentVariant: true,
    };

    this.itemFetch$ = this._storeService.fetchItem(itemSendData);
    this.itemFetch$.subscribe(
      (responseData) => {
        this.items = responseData.data;
        this.allItems = JSON.parse(JSON.stringify(this.items));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getFileDetail(event: any) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.itemImage = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]); // to trigger onload
    }
    this.file = event.target.files[0] as File;
  }

  onAddNewItem() {
    this._formService.markFormAsTouched(this.addItemFormGroup);
    if (!this.addItemFormGroup.valid) {
      return;
    }
    let comboIds = '';

    const formData = new FormData();
    if (this.type === 'COMBO') {
      this.addItemFormGroup.value.comboIds.forEach((combo) => {
        if (comboIds === '') {
          comboIds = combo.ItemIdPK;
        } else {
          comboIds = comboIds + ',' + combo.ItemIdPK;
        }
      });
      formData.append('comboIds', comboIds);
    }

    formData.append('CategoryIdPK', this.addItemFormGroup.value.CategoryIdFK);
    formData.append(
      'VendorIdPK',
      this.storageData?.userDetail?.VendorIdPK.toString()
    );
    formData.append('name', this.addItemFormGroup.value.name);
    formData.append('price', this.addItemFormGroup.value.price);
    formData.append('itemStatus', 'ACTIVE');
    if (this.addItemFormGroup.value.shortDesc) {
      formData.append('shortDesc', this.addItemFormGroup.value.shortDesc);
    }
    formData.append('type', this.addItemFormGroup.value.type);
    if (this.addItemFormGroup.value.mealTimeIds) {
      formData.append('mealTimeIds', this.addItemFormGroup.value.mealTimeIds);
    }
    if (this.addItemFormGroup.value.prepTime) {
      formData.append('prepTime', this.addItemFormGroup.value.prepTime);
    }
    if (this.itemImage) {
      formData.append('image', this.file);
    }
    if (this.mode === 'EDIT') {
      formData.append('ItemIdPK', this.data.item.ItemIdPK.toString());
      this.onUpdateItem(formData);
      return;
    } else {
      // If comobo creating then we have to send sort Order
      formData.append('sortOrder', '0');
    }
    this.addItem$ = this._storeService.createItem(formData);
    this.addItem$.subscribe(
      async (res) => {
        await this._uiService.presentToast('Combo added sucessfully!');
        this.addItemFormGroup.patchValue(null);
        this._modal.dismiss(true);
      },
      (error) => {
        this._uiService.presentToast(error);
      }
    );
  }
  onUpdateItem(formData: FormData) {
    this.addItem$ = this._storeService.updateItem(formData);

    this.addItem$.subscribe((res) => {
      if (res) {
        this._uiService.presentToast('Item Updated Successfully!');
        this._modal.dismiss(true);
      }
    });
  }

  onImageLoadError(e: any) {
    e.target.src = '../../assets/images/icons/menu.png';
  }

  onDeleteItem() {
    this.addItem$ = this._storeService.updateItemStatus({
      VendorIdPK: this.storageData?.userDetail?.VendorIdPK,
      ItemIdPK: this.data.item.ItemIdPK,
      status: 'DELETED',
    });

    this.addItem$.subscribe({
      next: (res) => {
        this._uiService.presentToast('Item deleted successfully!');
        this._modal.dismiss(true);
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  dismiss() {
    this._modal.dismiss();
  }

  onItemSearch(e) {
    this.items = this.allItems.filter((item) => {
      if (item && e) {
        return item.name.toLowerCase().includes(e.toLowerCase());
      }
    });
  }

  removeDuplication() {
    this.initialItems.forEach((iItem) => {
      let found = false;
      this.items.forEach((item) => {
        if (iItem.ItemIdPK === item.ItemIdPK) {
          found = true;
        }
      });
      if (!found) {
        this.items.push(iItem);
      }
    });
    this.items = this.items;
  }

  segmentChanged(e) {
    this.activeSegment = e.target.value;
  }

  get getComboTotalPrice(): number {
    let total = 0;
    if (!this.addItemFormGroup.value.comboIds) {
      return total;
    }
    if (typeof this.addItemFormGroup.value.comboIds === 'object')
      this.addItemFormGroup.value.comboIds.forEach((combo) => {
        total += combo.price;
      });

    return total;
  }
  public getErrorMessage(fieldName: string, error: string): string {
    return this._formErrorService.getErrorMessage(fieldName, error);
  }
}
