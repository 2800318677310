import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { MenuItem } from '../shared/models/menu.model';
import { StorageInterface } from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import {
  Category,
  MealTimeList,
  StoreService,
  Tag,
} from '../store/store.service';

@Component({
  selector: 'app-item-tags',
  templateUrl: './item-tags.component.html',
  styleUrls: ['./item-tags.component.scss'],
})
export class ItemTagsComponent implements OnInit {
  @Input() data: {
    categories: Category[];
    mealTimes: MealTimeList[];
    storageData: StorageInterface;
    item?: MenuItem;
  };
  tag$: Observable<any>;
  tags: Tag[] = [];
  allTags: Tag[] = [];
  tagFormControl: FormControl = new FormControl(null, Validators.required);
  searchFormControl: FormControl = new FormControl();
  checkedTagCounts = 0;

  constructor(
    private _storeService: StoreService,
    private _uiService: UiService
  ) {}

  ngOnInit() {
    this.fetchTags();
  }

  loadSearchFilter() {
    this.searchFormControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((search) => {
          if (search) {
            this.tags = this.allTags.filter((tag) => {
              return tag.name.toLowerCase().includes(search.toLowerCase());
            });
            if (this.tags.length === 0) {
              this.tagFormControl.patchValue(search);
            } else {
              this.tagFormControl.patchValue(null);
            }
          }
          if (search === '' || !search) {
            this.tags = this.allTags;
          }
        })
      )
      .subscribe();
  }

  fetchTags() {
    this.tag$ = this._storeService.fetchTags({
      vendorId: this.data.storageData.userDetail.VendorIdPK,
    });
    this.tag$.subscribe(
      (responseData) => {
        this.tags = responseData.data;
        this.loadSearchFilter();
        this.patchTags();
        this.allTags = JSON.parse(JSON.stringify(this.tags));
      },
      (error) => {
        this._uiService.presentToast(error);
      }
    );
  }

  patchTags() {
    this.data.item.tags.forEach((tag) => {
      if (tag.ItemTagsIdPK) {
        this.tags.forEach((nTag) => {
          if (tag.ItemTagsIdPK === nTag.ItemTagsIdPK) {
            nTag.isChecked = true;
            this.checkedTagCounts += 1;
          }
        });
      }
    });
  }

  onCreateTag() {
    if (!this.tagFormControl.valid) {
      this._uiService.presentToast("Can't create a empty tag!");
      return;
    }
    this.tag$ = this._storeService.createTag({
      name: this.tagFormControl.value,
    });

    this.tag$.subscribe({
      next: (res) => {
        if (res) {
          this._uiService.presentToast('Tag Crerated Successfully!');
          this.fetchTags();
          this.tagFormControl.patchValue(null);
          this.searchFormControl.patchValue(null);
        }
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  onChangeSelection(e, i: number) {
    this.allTags[i].isChecked = e.detail.checked;
    this.tags[i].isChecked = e.detail.checked;
    if (e.detail.checked) {
      this.checkedTagCounts += 1;
    } else {
      this.checkedTagCounts -= 1;
    }
  }

  onUpdateTags() {
    if (this.checkedTagCounts > 2) {
      this._uiService.presentToast('A product can have only max 2 tags');
      return;
    }

    let tagIds = [];

    this.tags.forEach((tag) => {
      if (tag.isChecked) {
        tagIds.push(tag.ItemTagsIdPK);
      }
    });
    this.tag$ = this._storeService.updateTagOfItem({
      ItemIdPK: this.data.item.ItemIdPK,
      tagIds: tagIds.join(),
    });

    this.tag$.subscribe({
      next: (res) => {
        if (res) {
          this._uiService.presentToast('Tag Updated Successfully!');
          this._storeService.itemChanged.next(true);
        }
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }
}
