import { Injectable } from '@angular/core';
import { ErrorHandler } from './errorhandler.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PromotionalMessageCreateApiResponse, PromotionalMessageFetchApiResponse } from '../models/promotional-message.model';
import { PROMOTIONAL_MESSAGE_API } from '../constants/apis.constant';

@Injectable({
  providedIn: 'root'
})
export class PromotionalMessageService {

  constructor(private http: HttpClient, private errorHandler: ErrorHandler) {}

  fetchMessage(data?: any): Observable<PromotionalMessageFetchApiResponse> {
    const params = new HttpParams({ fromObject: data });
    return this.http
      .get<PromotionalMessageFetchApiResponse>(`${environment.base_url}${PROMOTIONAL_MESSAGE_API}`, {
        params,
      })
      .pipe(catchError(this.errorHandler.handleError));
  }

  createMessage(userData: any): Observable<PromotionalMessageCreateApiResponse> {
    return this.http
      .post<PromotionalMessageCreateApiResponse>(
        `${environment.base_url}${PROMOTIONAL_MESSAGE_API}`,
        userData
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

}
