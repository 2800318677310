import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import {
  CREATE_DELIVERY_PERSON,
  FETCH_DELIVERY_PERSON,
  UPDATE_DELIVERY_PERSON,
} from '../constants/apis.constant';
import { ErrorHandler } from './errorhandler.service';
import {
  DeliveryPersonCreateRequest,
  DeliveryPersonFetch,
  DeliveryPersonInterface,
  DeliveryPersonUpdate,
  DeliveryPersonUpdateRequest,
} from '../models/delivery-person.model';

@Injectable({
  providedIn: 'root',
})
export class DeliveryPersonService {
  constructor(private _http: HttpClient, private _errorHandler: ErrorHandler) {}
  fetchDeliveryPerson(params: {
    VendorIdPK: number;
    type: string;
    search?: string;
  }): Observable<any> {
    return this._http
      .get(environment.base_url + FETCH_DELIVERY_PERSON, { params })
      .pipe(
        catchError(this._errorHandler.handleError),
        map((res: DeliveryPersonFetch) => {
          return res;
        })
      );
  }
  createDeliveryPerson(userData: DeliveryPersonCreateRequest): Observable<any> {
    return this._http
      .post(environment.base_url + CREATE_DELIVERY_PERSON, userData)
      .pipe(catchError(this._errorHandler.handleError));
  }
  updateDeliveryPerson(userData: DeliveryPersonUpdateRequest): Observable<any> {
    return this._http
      .put(environment.base_url + UPDATE_DELIVERY_PERSON, userData)
      .pipe(
        catchError(this._errorHandler.handleError),
        map((res: DeliveryPersonUpdate) => {
          return res;
        })
      );
  }
}
