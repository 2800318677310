import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { CouponCreateComponent } from '../coupon-create/coupon-create.component';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import { StoreService } from '../store/store.service';
import { BannerInterface } from '../shared/models/banner.model';
import { BannerService } from '../shared/services/banner.service';
import { BannerCreateComponent } from '../banner-create/banner-create.component';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.page.html',
  styleUrls: ['./banners.page.scss'],
})
export class BannersPage {
  banner: BannerInterface[] = [];
  storageData: StorageInterface;
  constructor(
    private modalCtrl: ModalController,
    private _uiService: UiService,
    private _bannerService: BannerService,
    private _storageService: StorageService
  ) {}

  ionViewWillEnter() {
    this.setStorageData();
  }
  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res: any) => {
      this.storageData = res;
      this.fetchBanner();
    });
  }

  async fetchBanner() {
    this._bannerService
      .fetchBanner(this.storageData?.userDetail?.VendorIdPK)
      .subscribe({
        next: (res) => {
          this.banner = res.data;
        },
        error: (err) => {
          this._uiService.presentToast(err);
        },
      });
  }

  async openBannerModal(mode: any, banner?: BannerInterface) {
    const modal = await this.modalCtrl.create({
      component: BannerCreateComponent,
      animated: true,
      cssClass: 'Banner-modal',
      componentProps: {
        storageData: this.storageData,
        ...(banner && { banner: banner }),
        mode: mode,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchBanner();
      }
    });
    modal.present();
  }
}
