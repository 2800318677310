import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import { StoreService, UpSellList } from '../store/store.service';
import { UpsellOfferCreateComponent } from '../upsell-offer-create/upsell-offer-create.component';

@Component({
  selector: 'app-upsell-offers',
  templateUrl: './upsell-offers.page.html',
  styleUrls: ['./upsell-offers.page.scss'],
})
export class UpsellOffersPage implements OnInit {
  upSell$: Observable<any>;
  upsells: UpSellList[] = [];
  storageData: StorageInterface;
  constructor(
    private modalCtrl: ModalController,
    private _storeService: StoreService,
    private _storageService: StorageService,
    private _uiSevice: UiService
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      this.fetchUpSells();
    });
  }

  fetchUpSells() {
    this.upSell$ = this._storeService.fetchUpsells({
      VendorIdFK: this.storageData?.userDetail?.VendorIdPK,
    });

    this.upSell$.subscribe({
      next: (res) => {
        if (res) {
          this.upsells = res;
        }
      },
      error: (err) => {
        this._uiSevice.presentToast(err);
      },
    });
  }

  async openUpsellModal(mode: string, upSell?: UpSellList) {
    const modal = await this.modalCtrl.create({
      component: UpsellOfferCreateComponent,
      animated: true,
      cssClass: 'coupons-modal',
      componentProps: {
        mode: mode,
        data: {
          storageData: this.storageData,
          upSell: upSell,
        },
      },
    });

    modal.onDidDismiss().then((res) => {
      if (res.data) {
        this.fetchUpSells();
      }
    });
    modal.present();
  }
}
