import { Component, Input, OnInit } from '@angular/core';
import { ORDER_STATUS_ENUMS } from 'src/app/shared/constants/app.constants';
import { Staffs } from 'src/app/shared/models/staff.model';
import { OrderInterface } from 'src/app/shared/services/order.service';

@Component({
  selector: 'app-waiting-orders',
  templateUrl: './waiting-orders.page.html',
  styleUrls: ['./waiting-orders.page.scss'],
})
export class WaitingOrdersPage implements OnInit {
  @Input() orders: OrderInterface[] = [];
  @Input() kitchenUsers: Staffs[] = [];

  orderStatusEnum = ORDER_STATUS_ENUMS;
  constructor() {}

  ngOnInit() {}
}
