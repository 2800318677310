import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { STORAGE_CONSTANT } from 'src/app/shared/constants/app.constants';
import { OrderService } from 'src/app/shared/services/order.service';
import {
  StorageInterface,
  StorageService,
} from 'src/app/shared/services/storage.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { formatDate } from '@angular/common';
import { IonDatetime } from '@ionic/angular';
@Component({
  selector: 'app-report-sales',
  templateUrl: './report-sales.page.html',
  styleUrls: ['./report-sales.page.scss'],
})
export class ReportSalesPage implements OnInit {
  // Linechart: any = [];
  // Piechart: any = [];
  currentPage = 1;
  currentLimit = 10;
  startDateFC = new FormControl(null);
  endDateFC = new FormControl(null);
  totalSize: number;
  filterStartDate: any;
  filterEndDate: any;
  salesReportData: any = [];
  storageData: StorageInterface;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChild('startDatePicker') startDatePicker: IonDatetime;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChild('endDatePicker') endDatePicker: IonDatetime;
  constructor(
    private _orderService: OrderService,
    private _storageService: StorageService,
    private _uiService: UiService,
    private excelService: ExcelService
  ) {
    const date = new Date().toISOString().split('T')[0];
    const today = new Date();
    const pastWeekStartDate = new Date(today);
    pastWeekStartDate.setDate(today.getDate() - 7);
    const startDate = formatDate(pastWeekStartDate, 'yyyy-MM-dd', 'en-US');
    this.startDateFC.setValue(startDate);
    this.endDateFC.setValue(date);
  }

  ngOnInit() {}

  ionViewWillEnter() {
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res: any) => {
      this.storageData = res;
      this.fetchSalesReportData();
    });
  }

  // TODO::: Optimize this code
  async fetchSalesReportData() {
    if (this.endDateFC.value < this.startDateFC.value) {
      this._uiService.presentToast(
        'The start date cannot be earlier than the end date.'
      );
      return;
    }

    this._orderService
      .fetchVendorSalesReport(this.storageData?.userDetail?.VendorIdPK, {
        page: this.currentPage,
        limt: this.currentLimit,
        ...(this.startDateFC.value && {
          orderPlacedStartDateTime: this.startDateFC.value,
        }),
        ...(this.endDateFC.value && {
          orderPlacedEndDateTime: this.endDateFC.value,
        }),
      })
      .subscribe((responseData: any) => {
        if (responseData.statusCode === 200) {
          this.salesReportData = responseData.data;
          this.totalSize = responseData.totalCounts;
        }
      });
  }
  exportAsXLSX(): void {
    if (this.salesReportData.length < 1) {
      this._uiService.presentToast('No data available to export.');
      return;
    }
    this.excelService.exportAsExcelFile(
      this.salesReportData,
      'export-to-excel'
    );
  }
  onResetFilter() {
    this.startDateFC.reset();
    this.endDateFC.reset();

    if (this.startDatePicker) {
      this.startDatePicker.value = null;
    }

    if (this.endDatePicker) {
      this.endDatePicker.value = null;
    }

    this.fetchSalesReportData();
  }
}
