import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrderInterface } from '../../services/order.service';
import { Share } from '@capacitor/share';
import { STORAGE_CONSTANT } from '../../constants/app.constants';
import {
  StorageInterface,
  StorageService,
} from '../../services/storage.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent implements OnInit {
  @Input() order: OrderInterface;
  storageData: StorageInterface;
  constructor(
    public _modalCntrl: ModalController,
    private _storageService: StorageService,
    private _uiService: UiService
  ) {}

  ngOnInit() {
    //console.log('invoice', this.order);
    this._storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
    });
  }

  async onShareInvoice() {
    try {
      await this._uiService.share({
        title: this.order.orderPlacedId,
        text:
          'Thank you for ordering with us. You can view the detailed invoice at: ' +
          'https://easeinn.app/invoice/' +
          this.order.OrderIdPK,
        url: 'https://easeinn.app/invoice/' + this.order.OrderIdPK,
        dialogTitle: 'Invoice',
        ...(this.order.customer.mobile && {
          customerNumber: this.order.customer.mobile,
        }),
      });
    } catch (e) {
      this._uiService.presentToast(e);
    }
  }
}
