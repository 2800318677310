import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { FormService } from 'src/app/shared/services/form.service';
import { StorageInterface } from 'src/app/shared/services/storage.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { StoreService } from 'src/app/store/store.service';
import { DiningAreaInterface } from '../dining-areas/dining-areas.page';
import { Seat } from '../tables.page';

@Component({
  selector: 'app-add-table',
  templateUrl: './add-table.component.html',
  styleUrls: ['./add-table.component.scss'],
})
export class AddTableComponent implements OnInit {
  @Input() storageData: StorageInterface;
  @Input() seat: Seat;
  addTableFormGroup: FormGroup;
  diningAreas: DiningAreaInterface[] = [];
  fetchDiningAreas$: Observable<any>;
  tableShapes = ['SQUARE', 'RECTANGLE', 'CIRCLE', 'ELLIPSE', 'TRIANGLE'];
  createTable$: Observable<any>;
  tableStatusOptions = ['ACTIVE', 'INACTIVE'];

  constructor(
    private _modal: ModalController,
    private _storeService: StoreService,
    private _formBuilder: FormBuilder,
    private _formService: FormService,
    private _formErrorService: FormErrorService,
    private _uiService: UiService
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  ionViewWillEnter() {
    this.fetchAllDiningAreas();
  }

  fetchAllDiningAreas() {
    this.fetchDiningAreas$ = this._storeService.getStoreDiningAreas(
      this.storageData?.userDetail?.VendorIdPK
    );

    this.fetchDiningAreas$.subscribe({
      next: (res) => {
        this.diningAreas = res;
        if (this.seat) {
          this.addTableFormGroup.patchValue(this.seat);
        }
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  buildForm() {
    this.addTableFormGroup = this._formBuilder.group({
      DiningAreaIdFK: new FormControl(null, [Validators.required]),
      tableNumber: new FormControl(null, [Validators.required]),
      tableShape: new FormControl(null, [Validators.required]),
      maxCapacity: new FormControl(null, [Validators.required]),
      tableDescription: new FormControl(null, [Validators.required]),
      tableStatus: new FormControl('ACTIVE', [Validators.required]),
    });
  }

  onAdd() {
    this._formService.markFormAsTouched(this.addTableFormGroup);

    if (!this.addTableFormGroup.valid) {
      return;
    }

    this.addTableFormGroup.value.VendorIdPK =
      this.storageData?.userDetail?.VendorIdPK;

    this.addTableFormGroup.value.status =
      this.addTableFormGroup.value.tableStatus;

    if (this.seat) {
      this.onUpdate();
      return;
    }
    this.createTable$ = this._storeService.createTableForStore(
      this.addTableFormGroup.value
    );

    this.createTable$.subscribe({
      next: (res) => {
        this._uiService.presentToast('Table Created Successfully!');
        this.addTableFormGroup.reset();
        this._modal.dismiss(true);
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  onUpdate() {
    this.createTable$ = this._storeService.updateTableForStore(
      this.addTableFormGroup.value
    );

    this.createTable$.subscribe({
      next: (res) => {
        this._uiService.presentToast('Table Updated Successfully!');
        this.addTableFormGroup.reset();
        this._modal.dismiss(true);
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  dismiss() {
    this._modal.dismiss();
  }

  public getErrorMessage(fieldName: string, error: string): string {
    return this._formErrorService.getErrorMessage(fieldName, error);
  }
}
