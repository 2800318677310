import {
  AfterContentChecked,
  Component,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  StorageInterface,
  StorageService,
} from '../../services/storage.service';
import { STORAGE_CONSTANT } from '../../constants/app.constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.page.html',
  styleUrls: ['./footer.page.scss'],
})
export class FooterPage implements OnInit {
  _router = inject(Router);
  _storageService = inject(StorageService);
  @Input() storageData!: StorageInterface;
  constructor() {}

  ngOnInit() {}

  ionViewDidEnter() {
    // this.setStorageData();
  }

  navigateToStore() {
    if (this.storageData.appPrefs) {
      if (this.storageData.appPrefs.defaultQuickOrder) {
        this._router.navigateByUrl('/store/quick-order');
        return;
      }
    }
    this._router.navigateByUrl('/store');
  }
}
