import { Component, OnInit, inject } from '@angular/core';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';
import { Observable } from 'rxjs';
import { StoreService } from '../store/store.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-manage-qrs',
  templateUrl: './manage-qrs.page.html',
  styleUrls: ['./manage-qrs.page.scss'],
})
export class ManageQrsPage implements OnInit {
  currentSelectedTab = 'STORE';
  storageData: StorageInterface;
  storeUrl = '';
  fetchTables$: Observable<any>;

  _storageService = inject(StorageService);
  _storeService = inject(StoreService);
  tables: any[] = [];
  tableUrl = '';
  currentQrName = 'Store-Qr';
  constructor() {}

  ngOnInit() {
    this.setStorageData();
  }

  segmentChanged(e) {
    this.currentSelectedTab = e.detail.value;
    console.log(this.currentSelectedTab);
  }

  fetchAllTables() {
    this.fetchTables$ = this._storeService.getAllTablesOfStore(
      this.storageData?.userDetail?.VendorIdPK
    );

    this.fetchTables$.subscribe((res) => {
      res.forEach((area) => {
        if (area.floorName !== 'Not Available') {
          area.seats.forEach((seat) => {
            this.tables.push(seat);
          });
        }
      });
    });
  }

  onSelectTable(e) {
    let table = this.tables.find(
      (table) => +table.TableIdPK === +e.detail.value
    );

    this.currentQrName =
      table.dining_area.name + ' Table-No-' + table.tableNumber;
    this.tableUrl =
      environment.menu_url +
      this.storageData.userDetail.vendorCode.slice(
        2,
        this.storageData.userDetail.vendorCode.length
      ) +
      'D' +
      table.DiningAreaIdFK +
      'T' +
      table.tableNumber;
  }

  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      this.storeUrl =
        environment.menu_url +
        this.storageData.userDetail.vendorCode.slice(
          2,
          this.storageData.userDetail.vendorCode.length
        ) +
        'D0T0';
      this.fetchAllTables();
    });
  }
}
