import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { ErrorHandler } from './errorhandler.service';
import { DatePipe } from '@angular/common';
import { InventoryTransactionApiResponse } from '../models/inventory-transaction.model';

@Injectable({ providedIn: 'root' })
export class InventoryService {
  httpClient = inject(HttpClient);
  _errorHandler = inject(ErrorHandler);
  datePipe = inject(DatePipe);

  createInventoryCategory(body: any): Observable<any> {
    return this.httpClient
      .post(
        environment.base_url + environment.create_inventory_category_method,
        body
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  fetchInventoryCategories(params: any): Observable<InventoryCategoryResponse> {
    return this.httpClient
      .get<InventoryCategoryResponse>(
        environment.base_url + environment.fetch_inventory_category_method,
        { params }
      )
      .pipe(catchError(this._errorHandler.handleError));
  }
  fetchInventoryTransactions(
    params: any
  ): Observable<InventoryTransactionApiResponse> {
    return this.httpClient
      .get<InventoryTransactionApiResponse>(
        environment.base_url + environment.fetch_inventory_transactions_method,
        { params }
      )
      .pipe(catchError(this._errorHandler.handleError));
  }
  // fetchInventoryCategoriesWithItemLength(
  //   vendorId: number,
  //   params: any
  // ): Observable<InventoryCategoryResponse> {
  //   return this.httpClient
  //     .get<InventoryCategoryResponse>(
  //       environment.base_url +
  //         environment.fetch_inventory_category_with_item_length_method.replace(
  //           '{id}',
  //           vendorId.toString()
  //         ),
  //       { params }
  //     )
  //     .pipe(catchError(this._errorHandler.handleError));
  // }

  // ********Changes because of category deletion not working****************

  fetchInventoryCategoriesWithItemLength(
    vendorId: number,
    params: any
  ): Observable<InventoryCategoryResponse> {
    return this.httpClient
      .get<InventoryCategoryResponse>(
        environment.base_url +
          environment.fetch_inventory_category_method.replace(
            '{id}',
            vendorId.toString()
          ),
        { params }
      )
      .pipe(catchError(this._errorHandler.handleError));
  }
  fetchInventoryStats(
    vendorId: number,
    body?
  ): Observable<InventoryStatsResponse> {
    return this.httpClient
      .get<InventoryStatsResponse>(
        environment.base_url +
          environment.fetch_inventory_report_method.replace(
            '{id}',
            vendorId.toString()
          ),
        { params: body }
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  deleteInventoryCategories(catgeoryId: number): Observable<any> {
    return this.httpClient
      .delete<any>(
        environment.base_url +
          environment.delete_inventory_category_method.replace(
            '{id}',
            catgeoryId.toString()
          )
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  updateInventoryCategory(catgeoryId: number, body: any): Observable<any> {
    return this.httpClient
      .patch(
        environment.base_url +
          environment.udpate_inventory_category_method.replace(
            '{id}',
            catgeoryId.toString()
          ),
        body
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  fetchInventoryUnits(): Observable<InventoryUnitFetchResponse> {
    return this.httpClient
      .get<InventoryUnitFetchResponse>(
        environment.base_url + environment.fetch_inventory_unit_method
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  createInventoryMasterItem(body: any): Observable<any> {
    return this.httpClient
      .post(
        environment.base_url + environment.create_inventory_item_master_method,
        body
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  fetchInventoryMasterItem(
    params?: any
  ): Observable<InventoryItemMasterResponse> {
    return this.httpClient
      .get<InventoryItemMasterResponse>(
        environment.base_url + environment.fetch_inventory_item_master_method,
        { params }
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  fetchInventoryItems(params?: any): Observable<InventoryItemResponse> {
    return this.httpClient
      .get<InventoryItemResponse>(
        environment.base_url + environment.fetch_inventory_item_method,
        { params }
      )
      .pipe(
        map((_) => {
          _.data.forEach((__) => {
            __.itemSelectionLabel = `${__.name} | Expiry:  ${
              __.expiryDate !== 'Invalid date'
                ? this.datePipe.transform(__.expiryDate, 'mediumDate')
                : 'Not Available'
            }`;
          });
          return _;
        }),
        catchError(this._errorHandler.handleError)
      );
  }
  fetchInventoryList(
    vendorId: number,
    params?: any
  ): Observable<InventoryListResponse> {
    return this.httpClient
      .get<InventoryListResponse>(
        environment.base_url +
          environment.fetch_inventory_list_method.replace(
            '{id}',
            vendorId.toString()
          ),
        { params }
      )
      .pipe(
        map((response: InventoryListResponse) => {
          response.data.forEach((item) => {
            if (
              item.inventoryItems.length === 0 &&
              (params.near_expiry || params.low_stock)
            ) {
              item.inventoryItemMaster.isDisplay = false;
            } else {
              item.inventoryItemMaster.isDisplay = true;
            }

            item.inventoryItems.forEach((inventoryItem) => {
              if (
                !inventoryItem.expiryDate ||
                inventoryItem.expiryDate === 'Invalid date'
              ) {
                inventoryItem.expiredIn = 'No expiry date provided';
                return;
              }

              const givenDate = new Date(inventoryItem.expiryDate);
              const today = new Date();
              today.setHours(0, 0, 0, 0); // Set time to midnight

              if (
                givenDate.getFullYear() === today.getFullYear() &&
                givenDate.getMonth() === today.getMonth() &&
                givenDate.getDate() === today.getDate()
              ) {
                inventoryItem.expiredIn = 'The product will expire today';
              } else if (givenDate > today) {
                const differenceMs = Math.abs(
                  givenDate.getTime() - today.getTime()
                );
                const daysUntilExpiry = Math.floor(
                  differenceMs / (1000 * 60 * 60 * 24)
                );
                inventoryItem.expiredIn = `Will expire in ${daysUntilExpiry} days`;
              } else {
                inventoryItem.expiredIn = `Product expired on ${
                  inventoryItem.expiryDate.split(' ')[0]
                }`;
              }
            });
          });
          return response;
        }),
        catchError(this._errorHandler.handleError)
      );
  }

  // fetchInventoryList(
  //   vendorId: number,
  //   params?: any
  // ): Observable<InventoryListResponse> {
  //   return this.httpClient
  //     .get<InventoryListResponse>(
  //       environment.base_url +
  //         environment.fetch_inventory_list_method.replace(
  //           '{id}',
  //           vendorId.toString()
  //         ),
  //       { params }
  //     )
  //     .pipe(
  //       map((_) => {
  //         _.data.forEach((__) => {
  //           if (
  //             __.inventoryItems.length === 0 &&
  //             (params.near_expiry || params.low_stock)
  //           ) {
  //             __.inventoryItemMaster.isDisplay = false;
  //           } else {
  //             __.inventoryItemMaster.isDisplay = true;
  //           }
  //           __.inventoryItems.forEach((___) => {
  //             if (!___.expiryDate || ___.expiryDate === 'Invalid date') {
  //               ___.expiredIn = 'No expiry date provided';
  //               return;
  //             }
  //             const givenDate = new Date(___.expiryDate);

  //             // Get today's date
  //             const today = new Date();
  //             if (givenDate > today) {
  //               const differenceMs = Math.abs(
  //                 (today as any) - (givenDate as any)
  //               );
  //               ___.expiredIn =
  //                 'Will expire in ' +
  //                 Math.floor(differenceMs / (1000 * 60 * 60 * 24)) +
  //                 ' days';
  //             } else {
  //               ___.expiredIn =
  //                 'Product expired on ' + ___.expiryDate.split(' ')[0];
  //             }
  //           });
  //         });
  //         return _;
  //       }),
  //       catchError(this._errorHandler.handleError)
  //     );
  // }

  deleteInventoryMasterItem(itemId: number): Observable<any> {
    return this.httpClient
      .delete<any>(
        environment.base_url +
          environment.delete_inventory_item_master_method.replace(
            '{id}',
            itemId.toString()
          )
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  updateInventoryMasterItem(itemId: number, body: any): Observable<any> {
    return this.httpClient
      .patch(
        environment.base_url +
          environment.update_inventory_item_master_method.replace(
            '{id}',
            itemId.toString()
          ),
        body
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  createInventoryItem(body: any): Observable<any> {
    return this.httpClient
      .post(
        environment.base_url + environment.create_inventory_item_method,
        body
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  updateInventoryItem(itemId: number, body: any): Observable<any> {
    return this.httpClient
      .patch(
        environment.base_url +
          environment.update_inventory_item_method.replace(
            '{id}',
            itemId.toString()
          ),
        body
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  deleteInventoryItem(itemId: number, body: any): Observable<any> {
    return this.httpClient
      .delete<any>(
        environment.base_url +
          environment.delete_inventory_item_method.replace(
            '{id}',
            itemId.toString()
          ),
        { body }
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  manualStockOut(inventoryItemId: number, body: any): Observable<any> {
    return this.httpClient
      .patch(
        environment.base_url +
          environment.manual_stock_out_item_method.replace(
            '{id}',
            inventoryItemId.toString()
          ),
        body
      )
      .pipe(catchError(this._errorHandler.handleError));
  }
}

export interface InventoryItemMasterResponse {
  message: string;
  statusCode: number;
  totalSize: number;
  data: InventoryItemMaster[];
}

export interface InventoryItemMaster {
  InventoryItemMasterIdPK: number;
  InventoryCategoryMasterIdFK: number;
  InventoryUnitIdFK: number;
  name: string;
  icon: string;
  deleted: boolean;
  type: string;
  minStockQty: number;
  VendorIdFK: number;
  createdAt: string;
  updatedAt: string;
  inventory_item_unit: Inventoryitemunit;
  inventory_category_master: Inventorycategorymaster;
  vendor: Vendor;
  isDisplay?: boolean; // will use this in inventory list to display this master or not in low stock & near expiry
}

export interface Vendor {
  VendorIdPK: number;
  UserIdFK: number;
  CityIdFK: number;
  vendorCode: string;
  vendorQrCode?: any;
  vendorName: string;
  vendorStoreName: string;
  vendorStoreType: string;
  storeTiming: string;
  storeIcon?: any;
  vendorAppType: string;
  serviceType?: any;
  vendorLocation: string;
  vendorLat: string;
  vendorLang: string;
  status: string;
  expirationDate: string;
  smsLimit: number;
  subscriptionType: string;
  creationDate: string;
  mobile: string;
  alternateMobile: string;
  email: string;
  token: string;
  preferences: string;
  printWidth: number;
  deliveryDistance: number;
  upi: string;
}

export interface Inventorycategorymaster {
  InventoryCategoryMasterIdPK: number;
  VendorIdFK: number;
  name: string;
  status: string;
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface Inventoryitemunit {
  InventoryItemUnitIdPK: number;
  name: string;
  unit: string;
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface InventoryUnitFetchResponse {
  message: string;
  statusCode: number;
  totalSize: number;
  data: InvenyoryUnit[];
}

export interface InvenyoryUnit {
  InventoryItemUnitIdPK: number;
  name: string;
  unit: string;
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface InventoryCategoryResponse {
  message: string;
  statusCode: number;
  totalSize: number;
  data: InventoryCategories[];
}
export interface InventoryTransactionFetch {
  message: string;
  statusCode: number;
  totalSize: number;
  data: InventoryTransactionInterface[];
}
export interface InventoryTransactionInterface {
  createdAt: string;
  updatedAt: string;
}
export interface InventoryCategories {
  InventoryCategoryMasterIdPK: number;
  name: string;
  status: string;
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
  itemsCount?: number; // this key will only come in category/stats API
}

export interface InventoryListResponse {
  message: string;
  statusCode: number;
  totalSize?: any;
  data: InventoryList[];
}

export interface InventoryList {
  inventoryItemMaster: InventoryItemMaster;
  inventoryItems: InventoryItem[];
}

export interface InventoryItemResponse {
  message: string;
  statusCode: number;
  totalSize: number;
  data: InventoryItem[];
}

export interface InventoryItem {
  InventoryItemIdPK: number;
  InventoryItemMasterIdFK: number;
  InventoryCategoryMasterIdFK: number;
  MerchantIdFK?: any;
  VendorIdFK: number;
  quantity: number;
  price: number;
  discountType?: any;
  name: string;
  shortDesc: string;
  discountValue?: any;
  mrp: number;
  expiryDate: string;
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
  expiredIn?: string; // this is a manual appended key
  itemSelectionLabel?: string; // this is a manual appended key
}
export interface InventoryStatsResponse {
  message: string;
  statusCode: number;
  totalSize: number;
  data: InventoryReportInterface;
}
export interface InventoryReportInterface {
  lowStockItemCount: number;
  nearExpiryItemCount: number;
  totalInventoryValue: number;
  totalItemCount: number;
  totalNearExpiryValue: number;
}
