import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import {
  ORDER_STATUS_ENUMS,
  STORAGE_CONSTANT,
} from '../../constants/app.constants';
import { FormErrorService } from '../../services/form-error.service';
import { FormService } from '../../services/form.service';
import { OrderInterface, OrderService } from '../../services/order.service';
import { UiService } from '../../services/ui.service';
import {
  StorageInterface,
  StorageService,
} from '../../services/storage.service';
import { Staffs } from '../../models/staff.model';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnInit, OnChanges {
  @Input() order: OrderInterface = {} as OrderInterface;
  @Input() isOrderCreated = false; // is we creating order
  @Input() createdOrderAmt = 0;
  @Input() isStandalone = true;
  @Input() onProceed$: Subject<boolean> = new Subject();
  @Input() kitchenUsers: Staffs[] = [];
  selectedKitchenFC = new FormControl(null);
  assignToKitchenFC = new FormControl(false);
  isTakeawayFC = new FormControl(false);
  tokenNumberFC = new FormControl();

  orderStatus = ORDER_STATUS_ENUMS;
  placeOrder$!: Observable<any>;

  paymentFormGroup!: FormGroup;
  @Output() paymentDataEmitter$ = new EventEmitter();
  storageData: StorageInterface;

  constructor(
    private _formService: FormService,
    private _formBuilder: FormBuilder,
    private _formErrorService: FormErrorService,
    public _modalCtrl: ModalController,
    private _orderService: OrderService,
    private _uiService: UiService,
    private _storageService: StorageService
  ) {}

  ngOnInit() {
    this._storageService.getObject(STORAGE_CONSTANT).then(async (res) => {
      this.storageData = await res;
    });

    this.buildForm();
    this.onProceed$.subscribe((res) => {
      if (res) {
        this.onProceed();
      }
    });

    if (this.kitchenUsers.length) {
      this.assignToKitchenFC.patchValue(true);
      this.selectedKitchenFC.patchValue(this.kitchenUsers[0].StaffIdPK);
    }
  }

  // Need to fix this it will only work when something in parent changes
  ngOnChanges(): void {
    if (this.kitchenUsers.length) {
      this.assignToKitchenFC.patchValue(true);
      this.selectedKitchenFC.patchValue(this.kitchenUsers[0].StaffIdPK);
    }
  }

  buildForm() {
    this.paymentFormGroup = this._formBuilder.group({
      paymentMethod: new FormControl('PREPAID', [Validators.required]),
      paymentMode: new FormControl('CASH'),
      discount: new FormControl(null),
      markAsComplete: new FormControl(false),
    });
  }

  onChangePaymentMethod(e: any) {
    switch (e.detail.value) {
      case 'PREPAID':
        this.paymentFormGroup.controls.paymentMode.setValidators(
          Validators.required
        );
        this.paymentFormGroup.get('paymentMode').setValue('CASH');

        break;
      case 'POSTPAID':
        this.paymentFormGroup.controls.paymentMode.removeValidators(
          Validators.required
        );
        this.paymentFormGroup.get('paymentMode').setValue(null);

        break;
    }
  }

  onProceed() {
    this._formService.markFormAsTouched(this.paymentFormGroup);
    if (!this.paymentFormGroup.valid) {
      return;
    }

    // order create handler
    if (this.isOrderCreated) {
      // KOT handler
      if (this.assignToKitchenFC.value) {
        this.paymentFormGroup.value.KitchenIdFK = this.selectedKitchenFC.value;
      }
      //takeaway handle
      this.paymentFormGroup.value.isTakeaway = this.isTakeawayFC.value ? 1 : 0;
      if (this.tokenNumberFC.value)
        this.paymentFormGroup.value.tokenNumber = this.tokenNumberFC.value;
      if (!this.isStandalone) {
        this.paymentDataEmitter$.emit(this.paymentFormGroup.value);
      } else {
        this._modalCtrl.dismiss(this.paymentFormGroup.value);
      }
      return;
    } else {
      if (this.paymentFormGroup.value.paymentMethod === 'POSTPAID') {
        this.order.paymentMode = this.paymentFormGroup.value.paymentMethod;
      } else {
        this.order.paymentMode = this.paymentFormGroup.value.paymentMode;
      }
      this.order.discount = this.paymentFormGroup.value.discount;
    }

    //
    if (this.order.status === ORDER_STATUS_ENUMS.WAITING) {
      if (this.tokenNumberFC.value)
        this.order.tokenNumber = this.tokenNumberFC.value;
      // KOT handler
      if (this.assignToKitchenFC.value) {
        this.order.KitchenIdFK = this.selectedKitchenFC.value;
      }
      //takeaway handle
      this.order.isTakeaway = this.isTakeawayFC.value ? 1 : 0;
      this.placeOrder$ = this._orderService.updateWaitingOrder(this.order);
    } else {
      this.placeOrder$ = this._orderService.updateAcceptedOrder(this.order);
    }

    this.placeOrder$.subscribe((res) => {
      if (res) {
        if (
          this.order.status === ORDER_STATUS_ENUMS.WAITING ||
          this.order.status === ORDER_STATUS_ENUMS.PARTIALLY_ACCEPTED
        ) {
          this._uiService.presentToast('Order Accepted Successfully!');
        } else if (this.order.status === ORDER_STATUS_ENUMS.ACCEPTED) {
          this._uiService.presentToast('Order Completed Successfully');
        }

        this._modalCtrl.dismiss(true);
      }
    });
  }

  public getErrorMessage(fieldName: string, error: string): string {
    return this._formErrorService.getErrorMessage(fieldName, error);
  }
}
