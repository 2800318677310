import { Component, Input, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { MenuItem } from '../shared/models/menu.model';
import { FormErrorService } from '../shared/services/form-error.service';
import { FormService } from '../shared/services/form.service';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import {
  AddOnsList,
  Category,
  MealTimeList,
  StoreService,
} from '../store/store.service';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';
import {
  InventoryCategories,
  InventoryItemMaster,
  InventoryService,
  InvenyoryUnit,
} from '../shared/services/inventory.service';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-item-addon-create',
  templateUrl: './item-addon-create.component.html',
  styleUrls: ['./item-addon-create.component.scss'],
})
export class ItemAddonCreateComponent implements OnInit {
  @Input() mode;
  @Input() data: {
    categories: Category[];
    mealTimes: MealTimeList[];
    storageData: StorageInterface;
    item?: MenuItem;
  };
  @Input() addOn: AddOnsList;
  addOnFormGroup!: FormGroup;
  createAddOn$: Observable<any>;
  storageData: StorageInterface = {} as StorageInterface;
  category: InventoryCategories[] = [];
  _service = inject(InventoryService);
  units: InvenyoryUnit[] = [];
  inventoryItemMasters: InventoryItemMaster[] = [];
  inventory: InventoryItemMaster[] = [];
  searchInput$ = new Subject<any>();
  constructor(
    private _storeService: StoreService,
    public modalCtrl: ModalController,
    private _formService: FormService,
    private _formBuilder: FormBuilder,
    private _formErrorService: FormErrorService,
    private _storageService: StorageService,
    private _uiService: UiService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.getStorageData();
    this.loadSearchFilter();
  }
  getStorageData() {
    this._storageService
      .getObject(STORAGE_CONSTANT)
      .then((res: StorageInterface) => {
        if (res) {
          this.storageData = res;
          this.fetchInventoryMasterItems();
          this.fetchUnits();
        }
      });
  }
  buildForm() {
    this.addOnFormGroup = this._formBuilder.group({
      name: new FormControl(null, Validators.required),
      price: new FormControl(null, Validators.required),
      ItemIdFK: new FormControl(this.data.item.ItemIdPK, Validators.required),
      // ItemIdFK: new FormControl('', Validators.required),
      quantity: new FormControl('', Validators.required),
      InventoryItemMasterIdFK: new FormControl(),
      unit: new FormControl(),
      status: new FormControl('ACTIVE'),
    });
    if (this.addOn) {
      this.addOnFormGroup.patchValue(this.addOn);
    }
  }

  onChangeUnit(e: InventoryItemMaster) {
    if (e) {
      const unitValue = e.inventory_item_unit.unit;
      const control = this.addOnFormGroup.get('unit');
      if (control) {
        control.setValue(unitValue);
      }
    } else {
      this.addOnFormGroup.get('unit').reset();
      this.getUnitValue();
    }
  }

  fetchUnits() {
    this._service.fetchInventoryUnits().subscribe({
      next: (_) => {
        this.units = _.data;
      },
      error: (_) => {
        this._uiService.presentToast(_);
      },
    });
  }
  fetchInventoryMasterItems() {
    this._service
      .fetchInventoryMasterItem({
        vendorId: this.storageData?.userDetail?.VendorIdPK,
      })
      .subscribe({
        next: (_) => {
          this.inventoryItemMasters = _.data;
          if (this.addOn) {
            this.onPatchIngredient(_.data);
            this.inventory = this.inventoryItemMasters;
          }
        },
        error: (_) => {
          this._uiService.presentToast(_);
        },
      });
  }
  onPatchIngredient(inventory: InventoryItemMaster[]) {
    inventory.forEach((res) => {
      if (res.InventoryItemMasterIdPK === this.addOn.InventoryItemMasterIdFK) {
        this.addOnFormGroup.patchValue({
          InventoryItemMasterIdFK: res.InventoryItemMasterIdPK,
          unit: res.inventory_item_unit.unit,
        });
      }
    });
  }
  loadSearchFilter() {
    this.searchInput$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((res) => {
          this.onIngredientSearch(res);
        })
      )
      .subscribe();
  }
  onIngredientSearch(e) {
    this.inventory = this.inventoryItemMasters.filter((item) => {
      if (item && e) {
        return item.name.toLowerCase().includes(e.toLowerCase());
      }
    });
  }
  getUnitValue(): string {
    const control = this.addOnFormGroup.get('unit');
    return control ? control.value : '';
  }

  onCreateAddOn() {
    this._formService.markFormAsTouched(this.addOnFormGroup);
    // this.addOnFormGroup.value.ItemIdFK = this.data.item.ItemIdPK;
    this.addOnFormGroup.removeControl('unit');
    if (!this.addOnFormGroup.valid) {
      console.log(this.addOnFormGroup);
      this._uiService.presentToast('Form Not Valid!');
      return;
    }
    this.createAddOn$ = this._storeService.createAddOn(
      this.addOnFormGroup.value
    );

    this.createAddOn$.subscribe({
      next: (res) => {
        if (res) {
          this.addOnFormGroup.reset();
          this._uiService.presentToast('Add-On created successfully!');
          this.modalCtrl.dismiss(true);
        }
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  onUpdateAddOn() {
    this._formService.markFormAsTouched(this.addOnFormGroup);
    if (!this.addOnFormGroup.valid) {
      return;
    }
    this.addOnFormGroup.removeControl('unit');

    this.createAddOn$ = this._storeService.updateAddOn(
      this.addOn.AddOnIdPK,
      this.addOnFormGroup.value
    );

    this.createAddOn$.subscribe({
      next: (res) => {
        if (res) {
          this._uiService.presentToast('Add On Updated Successfully!');
          this.modalCtrl.dismiss(true);
        }
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  onDeleteAddOn() {
    this.createAddOn$ = this._storeService.deleteAddOn(this.addOn.AddOnIdPK);

    this.createAddOn$.subscribe({
      next: (res) => {
        if (res) {
          this.modalCtrl.dismiss(true);
          this._uiService.presentToast('Add On Deleted Successfully!');
        }
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  public getErrorMessage(fieldName: string, error: string): string {
    return this._formErrorService.getErrorMessage(fieldName, error);
  }
}
