import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { STORAGE_CONSTANT } from '../constants/app.constants';

@Injectable({ providedIn: 'root' })
export class StorageService {
  fcmToken: string;
  constructor(private _router: Router) {}

  async onLogout(storageData: StorageInterface) {
    let fmcToken = storageData.fcmToken;
    storageData = { fcmToken: fmcToken } as StorageInterface;
    await this.setObject(STORAGE_CONSTANT, storageData);
    await Preferences.clear();
    localStorage.clear();
    await this._router.navigate(['/auth']);
    location.reload();
  }

  async setObject(key: string, value: any) {
    await Preferences.set({ key, value: JSON.stringify(value) });
  }

  async getObject(key: string): Promise<any> {
    const ret = await Preferences.get({ key });
    return JSON.parse(ret.value);
  }

  setCartStorage(cart) {
    localStorage.setItem('cart', JSON.stringify(cart));
  }

  getCartFromStorage() {
    return JSON.parse(localStorage.getItem('cart'));
  }

  async clearAllStorage() {
    localStorage.clear();
    await Preferences.clear();
    this._router.navigate(['auth']);
  }
  async clearStorage() {
    localStorage.clear();
    await Preferences.clear();
  }
}

export interface StorageInterface {
  userType: string;
  token: string;
  userDetail?: UserDataInterface;
  customerName?: string;
  customerIdPK?: number;
  customerMobileNumber?: any;
  customerStatus?: any;
  tableIdPK?: any;
  tableNumber?: any;
  // eslint-disable-next-line @typescript-eslint/naming-convention

  fcmToken?: string;
  currency?: string;
  appPrefs?: {
    whatsappPref?: boolean;
    defaultQuickOrder?: boolean;
  };
  isDarkMode?: boolean;
}

export interface UserDataInterface {
  VendorIdPK?: number;
  UserIdFK?: number;
  CityIdFK?: number;
  VendorId?: number;
  vendorCode?: string;
  vendorQrCode?: any;
  vendorName?: string;
  vendorStoreName?: string;
  vendorStoreType?: string;
  storeTiming?: string;
  vendorAppType?: any;
  serviceType?: any;
  vendorLocation?: string;
  vendorLat?: string;
  vendorLang?: string;
  status?: string;
  expirationDate?: string;
  smsLimit?: number;
  subscriptionType?: string;
  creationDate?: string;
  mobile?: string;
  alternateMobile?: string;
  email?: string;
  token?: string;
  employeeName?: string;
  preferences?: string;
  printWidth?: number;
  deliveryDistance?: any;
  city?: City;
  vendorStoreUrl?: string; //pending
}

export interface State {
  StateIdPK: number;
  stateName: string;
}

export interface City {
  CityIdPK: number;
  StateIdFK: number;
  cityName: string;
  state: State;
}
