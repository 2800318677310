import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ORDER_STATUS_ENUMS,
  STORAGE_CONSTANT,
} from 'src/app/shared/constants/app.constants';
import {
  OrderInterface,
  OrderService,
} from 'src/app/shared/services/order.service';
import {
  StorageInterface,
  StorageService,
} from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-past-orders',
  templateUrl: './past-orders.page.html',
  styleUrls: ['./past-orders.page.scss'],
})
export class PastOrdersPage implements OnInit {
  currentSelectedTab = 'COMPLETED';
  fetchOrders$: Observable<any>;
  completedOrders: OrderInterface[] = [];
  rejectedOrders: OrderInterface[] = [];
  storageData: StorageInterface;
  currentPage = 1;
  currentRejectedPage = 1;
  reachedEnd: boolean = false;
  constructor(
    private _orderService: OrderService,
    private _storageService: StorageService
  ) {
    _orderService.orderReload$.subscribe((res) => {
      if (res) {
        this.currentPage = 1;
        this.fetchOrders(false, null);
      }
    });
  }

  ngOnInit() {}

  ionViewWillEnter() {
    this.getStorageData();
  }

  getStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res) => {
      if (res) {
        this.storageData = res;
        this.fetchOrders(false, null);
        this.fetchCancelledOrders(false, null);
      }
    });
  }

  
  fetchOrders(isFirstLoad: boolean, event: any) {
    this.fetchOrders$ = this._orderService.fetchOrder({
      VendorIdPK: this.storageData?.userDetail?.VendorIdPK,
      status: ORDER_STATUS_ENUMS.COMPLETED,
      currentPage: this.currentPage,
      itemsPerPage: 20,
    });

    this.fetchOrders$.subscribe((res) => {
      if (res) {
        this.completedOrders = [];
        this.completedOrders = [
          ...this.completedOrders,
          ...this._orderService.getCompletedOrders(),
        ];

        if (isFirstLoad) {
          event.target.complete();
        }
      }
    });
  }

  fetchCancelledOrders(isFirstLoad: boolean, event: any) {
    this.fetchOrders$ = this._orderService.fetchOrder({
      VendorIdPK: this.storageData?.userDetail?.VendorIdPK,
      status: ORDER_STATUS_ENUMS.REJECTED,
      currentPage: this.currentRejectedPage,
      itemsPerPage: 20,
    });

    this.fetchOrders$.subscribe((res) => {
      if (res) {
        this.rejectedOrders = [];
        this.rejectedOrders = [
          ...this.rejectedOrders,
          ...this._orderService.getRejectedOrders(),
        ];
        if (isFirstLoad) {
          event.target.complete();
        }
      }
    });
  }

  doInfinite(e) {
    if (this.currentSelectedTab === 'COMPLETED') {
      this.currentPage = this.currentPage + 1;
      this.fetchOrders(true, e);
    } else {
      this.currentRejectedPage = this.currentRejectedPage + 1;
      this.fetchCancelledOrders(true, e);
    }
  }

  segmentChanged(e) {
    this.currentSelectedTab = e.detail.value;
  }
}
