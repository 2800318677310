import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { FormService } from 'src/app/shared/services/form.service';
import { MerchantService } from 'src/app/shared/services/merchant.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-merchant-create',
  templateUrl: './merchant-create.page.html',
  styleUrls: ['./merchant-create.page.scss'],
})
export class MerchantCreatePage implements OnInit {
  @Input() details: any;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() VendorId: string;

  merchantsFormGroup!: FormGroup;
  constructor(
    public modalCtrl: ModalController,
    private merchantService: MerchantService,
    private formErrorService: FormErrorService,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private uiService: UiService,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    this.buildForm();
    if (this.details) {
      this.merchantsFormGroup.patchValue(this.details);
    }
    if(this.VendorId){
      this.merchantsFormGroup.patchValue({
        vendorId : this.VendorId
      })
    }
  }
  buildForm() {
    this.merchantsFormGroup = this.formBuilder.group({
      vendorId: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      gstin: new FormControl('', [Validators.required]),
    });
  }
  dismiss() {
    this.modalCtrl.dismiss();
  }

  onCreateMerchant() {
    this.formService.markFormAsTouched(this.merchantsFormGroup);
    if (!this.merchantsFormGroup.valid) {
      this.uiService.presentToast('Form Not Valid!');
      return;
    }
    this.merchantService
      .createMerchant(this.merchantsFormGroup.value)
      .subscribe({
        next: (res) => {
          this.uiService.presentToast('Created Successfully');

          this.modalCtrl.dismiss({ data: true });
        },
        error: (err) => {
          this.uiService.presentToast(err);
        },
      });
  }
  onUpdate() {
    this.formService.markFormAsTouched(this.merchantsFormGroup);
    if (!this.merchantsFormGroup.valid) {
      this.uiService.presentToast('Form Not Valid!');
      return;
    }
    this.merchantService
      .updateMerchant(this.merchantsFormGroup.value, this.details.MerchantMasterIdPK)
      .subscribe({
        next: (res) => {
          this.uiService.presentToast('Updated Successfully');
          this.modalCtrl.dismiss({ data: true });
        },
        error: (err) => {
          this.uiService.presentToast(err);
        },
      });
  }
  async onDelete() {
    const confirmationAlert = await this.alertController.create({
      header: 'Confirm Deletion',
      message: 'Are you sure you want to delete this merchant?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Deletion cancelled by the user.');
          }
        },
        {
          text: 'Delete',
          handler: () => {
            this.merchantService.deleteMerchant(this.details.MerchantMasterIdPK).subscribe({
              next: (res) => {
                this.uiService.presentToast('Deleted Successfully');
                this.modalCtrl.dismiss({ data: true });
              },
              error: (err) => {
                this.uiService.presentToast('Failed to delete merchant.');
                console.error(err);
              },
            });
          }
        }
      ]
    });
  
    await confirmationAlert.present();
  }
  public getErrorMessage(fieldName: string, error: string): string {
    return this.formErrorService.getErrorMessage(fieldName, error);
  }
}
