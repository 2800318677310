import { Component, OnInit } from '@angular/core';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { Router } from '@angular/router';
import { UiService } from '../shared/services/ui.service';
import { MenuItem } from '../shared/models/menu.model';

@Component({
  selector: 'app-more',
  templateUrl: './more.page.html',
  styleUrls: ['./more.page.scss'],
})
export class MorePage implements OnInit {
  adminMenuItems = [
    {
      label: 'Order History',
      icon: 'time-outline',
      path: '/order/past',
      id: 1,
      visibility: true,
    },
    {
      label: 'Menu Categories',
      icon: 'list-outline',
      path: '/categories',
      id: 2,
      visibility: true,
    },
    {
      label: 'Menu Items',
      icon: 'fast-food-outline',
      path: '/items',
      id: 3,
      visibility: true,
    },
    {
      label: 'Recipes',
      icon: 'document-outline',
      path: '/recipes/list',
      id: 18,
      visibility: true,
    },
    {
      label: 'Upsell Offers',
      icon: 'rocket-outline',
      path: '/upsell-offers',
      id: 4,
      visibility: true,
    },

    {
      label: 'Coupons',
      icon: 'pricetags-outline',
      path: '/coupons',
      id: 5,
      visibility: true,
    },
    {
      label: 'Meal Timings',
      icon: 'time-outline',
      path: '/meal-timings',
      id: 6,
      visibility: true,
    },
    {
      label: 'Purchase Orders',
      icon: 'document-text-outline',
      path: '/purchase-orders',
      id: 7,
      visibility: true,
    },
    {
      label: 'Suppliers',
      icon: 'document-text-outline',
      path: '/merchants/list',
      id: 8,
      visibility: true,
    },
    {
      label: 'Inventory',
      icon: 'document-text-outline',
      path: '/inventory/dashboard',
      id: 9,
      visibility: true,
    },
    {
      label: 'Inventory Trancation',
      icon: 'swap-horizontal-outline',
      path: '/inventory-transactions',
      id: 21,
      visibility: true,
    },

    {
      label: 'Reports & Analytics',
      icon: 'stats-chart-outline',
      path: '/report/dashboard',
      id: 18,
      visibility: true,
    },
    {
      label: 'My Account',
      icon: 'person-outline',
      path: '/account',
      id: 10,
      visibility: true,
    },
    {
      label: 'Customers',
      icon: 'people-outline',
      path: '/customers',
      id: 11,
      visibility: true,
    },
    {
      label: 'Tables & Floors',
      icon: 'grid-outline',
      path: '/dining-areas',
      id: 12,
      visibility: true,
    },
    {
      label: 'Manage QRs',
      icon: 'qr-code-outline',
      path: '/manage-qrs',
      id: 13,
      visibility: true,
    },
    {
      label: 'Banners',
      icon: 'images-outline',
      path: '/banners',
      id: 16,
      visibility: true,
    },
    {
      label: 'Promotional Messages',
      icon: 'chatbox-ellipses-outline',
      path: '/promotional-message',
      id: 18,
      visibility: true,
    },
    {
      label: 'Manage Staff',
      icon: 'ribbon-outline',
      path: '/staff-listing',
      id: 18,
      visibility: true,
    },
    {
      label: 'Delivery Person',
      icon: 'bicycle-outline',
      path: '/delivery-person-listing',
      id: 14,
      visibility: true,
    },
    {
      label: 'Manage KOT',
      icon: 'flame-outline',
      path: '/kot-user-listing',
      id: 15,
      visibility: true,
    },
    {
      label: 'Help',
      icon: 'mail-outline',
      path: '/support',
      id: 16,
      visibility: true,
    },
    {
      label: 'Settings',
      icon: 'settings-outline',
      path: '/settings',
      id: 17,
      visibility: true,
    },
  ];
  employeeMenuItems = [
    {
      label: 'Order History',
      icon: 'time-outline',
      path: '/order/past',
      id: 1,
      visibility: true,
    },
    {
      label: 'Menu Categories',
      icon: 'list-outline',
      path: '/categories',
      id: 2,
      visibility: true,
    },
    {
      label: 'Menu Items',
      icon: 'fast-food-outline',
      path: '/items',
      id: 3,
      visibility: true,
    },
    {
      label: 'Recipes',
      icon: 'document-outline',
      path: '/recipes/list',
      id: 18,
      visibility: true,
    },
    {
      label: 'Upsell Offers',
      icon: 'rocket-outline',
      path: '/upsell-offers',
      id: 4,
      visibility: true,
    },
    // {
    //   label: 'Coupons',
    //   icon: 'pricetags-outline',
    //   path: '/coupons',
    //   id: 5,
    //   visibility: true,
    // },
    // {
    //   label: 'Meal Timings',
    //   icon: 'time-outline',
    //   path: '/meal-timings',
    //   id: 6,
    //   visibility: true,
    // },
    // {
    //   label: 'Purchase Orders',
    //   icon: 'document-text-outline',
    //   path: '/purchase-orders',
    //   id: 7,
    //   visibility: true,
    // },
    // {
    //   label: 'Suppliers',
    //   icon: 'document-text-outline',
    //   path: '/merchants/list',
    //   id: 8,
    //   visibility: true,
    // },
    {
      label: 'Inventory',
      icon: 'document-text-outline',
      path: '/inventory/dashboard',
      id: 9,
      visibility: true,
    },
    {
      label: 'My Account',
      icon: 'person-outline',
      path: '/account',
      id: 10,
      visibility: true,
    },
    {
      label: 'Customers',
      icon: 'people-outline',
      path: '/customers',
      id: 11,
      visibility: true,
    },
    {
      label: 'Tables & Floors',
      icon: 'grid-outline',
      path: '/dining-areas',
      id: 12,
      visibility: true,
    },
    // {
    //   label: 'Manage QRs',
    //   icon: 'qr-code-outline',
    //   path: '/manage-qrs',
    //   id: 13,
    //   visibility: true,
    // },
    // {
    //   label: 'Delivery Person',
    //   icon: 'bicycle-outline',
    //   path: '/delivery-person-listing',
    //   id: 14,
    //   visibility: true,
    // },
    // {
    //   label: 'Manage KOT',
    //   icon: 'flame-outline',
    //   path: '/kot-user-listing',
    //   id: 15,
    //   visibility: true,
    // },
    {
      label: 'Help',
      icon: 'mail-outline',
      path: '/support',
      id: 16,
      visibility: true,
    },
    // {
    //   label: 'Settings',
    //   icon: 'settings-outline',
    //   path: '/settings',
    //   id: 17,
    //   visibility: true,
    // },
  ];
  menuItems: any[] = [];

  storageData: StorageInterface = {} as StorageInterface;

  constructor(
    private _storageService: StorageService,
    private _router: Router,
    private _uiService: UiService
  ) {}

  ngOnInit() {
    this.menuItems = null;
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      this.renderView();
    });
  }

  renderView() {
    if (this.storageData?.userDetail?.vendorStoreType === 'MULTITABLE') {
      let index = this.adminMenuItems.findIndex((menu) => menu.id === 4);
      this.adminMenuItems[index].visibility = true;
      this.menuItems = [this.adminMenuItems[index]];
    }

    if (this.storageData.userType === 'EMPLOYEE') {
      this.menuItems = this.employeeMenuItems;
    } else if (
      this.storageData.userType === 'VENDOR' ||
      this.storageData.userType === 'ADMIN'
    ) {
      this.menuItems = this.adminMenuItems;
    }
  }

  handleNavigation(path: string) {
    console.log(path, this.storageData?.userDetail?.deliveryDistance);

    if (
      path === '/delivery-person-listing' &&
      !this.storageData?.userDetail?.deliveryDistance
    ) {
      this._uiService.presentToast(
        'This feature is not enabled for your store. Contact EaseInn to enable'
      );
      return;
    }
    this._router.navigateByUrl(path);
  }
}
