import { Component, Input, OnInit, inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { PurchaseOrderInterface } from '../purchase-orders/purchase-orders.page';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';
import { FormErrorService } from '../shared/services/form-error.service';
import { FormService } from '../shared/services/form.service';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import { StoreService } from '../store/store.service';
import {
  Merchant,
  MerchantsFetchApiResponse,
} from '../shared/models/merchants.model';
import { MerchantService } from '../shared/services/merchant.service';
import { Ingredients } from '../shared/models/recipes.model';
import {
  InventoryItemMaster,
  InventoryService,
} from '../shared/services/inventory.service';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-purchase-order-create',
  templateUrl: './purchase-order-create.component.html',
  styleUrls: ['./purchase-order-create.component.scss'],
})
export class PurchaseOrderCreateComponent implements OnInit {
  mode;
  @Input() order: PurchaseOrderInterface;
  purchaseOrderFormGroup!: FormGroup;
  purchaseOrderUpdateFormGroup!: FormGroup;
  createPurchase$!: Observable<any>;
  MOBILE_REGEX = '([6-9]{10}$)|(^[6-9][0-9]{9}$)';
  searchInput$ = new Subject<any>();
  allMerchants: Merchant[] = [];
  merchants: Merchant[] = [];
  storageData: StorageInterface = {} as StorageInterface;
  inventoryItemMasters: InventoryItemMaster[] = [];
  inventories: InventoryItemMaster[] = [];
  _service = inject(InventoryService);

  constructor(
    private merchantService: MerchantService,
    public modalCtrl: ModalController,
    private _storeService: StoreService,
    private _uiService: UiService,
    private _formBuilder: FormBuilder,
    private _formErrorService: FormErrorService,
    private _formService: FormService,
    private _storageService: StorageService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.buildUpdateForm();
    this.loadSearchFilter();
    this.patchOrder();
  }
  ionViewWillEnter() {
    this.setStorageData();
  }
  setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      this.fetchInventoryMasterItems();
      this.onFetch();

      this.purchaseOrderFormGroup.patchValue({
        createdBy: this.storageData?.userDetail?.VendorIdPK,
      });
    });
  }
  buildUpdateForm() {
    this.purchaseOrderUpdateFormGroup = this._formBuilder.group({
      paymentMode: new FormControl(''),
      paymentStatus: new FormControl('', [Validators.required]),
      remark: new FormControl(''),
    });
  }
  buildForm() {
    this.purchaseOrderFormGroup = this._formBuilder.group({
      amount: new FormControl('', [Validators.required]),
      date: new FormControl('', [Validators.required]),
      discount: new FormControl('0', [Validators.required]),
      paymentMode: new FormControl(''),
      paymentStatus: new FormControl('', [Validators.required]),
      purchaseId: new FormControl('', [Validators.required]),
      createdBy: new FormControl('', [Validators.required]),
      tax: new FormControl('', [Validators.required]),
      vendorMobile: new FormControl(''),
      vendorName: new FormControl(''),
      remark: new FormControl(''),
      MerchantMasterIdFK: new FormControl(null),
      lineItems: this._formBuilder.array([
        this._formBuilder.group({
          unit: new FormControl(''),
          InventoryItemMasterIdFK: new FormControl(''),
          price: new FormControl(''),
          qty: new FormControl(''),
          expiry: new FormControl(''), //remark
        }),
      ]),
    });
  }
  onSelectedValue(e: Merchant) {
    console.log('Merchant =', e);

    if (e) {
      this.purchaseOrderFormGroup.patchValue({
        vendorMobile: e.mobile,
        MerchantMasterIdFK: e.MerchantMasterIdPK,
      });
    } else {
      this.purchaseOrderFormGroup.get('vendorMobile').reset();
      this.purchaseOrderFormGroup.get('MerchantMasterIdFK').reset();
    }
  }
  onMerchantSearch(e) {
    this.merchants = this.allMerchants.filter((item) => {
      if (item && e) {
        return item.name.toLowerCase().includes(e.toLowerCase());
      }
    });
  }
  onFetch() {
    this.merchantService
      .fetchMerchant({ vendorId: this.storageData?.userDetail?.VendorIdPK })
      .subscribe({
        next: (res: MerchantsFetchApiResponse) => {
          this.allMerchants = res.data;
        },
        error: (err) => {
          this._uiService.presentToast(err);
        },
      });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get netAmount() {
    return (
      this.purchaseOrderFormGroup.value.amount +
      this.purchaseOrderFormGroup.value.tax -
      this.purchaseOrderFormGroup.value.discount
    );
  }

  patchOrder() {
    console.log(this.order);
    if (this.order) {
      this.purchaseOrderUpdateFormGroup.patchValue({
        paymentMode: this.order?.paymentMode || '',
        paymentStatus: this.order?.paymentStatus || '',
        remark: this.order?.remark || '',
      });
    }
  }

  purchaseOrder() {
    this._formService.markFormAsTouched(this.purchaseOrderFormGroup);
    if (!this.purchaseOrderFormGroup.valid) {
      this._uiService.presentToast('Form Not Valid!');
      return;
    }
    if (this.purchaseOrderFormGroup.value.paymentStatus === 'PAID') {
      if (!this.purchaseOrderFormGroup.value.paymentMode) {
        this._uiService.presentToast('Please Select Payment Mode ');
        return;
      }
    }
    if (this.purchaseOrderFormGroup.value.paymentStatus === 'PENDING') {
      this.purchaseOrderFormGroup.removeControl('paymentMode');
    }
    if (!this.purchaseOrderFormGroup.value.MerchantMasterIdFK) {
      this.purchaseOrderFormGroup.removeControl('MerchantMasterIdFK');
    }
    if (this.purchaseOrderFormGroup.value.lineItems.length < 1) {
      this.purchaseOrderFormGroup.removeControl('lineItems');
    }
    if (!this.purchaseOrderFormGroup.value.remark) {
      this.purchaseOrderFormGroup.removeControl('remark');
    }

    this.purchaseOrderFormGroup.removeControl('vendorName');
    this.purchaseOrderFormGroup.removeControl('vendorMobile');

    this.createPurchase$ = this._storeService.createPurchaseOrder(
      this.purchaseOrderFormGroup.value
    );
    this.createPurchase$.subscribe(
      async (res) => {
        if (res) {
          await this._uiService.presentToast(
            'Purchase Order Create sucessfully!'
          );
          this.purchaseOrderFormGroup.reset();
          this.modalCtrl.dismiss(true);
        }
      },
      (error) => {
        this._uiService.presentToast(error);
      }
    );
  }

  onUpdatePurchaseOrder() {
    this._formService.markFormAsTouched(this.purchaseOrderUpdateFormGroup);
    if (!this.purchaseOrderUpdateFormGroup.valid) {
      this._uiService.presentToast('Enter Valid Data ');
      return;
    }
    if (this.purchaseOrderUpdateFormGroup.value.paymentStatus === 'PAID') {
      if (!this.purchaseOrderUpdateFormGroup.value.paymentMode) {
        this._uiService.presentToast('Please Select Payment Mode ');
        return;
      }
    }
    if (this.purchaseOrderUpdateFormGroup.value.paymentStatus === 'PENDING') {
      this.purchaseOrderUpdateFormGroup.removeControl('paymentMode');
    }

    if (!this.purchaseOrderUpdateFormGroup.value.remark) {
      this.purchaseOrderUpdateFormGroup.removeControl('remark');
    }
    this._storeService
      .updatePurchaseOrder(
        this.order.purchaseId,
        this.purchaseOrderUpdateFormGroup.value
      )
      .subscribe({
        next: (res) => {
          this.purchaseOrderUpdateFormGroup.reset();
          this.modalCtrl.dismiss(true);
          this._uiService.presentToast('Updated Successfully');
        },
        error: (err) => {
          this._uiService.presentToast(err);
        },
      });
  }

  fetchInventoryMasterItems() {
    this._service
      .fetchInventoryMasterItem({
        vendorId: this.storageData?.userDetail?.VendorIdPK,
      })
      .subscribe({
        next: (_) => {
          this.inventoryItemMasters = _.data;
        },
        error: (_) => {
          this._uiService.presentToast(_);
        },
      });
  }

  onChangeUnit(e: InventoryItemMaster, i: number) {
    if (e) {
      const unitValue = e.inventory_item_unit.unit;
      const control = this.ingredientFormArray.at(i).get('unit');
      if (control) {
        control.setValue(unitValue);
      }
    } else {
      this.ingredientFormArray.at(i).get('unit').reset();
      this.getUnitValue(i);
    }
  }
  getUnitValue(index: number): string {
    const control = this.ingredientFormArray.at(index).get('unit');
    return control ? control.value : '';
  }
  onRemoveIngredient(i: number) {
    this.ingredientFormArray.removeAt(i);
  }
  addIngredientFormGroup() {
    this.ingredientFormArray.push(
      this._formBuilder.group({
        unit: new FormControl(),
        InventoryItemMasterIdFK: new FormControl(),
        price: new FormControl(),
        qty: new FormControl(),
        expiry: new FormControl(),
      })
    );
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  get ingredientFormArray(): FormArray {
    return this.purchaseOrderFormGroup.get('lineItems') as FormArray;
  }
  loadSearchFilter() {
    this.searchInput$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((res) => {
          this.onIngredientSearch(res);
        })
      )
      .subscribe();
  }
  onIngredientSearch(e) {
    this.inventories = this.inventoryItemMasters.filter((item) => {
      if (item && e) {
        return item.name.toLowerCase().includes(e.toLowerCase());
      }
    });
  }

  public getErrorMessage(fieldName: string, error: string): string {
    return this._formErrorService.getErrorMessage(fieldName, error);
  }
  onDelete() {
    this._uiService.presentActionSheet('Are you sure to delete this Order!', [
      {
        text: 'Yes',
        handler: () => {
          this._storeService
            .deletePurchaseOrder(this.order.purchaseId)
            .subscribe({
              next: (_) => {
                this._uiService.presentToast('deleted successfully');
                this.purchaseOrderUpdateFormGroup.reset();
                this.modalCtrl.dismiss(true);
              },
            });
        },
      },
      {
        text: 'No',
        role: 'cancel',
      },
    ]);
  }
}
