import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { StorageInterface } from 'src/app/shared/services/storage.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { StoreService } from 'src/app/store/store.service';
import { DiningAreaInterface } from '../dining-areas/dining-areas.page';

@Component({
  selector: 'app-add-dining-area',
  templateUrl: './add-dining-area.component.html',
  styleUrls: ['./add-dining-area.component.scss'],
})
export class AddDiningAreaComponent implements OnInit {
  @Input() storageData: StorageInterface;
  @Input() diningArea: DiningAreaInterface;
  diningAreaFormControl = new FormControl(null, [Validators.required]);
  createDiningArea$: Observable<any>;

  constructor(
    private _modal: ModalController,
    private _storeService: StoreService,
    private _uiService: UiService
  ) {}

  ngOnInit() {
    if (this.diningArea) {
      this.diningAreaFormControl.patchValue(this.diningArea.name);
    }
  }

  onAdd() {
    if (!this.diningAreaFormControl.valid) {
      this._uiService.presentToast('Name can not be empty');
      return;
    }
    if (this.diningArea) {
      this.onUpdateDiningArea();
      return;
    }

    this.createDiningArea$ = this._storeService.createStoreDiningArea({
      VendorIdFK: this.storageData?.userDetail?.VendorIdPK,
      name: this.diningAreaFormControl.value,
    });

    this.createDiningArea$.subscribe(
      (res) => {
        if (res) {
          this._uiService.presentToast('Dining Area Created Successfully!');
          this.diningAreaFormControl.patchValue(null);
          this._modal.dismiss(true);
        }
      },
      (error) => {
        this._uiService.presentToast(error);
      }
    );
  }

  onUpdateDiningArea() {
    this.createDiningArea$ = this._storeService.updateDinigArea(
      this.diningArea.DiningAreaIdPK,
      { name: this.diningAreaFormControl.value }
    );

    this.createDiningArea$.subscribe((res) => {
      this._uiService.presentToast('Dining Area Updated Successfully!');
      this.diningAreaFormControl.patchValue(null);
      this.diningArea = null;
      this._modal.dismiss(true);
    });
  }

  dismiss() {
    this._modal.dismiss();
  }
}
