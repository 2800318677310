import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ItemReorderEventDetail, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { StoreService } from '../store/store.service';
import { AddCategoryPage } from './add-category/add-category.page';

import { IonInfiniteScroll } from '@ionic/angular';
import { UiService } from '../shared/services/ui.service';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.page.html',
  styleUrls: ['./create-category.page.scss'],
})
export class CategoryPage {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  categories: CreateCategory[] = [];
  storageData: StorageInterface;
  fetchCategory$: Observable<any>;
  categoryUpdate$: Observable<any>;

  page = 1;
  prevPage: number;
  itemsPerPage = 10;
  totalSize: number;
  serachData = '';
  baseUrl = environment.base_url;
  categoryEditEnable = false;
  categoryId: any;
  allCategoryFetched = false;
  reOrderDisabled = true;

  constructor(
    private _modal: ModalController,
    private _storageService: StorageService,
    private _storeService: StoreService,
    private _router: Router,
    private _uiService: UiService
  ) {}

  ionViewWillEnter() {
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res: any) => {
      this.storageData = res;
      this.fetchPageData();
    });
  }

  async openAddNewCategoryModal(categories?: CreateCategory, index?: number) {
    const modal = await this._modal.create({
      component: AddCategoryPage,
      cssClass: 'add-table-modal',
      componentProps: {
        storageData: this.storageData,
        ...(categories && { categories: categories }),
        ...(categories && { index }),
      },
    });

    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchPageData();
      }
    });
    modal.present();
  }

  openEditModal(categories: CreateCategory, i: number) {
    this.openAddNewCategoryModal(categories, i);
  }

  async fetchPageData(event?) {
    const postData = {
      currentPage: this.page,
      itemsPerPage: this.itemsPerPage,
      searchData: this.serachData,
      VendorIdFK: this.storageData?.userDetail?.VendorIdPK,
    };
    this.fetchCategory$ = this._storeService.fetchCategory(postData);
    this.fetchCategory$.subscribe(
      (res) => {
        if (res.data.length) {
          this.categories = [...this.categories, ...res.data];
          this._storeService.setCategories(this.categories);
          this.totalSize = res.totalSize;
        } else {
          this.allCategoryFetched = true;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        if (event) {
          event.target.complete();
        }
      }
    );
  }

  loadData(event) {
    this.page = this.page + 1;
    this.fetchPageData(event);
  }

  onImageLoadError(e: any) {
    e.target.src = '../../assets/images/icons/menu.png';
  }

  onReArrangeCategories() {
    this.reOrderDisabled = false;
  }

  handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    this.categories = ev.detail.complete(this.categories);
  }

  onSaveSortOrder() {
    let sentObj = {
      sortOrders: [],
    };

    sentObj.sortOrders = [];

    this.categories.forEach((cat, i) => {
      cat.sortOrder = i;
      sentObj.sortOrders.push({
        CategoryIdPK: cat.CategoryIdPK,
        sortOrder: cat.sortOrder,
      });
    });

    this.categoryUpdate$ =
      this._storeService.updateCategoriesSortOrder(sentObj);

    this.categoryUpdate$.subscribe(
      (res) => {
        console.log(res);
        this.reOrderDisabled = true;
      },
      (err) => {
        this._uiService.presentToast(err);
      }
    );
  }
}

export interface CreateCategory {
  name: string;
  VendorIdFK: string;
  sortOrder: number;
  imageType: 'category';
  image: Blob;
  CategoryIdPK: number;
}
