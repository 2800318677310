import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IonDatetime, ModalController } from '@ionic/angular';
import {
  Merchant,
  MerchantsFetchApiResponse,
} from 'src/app/shared/models/merchants.model';
import {
  InventoryCategories,
  InventoryItem,
  InventoryItemMaster,
  InventoryService,
  InvenyoryUnit,
} from 'src/app/shared/services/inventory.service';
import { MerchantService } from 'src/app/shared/services/merchant.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-inventory-create',
  templateUrl: './inventory-create.page.html',
  styleUrls: ['./inventory-create.page.scss'],
})
export class InventoryCreatePage implements OnInit {
  @Input() vendorId!: number;
  @Input() createdBy!: number;
  @Input() itemMaster!: InventoryItemMaster;
  @Input() inventoryItem!: InventoryItem;
  @ViewChild('expiryDate') expiryDate!: IonDatetime;

  baseUrl = environment.base_url;
  // itemImage: any;
  // imageFile: File = {} as File;
  itemInventoryFG!: FormGroup;
  itemInventoryUpdateFG!: FormGroup;
  _formBuilder = inject(FormBuilder);
  _uiService = inject(UiService);
  _service = inject(InventoryService);
  _merchantService = inject(MerchantService);
  categories: InventoryCategories[] = [];
  units: InvenyoryUnit[] = [];
  merchants: Merchant[] = [];

  constructor(private _modal: ModalController) {}

  ngOnInit() {
    this.buildForm();
    // No need to show category in inventory item because category is already selected at the item master creation
    // this.fetchCategories();
    this.fetchMerchants();
    this.onBuildUpdateForm();
    this.fetchUnits();
    if (this.inventoryItem) {
      this.itemInventoryUpdateFG.patchValue({
        InventoryItemMasterIdFK: this.inventoryItem.InventoryItemMasterIdFK,
        InventoryCategoryMasterIdFK:
          this.inventoryItem.InventoryCategoryMasterIdFK,
        // MerchantIdFK: this.inventoryItem.MerchantIdFK,
        VendorIdFK: this.inventoryItem.VendorIdFK,
        discountType: this.inventoryItem.discountType,
        name: this.inventoryItem.name,
        shortDesc: this.inventoryItem.shortDesc,
        discountValue: this.inventoryItem.discountValue,
        mrp: this.inventoryItem.mrp,
        price: this.inventoryItem.price,
        quantity: this.inventoryItem.quantity,
        expiryDate: this.inventoryItem.expiryDate,
      });
    }
  }

  ionViewWillEnter() {
    if (this.inventoryItem)
      this.expiryDate.value = this.inventoryItem.expiryDate.split(' ')[0];
  }

  buildForm() {
    this.itemInventoryFG = this._formBuilder.group({
      categoryId: new FormControl(this.itemMaster.InventoryCategoryMasterIdFK),
      itemMasterId: new FormControl(this.itemMaster.InventoryItemMasterIdPK),
      unitId: new FormControl(
        this.itemMaster ? this.itemMaster.InventoryUnitIdFK : null,
        [Validators.required]
      ),
      name: new FormControl(this.itemMaster ? this.itemMaster.name : null, [
        Validators.required,
      ]),
      shortDesc: new FormControl(null),
      purchaseOrderId: new FormControl(null),
      vendorId: new FormControl(this.vendorId),
      createdBy: new FormControl(this.createdBy),
      merchantId: new FormControl(null),
      discountType: new FormControl(null),
      discountValue: new FormControl(null),
      mrp: new FormControl(null, [Validators.required]),
      price: new FormControl(null, [Validators.required]),
      quantity: new FormControl(null, [Validators.required]),
      expiryDate: new FormControl(new Date().toISOString(), [
        Validators.required,
      ]),
    });

    // need to fix this
    // if (this.inventoryItem) {
    //   // this.itemImage = this.baseUrl + this.inventoryItem.p;
    //   this.itemInventoryFG.patchValue({
    //     InventoryItemMasterIdFK: this.itemMaster.InventoryItemMasterIdPK,
    //     unitId: this.itemMaster.InventoryUnitIdFK,
    //     name: this.inventoryItem.name,
    //     shortDesc: this.inventoryItem.shortDesc
    //       ? this.inventoryItem.shortDesc
    //       : null,
    //     vendorId: this.vendorId,
    //     mrp: this.inventoryItem.mrp,
    //     price: this.inventoryItem.price,
    //     quantity: this.inventoryItem.quantity,
    //     expiryDate: this.inventoryItem.expiryDate,
    //   });
    // }
  }
  onBuildUpdateForm() {
    this.itemInventoryUpdateFG = this._formBuilder.group({
      InventoryItemMasterIdFK: new FormControl('', [Validators.required]),
      InventoryCategoryMasterIdFK: new FormControl('', [Validators.required]),
      MerchantIdFK: new FormControl(null),
      VendorIdFK: new FormControl(this.vendorId),
      discountType: new FormControl(null),
      name: new FormControl(null, [Validators.required]),

      shortDesc: new FormControl(null),
      discountValue: new FormControl(null),
      mrp: new FormControl(null, [Validators.required]),
      price: new FormControl(null, [Validators.required]),
      quantity: new FormControl(null, [Validators.required]),
      expiryDate: new FormControl(new Date().toISOString(), [
        Validators.required,
      ]),
    });
  }

  // fetchCategories() {
  //   this._service
  //     .fetchInventoryCategories({
  //       status: 'ACTIVE',
  //       VendorIdFK: this.vendorId,
  //     })
  //     .subscribe({
  //       next: (_) => {
  //         this.categories = _.data;
  //         if (this.itemMaster) {
  //           this.itemInventoryFG.patchValue({
  //             categoryId: this.itemMaster.InventoryCategoryMasterIdFK,
  //           });
  //         }
  //       },
  //       error: (_) => {
  //         this._uiService.presentToast(_);
  //       },
  //     });
  // }

  fetchMerchants() {
    this._merchantService.fetchMerchant({ vendorId: this.vendorId }).subscribe({
      next: (res: MerchantsFetchApiResponse) => {
        this.merchants = res.data;
        if (this.inventoryItem) {
          this.itemInventoryFG.patchValue({
            merchantId: this.inventoryItem.MerchantIdFK,
          });
          this.itemInventoryUpdateFG.patchValue({
            MerchantIdFK: this.inventoryItem.MerchantIdFK,
          });
        }
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  fetchUnits() {
    // Only master unit is available to select for items
    if (this.itemMaster) this.units.push(this.itemMaster.inventory_item_unit);
    else {
      this._service.fetchInventoryUnits().subscribe({
        next: (_) => {
          this.units = _.data;
        },
        error: (_) => {
          this._uiService.presentToast(_);
        },
      });
    }
  }

  addNewItem() {
    if (!this.itemInventoryFG.valid) {
      console.log(this.itemInventoryFG);

      this._uiService.presentToast('Please fill all the data ');
      return;
    }
    const formData = new FormData();
    const formValue = this.itemInventoryFG.value;

    for (const key in formValue) {
      if (
        Object.prototype.hasOwnProperty.call(formValue, key) &&
        formValue[key] !== null
      ) {
        formData.append(key, formValue[key]);
      }
    }

    // if (this.imageFile) {
    //   formData.append('imageType', 'item-master');
    //   formData.append('image', this.imageFile);
    // }
    this._service.createInventoryItem(formData).subscribe({
      next: (_) => {
        this._uiService.presentToast('Item Created Successfully');
        this.itemInventoryFG.reset();
        this._modal.dismiss(true);
      },
      error: (_) => {
        this._uiService.presentToast(_);
      },
    });
  }

  dismiss() {
    this._modal.dismiss();
  }

  // getFileDetail(event: any) {
  //   if (event.target.files && event.target.files[0]) {
  //     let reader = new FileReader();
  //     reader.onload = (event: any) => {
  //       this.itemImage = event.target.result;
  //     };
  //     reader.readAsDataURL(event.target.files[0]); // to trigger onload
  //   }
  //   this.imageFile = event.target.files[0] as File;
  // }

  onImageLoadError(e: any) {
    e.target.src = '../../../assets/images/icons/menu.png';
  }

  onDeleteItem() {
    let data = {
      createdBy: this.createdBy,
    };
    this._uiService.presentActionSheet('Are you sure to delete this Item!', [
      {
        text: 'Yes',
        handler: () => {
          this._service
            .deleteInventoryItem(this.inventoryItem.InventoryItemIdPK, data)
            .subscribe({
              next: (_) => {
                this._uiService.presentToast('Item deleted successfully');
                this._modal.dismiss(true);
              },
            });
        },
      },
      {
        text: 'No',
        role: 'cancel',
      },
    ]);
  }

  updateItem() {
    if (!this.itemInventoryUpdateFG.valid) {
      this._uiService.presentToast('Please fill all the data ');
      return;
    }
    const formData = new FormData();
    const formValue = this.itemInventoryUpdateFG.value;

    for (const key in formValue) {
      if (
        Object.prototype.hasOwnProperty.call(formValue, key) &&
        formValue[key] !== null
      ) {
        formData.append(key, formValue[key]);
      }
    }
    // if (this.imageFile) {
    //   formData.append('imageType', 'item-master');
    //   formData.append('image', this.imageFile);
    // }
    this._service
      .updateInventoryItem(this.inventoryItem.InventoryItemIdPK, formData)
      .subscribe({
        next: (_) => {
          this._uiService.presentToast('Item Updated Successfully');
          this.itemInventoryFG.reset();
          this._modal.dismiss(true);
        },
        error: (_) => {
          this._uiService.presentToast(_);
        },
      });
  }

  setExpiryDate(e: any) {
    this.itemInventoryFG.patchValue({
      expiryDate: e.detail.value,
    });
  }
}
