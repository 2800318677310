export const environment = {
  production: true,

  firebase: {
    apiKey: 'AIzaSyDO_yBi5WUHZ5F8MIqrq_AQqSkAGK4qj_g',
    authDomain: 'easeinn-e0e04.firebaseapp.com',
    databaseURL: 'https://easeinn-e0e04.firebaseio.com',
    projectId: 'easeinn-e0e04',
    storageBucket: 'easeinn-e0e04.appspot.com',
    messagingSenderId: '48876910482',
    appId: '1:48876910482:web:267fa799198bec0ff6334b',
    vapidKey:
      'BIwaWgV-_nVbho64oZlh1NuVKDk2LwJSzAkWV32sYmMGGulMZM8S0H6h_5n-eqU3LC3l1yhuH4984akRcHGyx_4',
  },
  // base_url: 'https://tapis.easeinn.app/',
  base_url: 'https://apis.easeinn.app/',
  menu_url: 'https://easeinn.app/menu/',
  // base_url: 'http://localhost:3000/',
  // token_key: 'token',
  category_fetch: 'mobile/category/fetch',
  mobile_validate: 'mobile/addcustomer',
  vendor_session: 'vendor/checkSession',
  // user_status_key: 'status',
  // user_mobile_key: 'mobile',
  item_fetch: 'mobile/item/fetchbycategory',
  cart_detail: 'cart',
  fetch_cart: 'mobile/item/fetchforcart',
  // vendor_key: 'vendor_id',
  // customer_key: 'customer_id',
  vendor_fetch: 'vendor/fetchdetail',
  // vendor_status: 'vendor_status',
  // vendor_store_name: 'vendor_store_name',
  otp_send: 'mobile/sendotpcustomer',
  edit_customer: 'mobile/editcustomer',
  // vendor_store_key: 'vendor_key',
  // vendor_store_address: 'vendor_address',
  is_otp_send: 'otp_status',
  // current_table_num: 'table_num',
  // current_table_pk: 'table_id',
  category_update_method: 'product/category/edit',
  category_remove_method: 'product/category/remove',
  create_category_method: 'product/category/add',
  category_pag_fetch_method: 'product/category/fetch',
  check_running_order: 'mobile/order/checkrunningstatus',
  fetch_menu_api: 'product/listMenu',
  fetch_item_by_ids_api: 'product/fetchItemByIds',
  fetch_add_ons: 'product/fetchAddons',
  invoice_url: 'mobile/order/${id}/invoice',
  fetch_dashboard_stats: 'vendor/{id}/statistics',
  fetch_customers: 'vendor/customers',
  vendor_edit_customers: 'vendor/editcustomer',
  fetch_all_tables: 'vendor/{id}/fetchtable',
  fetch_all_dining_areas: 'vendor/fetch/diningArea/{id}',
  create_dining_area: 'vendor/create/diningarea',
  update_dining_area: 'vendor/update/diningarea/{id}',
  create_store_table: 'vendor/createtable',
  update_store_table: 'vendor/updatetable',
  update_category_sort_method: 'product/category/sortOrder',
  item_add_method: 'product/item/add',
  fetch_meal_timings: 'product/fetchMealTimes',
  item_status_update_method: 'product/item/updatestatus',
  item_update_method: 'product/item/edit',
  create_variant_method: 'product/item/addVariant',
  update_variant_method: 'product/item/edit',
  create_add_on_method: 'product/createAddOn',
  delete_add_on_method: 'product/deleteAddOn/{id}',
  update_add_on_method: 'product/updateAddOn/{id}',
  fetch_tags_method: 'product/fetchItemTags',
  create_tag_method: 'product/createItemTag',
  update_item_tags_method: 'product/item/edit',
  create_coupon_method: 'product/coupon',
  fetch_coupons_method: 'product/coupons',
  update_coupon_method: 'product/coupon/{id}',
  delete_coupon_method: 'product/coupon/{id}',
  item_pag_fetch_method: 'product/item/fetch',
  update_recommendations_method: 'product/item/edit',
  fetch_upsell_method: 'product/fetchUpSell',
  create_upsell_method: 'product/createUpSell',
  update_upsell_method: 'product/updateUpSell/${id}',
  delete_upsell_method: 'product/deleteUpSell/${id}',
  update_meal_time_method: 'product/updateMealTime/{id}',
  create_purchase_order_method: 'purchasing/createPo',
  update_purchase_order_method: 'purchasing/editPo/{id}',
  remove_purchase_order_method: 'purchasing/removePo/{id}',
  fetch_purchase_order_method: 'purchasing/fetchPo',
  update_item_sort_method: 'product/item/sortOrder',
  create_inventory_category_method: 'inventory/category',
  fetch_inventory_category_method: 'inventory/category',
  fetch_inventory_transactions_method: 'inventory/transactions',
  fetch_inventory_category_with_item_length_method:
    'inventory/category/stats/{id}',
  fetch_inventory_report_method: 'inventory/report/{id}',
  fetch_product_report_method: 'vendor/product/report/{id}',
  fetch_category_stats_method: 'inventory/category/stats/{id}',
  fetch_order_report_method: 'vendor/order/report/{id}',
  fetch_customer_details_report_method: 'vendor/customer/detailedReport',
  fetch_category_report_method: 'vendor/category/report/{id}',
  delete_inventory_category_method: 'inventory/category/{id}',
  udpate_inventory_category_method: 'inventory/category/{id}',
  fetch_inventory_unit_method: 'inventory/item-unit',
  create_inventory_item_master_method: 'inventory/item-master',
  fetch_inventory_item_master_method: 'inventory/item-master',
  delete_inventory_item_master_method: 'inventory/item-master/{id}',
  update_inventory_item_master_method: 'inventory/item-master/{id}',
  create_inventory_item_method: 'inventory/item',
  fetch_inventory_item_method: 'inventory/item',
  update_inventory_item_method: 'inventory/item/{id}',
  delete_inventory_item_method: 'inventory/item/{id}',
  fetch_inventory_list_method: 'inventory/item-master/inventoryList/{id}',
  manual_stock_out_item_method: 'inventory/item/stock_out/{id}',
};
