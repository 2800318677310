import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

import { ErrorHandler } from './errorhandler.service';
import {
  RecipeFetch,
  RecipeCreateRequest,
  RecipeUpdateRequest,
  RecipeRemove,
  RecipeIngredientsFetch,
} from '../models/recipes.model';
import {
  CREATE_MENU_RECIPE,
  FETCH_MENU_RECIPE,
  MANAGE_INGREDIENTS_API,
  RECIPE_API,
  UPDATE_INGREDIENTS_API,
  UPDATE_MENU_RECIPE,
} from '../constants/apis.constant';
import { MenuRecipeFetch } from '../models/menu-recipe.model';

@Injectable({
  providedIn: 'root',
})
export class RecipesService {
  constructor(private http: HttpClient, private errorHandler: ErrorHandler) {}

  fetchRecipes(data?: any): Observable<RecipeFetch> {
    const params = new HttpParams({ fromObject: data });
    return this.http
      .get<RecipeFetch>(`${environment.base_url}${RECIPE_API}`, { params })
      .pipe(catchError(this.errorHandler.handleError));
  }

  createRecipes(userData: any): Observable<RecipeCreateRequest> {
    return this.http
      .post<RecipeCreateRequest>(
        `${environment.base_url}${RECIPE_API}`,
        userData
      )
      .pipe(catchError(this.errorHandler.handleError));
  }
  fetchIngredients(data?: any): Observable<RecipeIngredientsFetch> {
    const params = new HttpParams({ fromObject: data });
    return this.http
      .get<RecipeIngredientsFetch>(
        `${environment.base_url}${MANAGE_INGREDIENTS_API}`,
        { params }
      )
      .pipe(catchError(this.errorHandler.handleError));
  }
  createManageIngredients(userData: any): Observable<RecipeCreateRequest> {
    return this.http
      .post<RecipeCreateRequest>(
        `${environment.base_url}${MANAGE_INGREDIENTS_API}`,
        userData
      )
      .pipe(catchError(this.errorHandler.handleError));
  }
  updateRecipes(
    recipeId: string | number,
    userData: any
  ): Observable<RecipeUpdateRequest> {
    return this.http
      .patch<RecipeUpdateRequest>(
        `${environment.base_url}${RECIPE_API}/${recipeId}`,
        userData
      )
      .pipe(catchError(this.errorHandler.handleError));
  }
  updateManageIngredients(userData: any): Observable<RecipeUpdateRequest> {
    return this.http
      .patch<RecipeUpdateRequest>(
        `${environment.base_url}${UPDATE_INGREDIENTS_API}`,
        userData
      )
      .pipe(catchError(this.errorHandler.handleError));
  }
  deleteManageIngredients(
    recipeIngredientsId: string | number
  ): Observable<RecipeRemove> {
    return this.http
      .delete<RecipeRemove>(
        `${environment.base_url}${MANAGE_INGREDIENTS_API}/${recipeIngredientsId}`
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteRecipes(recipeId: string | number): Observable<RecipeRemove> {
    return this.http
      .delete<RecipeRemove>(`${environment.base_url}${RECIPE_API}/${recipeId}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateMenuRecipes(userData: any): Observable<RecipeUpdateRequest> {
    return this.http
      .patch<RecipeUpdateRequest>(
        `${environment.base_url}${UPDATE_MENU_RECIPE}`,
        userData
      )
      .pipe(catchError(this.errorHandler.handleError));
  }
  createMenuRecipes(userData: any): Observable<RecipeCreateRequest> {
    return this.http
      .post<RecipeCreateRequest>(
        `${environment.base_url}${CREATE_MENU_RECIPE}`,
        userData
      )
      .pipe(catchError(this.errorHandler.handleError));
  }
  fetchMenuRecipes(data?: any): Observable<MenuRecipeFetch> {
    const params = new HttpParams({ fromObject: data });
    return this.http
      .get<MenuRecipeFetch>(`${environment.base_url}${FETCH_MENU_RECIPE}`, { params })
      .pipe(catchError(this.errorHandler.handleError));
  }
}
