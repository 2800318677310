import { Injectable } from '@angular/core';
import { ErrorHandler } from './errorhandler.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import {
  CREATE_STAFF_API,
  FETCH_STAFF_API,
  REMOVE_STAFF_API,
  UPDATE_STAFF_API,
} from '../constants/apis.constant';
import {
  StaffCreateRequest,
  StaffFetch,
  StaffRemove,
  StaffUpdateRequest,
} from '../models/staff.model';

@Injectable({
  providedIn: 'root',
})
export class ManageStaffService {
  constructor(private http: HttpClient, private errorHandler: ErrorHandler) {}

  fetchStaff(data?: any): Observable<StaffFetch> {
    const params = new HttpParams({ fromObject: data });
    return this.http.get<StaffFetch>(`${environment.base_url}${FETCH_STAFF_API}`, { params })
      .pipe(catchError(this.errorHandler.handleError));
  }

  createStaff(userData: any): Observable<StaffCreateRequest> {
    return this.http.post<StaffCreateRequest>(`${environment.base_url}${CREATE_STAFF_API}`, userData)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateStaff(userData: any): Observable<StaffUpdateRequest> {
    return this.http.put<StaffUpdateRequest>(`${environment.base_url}${UPDATE_STAFF_API}`, userData)
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteStaff(staffId: { StaffIdPK: string | number }): Observable<StaffRemove> {
    const params = new HttpParams({ fromObject: staffId });
    return this.http.delete<StaffRemove>(`${environment.base_url}${REMOVE_STAFF_API}`, { params })
      .pipe(catchError(this.errorHandler.handleError));
  }
}
