import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Recipes } from 'src/app/shared/models/recipes.model';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { FormService } from 'src/app/shared/services/form.service';
import { ManageStaffService } from 'src/app/shared/services/manage-staff.service';
import { RecipesService } from 'src/app/shared/services/recipes.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-recipe-create',
  templateUrl: './recipe-create.page.html',
  styleUrls: ['./recipe-create.page.scss'],
})
export class RecipeCreatePage implements OnInit {
  recipeFormGroup!: FormGroup;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() details: Recipes = {} as Recipes;
  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
  @Input() vendorIdPK: string;
  addRecipe$!: Observable<any>;
  constructor(
    public modalCtrl: ModalController,
    private formErrorService: FormErrorService,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private recipeService: RecipesService,
    private uiService: UiService
  ) {}

  ngOnInit() {
    this.buildForm();

    if (this.details) {
      this.recipeFormGroup.patchValue(this.details);
    }
  }
  buildForm() {
    this.recipeFormGroup = this.formBuilder.group({
      vendorId: new FormControl(''),
      name: new FormControl('', [Validators.required]),
      prepTime: new FormControl('', [Validators.required]),
    });
  }
  dismiss() {
    this.modalCtrl.dismiss();
  }
  onAddNew() {
    this.formService.markFormAsTouched(this.recipeFormGroup);
    if (!this.recipeFormGroup.valid) {
      return;
    }
    // *********For Checking VendorIdPK is exists ************
    if (this.vendorIdPK) {
      this.recipeFormGroup.value.vendorId = this.vendorIdPK;
    } else {
      this.uiService.presentToast('Vendor Id Not Found');
      return;
    }
    if (!this.details) {
      this.addRecipe$ = this.recipeService.createRecipes(
        this.recipeFormGroup.value
      );
    } else {
      this.addRecipe$ = this.recipeService.updateRecipes(
        this.details.RecipeIdPK,
        this.recipeFormGroup.value
      );
    }

    this.addRecipe$.subscribe({
      next: (res) => {
        this.uiService.presentToast(
          !this.details ? ' Created Successfully' : 'Updated Successfully'
        );
        this.recipeFormGroup.reset();
        this.modalCtrl.dismiss({ data: true });
      },
      error: (err) => {
        this.uiService.presentToast(err);
      },
    });
  }

  public getErrorMessage(fieldName: string, error: string): string {
    return this.formErrorService.getErrorMessage(fieldName, error);
  }
}
