import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-account',
  templateUrl: './account.page.html',
  styleUrls: ['./account.page.scss'],
})
export class AccountPage implements OnInit {
  storageData: StorageInterface;
  storeIcon: any;
  baseURL = environment.base_url;
  constructor(
    private _storageService: StorageService,
    private _router: Router
  ) {}

  ngOnInit() {
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      this.storeIcon = res.userDetail.storeIcon;
      //console.log();
    });
  }

  onLogOut() {
    this._storageService.onLogout(this.storageData);
  }

  onDeleteAccount() {
    window.open('https://easeinn.app/delete-account');
  }
}
