import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ItemVariantCreateComponent } from '../item-variant-create/item-variant-create.component';
import { MenuItem } from '../shared/models/menu.model';
import { StorageInterface } from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import {
  Category,
  ItemByIdsInterface,
  MealTimeList,
  StoreService,
} from '../store/store.service';

@Component({
  selector: 'app-item-variants',
  templateUrl: './item-variants.component.html',
  styleUrls: ['./item-variants.component.scss'],
})
export class ItemVariantsComponent implements OnInit {
  @Input() data: {
    categories: Category[];
    mealTimes: MealTimeList[];
    storageData: StorageInterface;
    item?: MenuItem;
  };
  variantIds = [];
  fetchItems$: Observable<any>;
  items: ItemByIdsInterface[] = [];

  constructor(
    private _uiService: UiService,
    private modalCtrl: ModalController,
    private _storeService: StoreService
  ) {}

  ngOnInit() {
    if (this.data.item.variantIds) {
      this.variantIds = this.data.item.variantIds.split(',');
    }
    if (this.variantIds.length) {
      this.fetchItemByIds();
    }
  }

  fetchItemByIds() {
    this.fetchItems$ = this._storeService.fetchItemByIds({
      itemIds: this.variantIds.join(),
    });

    this.fetchItems$.subscribe({
      next: (response) => {
        this.items = response;
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  async openAddItemModal(mode, item?: ItemByIdsInterface) {
    const modal = await this.modalCtrl.create({
      component: ItemVariantCreateComponent,
      animated: true,
      cssClass: 'variant-modal',
      componentProps: {
        mode: mode,
        data: this.data,
        variant: item,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        if (data.data.hasOwnProperty('addedId')) {
          this.variantIds.push(data.data.addedId);
        }
        if (data.data.hasOwnProperty('removedId')) {
          let index = this.variantIds.findIndex(
            (id) => +id === +data.data.removedId
          );
          this.variantIds.splice(index, 1);
        }
        this.data.item.variantIds = this.variantIds.join();
        this._storeService.itemChanged.next(true);
        this.fetchItemByIds();
      }
    });

    modal.present();
  }
}
