import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthPage } from './auth/auth.page';
import { WelcomeScreenPage } from './welcome-screen/welcome-screen.page';
import { FooterPage } from './shared/components/footer/footer.page';
import { HomePage } from './home/home.page';
import { StorePage } from './store/store.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './shared/services/auth.interceptor.service';
import { ActiveOrdersPage } from './orders/active-orders/active-orders.page';
import { PastOrdersPage } from './orders/past-orders/past-orders.page';
import { WaitingOrdersPage } from './orders/active-orders/waiting-orders/waiting-orders.page';
import { AcceptedOrdersPage } from './orders/active-orders/accepted-orders/accepted-orders.page';
import { CompletedOrdersPage } from './orders/past-orders/completed-orders/completed-orders.page';
import { CancelledOrdersPage } from './orders/past-orders/cancelled-orders/cancelled-orders.page';
import { OrderCardComponent } from './shared/components/order-card/order-card.component';
import { AccountPage } from './account/account.page';
import { MorePage } from './more/more.page';
import { HeaderWithBackComponent } from './shared/components/header-with-back/header-with-back.component';
import { InvoiceComponent } from './shared/components/invoice/invoice.component';
import { SupportPage } from './support/support.page';
import { SettingsPage } from './settings/settings.page';
import { FieldErrorsComponent } from './shared/components/field-errors/field-errors.component';
import { PaymentModalComponent } from './shared/components/payment-modal/payment-modal.component';
import { MenuPopoverComponent } from './shared/components/menu-popover/menu-popover.component';
import { CartPage } from './cart/cart.page';
import { CustomersPage } from './customers/customers.page';
import { NewOrderAlertComponent } from './shared/components/new-order-alert/new-order-alert.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ItemCustomizationModalComponent } from './shared/components/item-customization-modal/item-customization-modal.component';
import { RepeatLastModalPage } from './shared/components/repeat-last-modal/repeat-last-modal.page';
import { QuickOrderPage } from './store/quick-order/quick-order.page';
import { MenuPipe } from './shared/pipes/menu.pipe';
import { CustomizeItemCartPage } from './shared/components/customize-item-cart/customize-item-cart.page';
import { TablesPage } from './tables/tables.page';
import { CustomerSelectHeaderPage } from './shared/components/customer-select-header/customer-select-header.page';
import { AskCustomerNamePage } from './shared/components/ask-customer-name/ask-customer-name.page';
import { AddTableComponent } from './tables/add-table/add-table.component';
import { DiningAreasPage } from './tables/dining-areas/dining-areas.page';
import { AddDiningAreaComponent } from './tables/add-dining-area/add-dining-area.component';
import { CategoryPage } from './create-category/create-category.page';
import { AddCategoryPage } from './create-category/add-category/add-category.page';
import { ItemsListPage } from './items-list/items-list.page';
import { ItemCreateComponent } from './item-create/item-create.component';
import { ItemVariantsComponent } from './item-variants/item-variants.component';
import { ItemVariantCreateComponent } from './item-variant-create/item-variant-create.component';
import { ItemAddonCreateComponent } from './item-addon-create/item-addon-create.component';
import { ItemAddonsComponent } from './item-addons/item-addons.component';
import { ItemRecommendationsComponent } from './item-recommendations/item-recommendations.component';
import { ItemTagsComponent } from './item-tags/item-tags.component';
import { MealTimingsPage } from './meal-timings/meal-timings.page';
import { CouponsPage } from './coupons/coupons.page';
import { PurchaseOrdersPage } from './purchase-orders/purchase-orders.page';
import { UpsellOffersPage } from './upsell-offers/upsell-offers.page';
import { CouponCreateComponent } from './coupon-create/coupon-create.component';
import { PurchaseOrderCreateComponent } from './purchase-order-create/purchase-order-create.component';
import { MealTimingCreateComponent } from './meal-timing-create/meal-timing-create.component';
import { UpsellOfferCreateComponent } from './upsell-offer-create/upsell-offer-create.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomNgSelectComponent } from './shared/components/custom-ng-select/custom-ng-select.component';
import { DeliveryPersonListingPageModule } from './delivery-person/delivery-person-listing/delivery-person-listing.module';
import { DeliveryPersonCreateComponent } from './delivery-person/delivery-person-create/delivery-person-create.component';
import { ManageQrsPage } from './manage-qrs/manage-qrs.page';
import { SharedModule } from './shared/shared.module';
import { KotUserCreateComponent } from './kot-user/kot-user-create/kot-user-create.component';
import { NoDataFoundComponent } from './shared/components/no-data-found/no-data-found.component';
import { NoDataFoundModule } from './shared/components/no-data-found/no-data-found.module';
import { InventoryDashboardPage } from './inventory/inventory-dashboard/inventory-dashboard.page';
import { InventoryListPage } from './inventory/inventory-list/inventory-list.page';
import { InventoryCreatePage } from './inventory/inventory-create/inventory-create.page';
import { InventoryCategoryCreatePage } from './inventory/inventory-category-create/inventory-category-create.page';
import { MerchantsListPage } from './merchants/merchants-list/merchants-list.page';
import { MerchantCreatePage } from './merchants/merchant-create/merchant-create.page';
import { RecipesListPage } from './recipes/recipes-list/recipes-list.page';
import { RecipeCreatePage } from './recipes/recipe-create/recipe-create.page';
import { ItemRecipeComponent } from './item-recipe/item-recipe.component';
import { InventoryMasterCreatePage } from './inventory/inventory-master-create/inventory-master-create.page';
import firebase from 'firebase/compat/app';
import { environment } from 'src/environments/environment.prod';

firebase.initializeApp(environment.firebase);
import { StaffCreateComponent } from './manage-staff/staff-create/staff-create.component';
import { RecipeIngredientsPage } from './recipes/recipe-ingredients/recipe-ingredients.page';
import { StaffListingPage } from './manage-staff/staff-listing/staff-listing.page';
import { ReportSalesPage } from './reports/report-sales/report-sales.page';
import { ReportsDashboardPage } from './reports/reports-dashboard/reports-dashboard.page';
import { ItemComboCreatePage } from './item-combo-create/item-combo-create.page';
import { WebQrPage } from './web-qr/web-qr.page';
import { StockInPage } from './inventory/stock-in/stock-in.page';
import { StockOutPage } from './inventory/stock-out/stock-out.page';
import { ExcelService } from './shared/services/excel.service';
import { BannerCreateComponent } from './banner-create/banner-create.component';
import { BannersPage } from './banners/banners.page';
import { DatePipe } from '@angular/common';
import { PromotionalMessagePage } from './promotional-message/promotional-message.page';
import { InventoryTransactionsPage } from './inventory/inventory-transactions/inventory-transactions.page';
import { ReportCategoryPage } from './reports/report-category/report-category.page';
import { ReportProductPage } from './reports/report-product/report-product.page';
import { ReportCustomerDetailPage } from './reports/report-customer-detail/report-customer-detail.page';
import { RenewSubscriptionPage } from './renew-subscription/renew-subscription.page';

@NgModule({
  declarations: [
    AppComponent,
    HomePage,
    WelcomeScreenPage,
    AuthPage,
    FooterPage,
    StorePage,
    ActiveOrdersPage,
    PastOrdersPage,
    WaitingOrdersPage,
    AcceptedOrdersPage,
    CompletedOrdersPage,
    CancelledOrdersPage,
    OrderCardComponent,
    MorePage,
    AccountPage,
    HeaderWithBackComponent,
    InvoiceComponent,
    MenuPopoverComponent,
    SupportPage,
    SettingsPage,
    FieldErrorsComponent,
    PaymentModalComponent,
    CartPage,
    CustomersPage,
    NewOrderAlertComponent,
    ItemCustomizationModalComponent,
    RepeatLastModalPage,
    QuickOrderPage,
    MenuPipe,
    CustomizeItemCartPage,
    TablesPage,
    CustomerSelectHeaderPage,
    AskCustomerNamePage,
    AddTableComponent,
    DiningAreasPage,
    AddDiningAreaComponent,
    CategoryPage,
    AddCategoryPage,
    ItemsListPage,
    ItemCreateComponent,
    ItemVariantsComponent,
    ItemVariantCreateComponent,
    ItemAddonsComponent,
    ItemRecipeComponent,
    ItemAddonCreateComponent,
    ItemRecommendationsComponent,
    ItemTagsComponent,
    MealTimingsPage,
    CouponsPage,
    PurchaseOrdersPage,
    UpsellOffersPage,
    CouponCreateComponent,
    PurchaseOrderCreateComponent,
    MealTimingCreateComponent,
    UpsellOfferCreateComponent,
    CustomNgSelectComponent,
    DeliveryPersonCreateComponent,
    KotUserCreateComponent,
    ManageQrsPage,
    InventoryDashboardPage,
    InventoryListPage,
    InventoryCreatePage,
    InventoryCategoryCreatePage,
    MerchantsListPage,
    MerchantCreatePage,
    RecipesListPage,
    RecipeCreatePage,
    InventoryMasterCreatePage,
    StaffCreateComponent,
    RecipeIngredientsPage,
    StaffListingPage,
    ReportSalesPage,
    ReportsDashboardPage,
    ItemComboCreatePage,
    WebQrPage,
    StockInPage,
    StockOutPage,
    BannerCreateComponent,
    BannersPage,
    PromotionalMessagePage,
    InventoryTransactionsPage,
    ReportCategoryPage,
    ReportProductPage,
    ReportCustomerDetailPage,
    RenewSubscriptionPage,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    DeliveryPersonListingPageModule,
    NgHttpLoaderModule.forRoot(),
    SharedModule,
    NoDataFoundModule,
  ],
  providers: [
    ExcelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DatePipe, // currently using in only inventory service
  ],
  bootstrap: [AppComponent],
  exports: [FooterPage],
})
export class AppModule {}
