import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { MealTimingInterface } from '../meal-timings/meal-timings.page';
import { FormErrorService } from '../shared/services/form-error.service';
import { FormService } from '../shared/services/form.service';
import { UiService } from '../shared/services/ui.service';
import { StoreService } from '../store/store.service';

@Component({
  selector: 'app-meal-timing-create',
  templateUrl: './meal-timing-create.component.html',
  styleUrls: ['./meal-timing-create.component.scss'],
})
export class MealTimingCreateComponent implements OnInit {
  @Input() meal: MealTimingInterface;
  mealTimeFormGroup: FormGroup;
  updateMealTiming$!: Observable<any>;
  isInvalid: boolean = false;
  constructor(
    public modalCtrl: ModalController,
    private _storeService: StoreService,
    private _uiService: UiService,
    private _formBuilder: FormBuilder,
    private _formErrorService: FormErrorService,
    private _formService: FormService
  ) {}
  ngOnInit() {
    this.buildForm();
    this.mealItem();
  }
  buildForm() {
    this.mealTimeFormGroup = this._formBuilder.group({
      startTime: new FormControl('', [Validators.required]),
      endTime: new FormControl('', [Validators.required]),
    });
  }
  mealItem() {
    if (this.meal) {
      this.mealTimeFormGroup.patchValue(this.meal);
    }
  }
  public onChange(event: any): void {
    this.isInvalid =
      this.mealTimeFormGroup.value.startTime >=
      this.mealTimeFormGroup.value.endTime;
    if (this.isInvalid) {
      this._uiService.presentToast(
        'End Time should be greater than Start Time'
      );
    }
  }

  updateMealTiming() {
    this._formService.markFormAsTouched(this.mealTimeFormGroup);
    if (
      !this.mealTimeFormGroup.valid ||
      this.mealTimeFormGroup.value.startTime >=
        this.mealTimeFormGroup.value.endTime
    ) {
      this._uiService.presentToast('Enter Valid Time');
      return;
    }

    this.updateMealTiming$ = this._storeService.updateMealTimes(
      this.meal.MealTimeIdPK,
      this.mealTimeFormGroup.value
    );
    this.updateMealTiming$.subscribe(
      async (res) => {
        if (res) {
          await this._uiService.presentToast('Time Update sucessfully!');
          this.mealTimeFormGroup.reset();
          this.modalCtrl.dismiss(true);
        }
      },
      (error) => {
        this._uiService.presentToast(error);
      }
    );
  }

  public getErrorMessage(fieldName: string, error: string): string {
    return this._formErrorService.getErrorMessage(fieldName, error);
  }
}
