export const STORAGE_CONSTANT = 'storageConstant';

export enum ORDER_STATUS_ENUMS {
  ACTIVE = 'ACTIVE',
  WAITING = 'WAITING',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PARTIALLY_ACCEPTED = 'PARTIALLY-ACCEPTED',
  COMPLETED = 'COMPLETED',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  PREPARING = 'PREPARING',
  PREPARED = 'PREPARED',
  KOT = 'KOT', // Specially for KOT users
}

export enum ORDER_TYPES_ENUM {
  BY_SELF = 'BY_SELF',
  BY_CUSTOMER = 'BY_CUSTOMER',
  HOME_DELIVERY = 'HOME_DELIVERY',
}
export const CURRENCY_TYPE = [
  { label: 'India Rupee', value: '₹' },
  // { label: 'US Dollar', value: '$' },
  // { label: 'Australia Dollar', value: '$' },
  // { label: 'Indonesia Rupiah', value: 'Rp' },
  // { label: 'Japan Yen', value: '¥' },
  // { label: 'Cambodia Riel', value: '៛' },
];
