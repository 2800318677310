import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ModalController, AlertController, IonSearchbar } from '@ionic/angular';
import {
  ItemMenu,
  ItemMenuCategory,
  MealTimeCategory,
  StoreMenuInterface,
  StoreService,
} from './store.service';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';
import { MenuPopoverComponent } from '../shared/components/menu-popover/menu-popover.component';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';
import { CartService } from '../shared/services/cart.service';
import { OrderService } from '../shared/services/order.service';
import { UiService } from '../shared/services/ui.service';
import { ItemCustomizationModalComponent } from '../shared/components/item-customization-modal/item-customization-modal.component';
import { RepeatLastModalPage } from '../shared/components/repeat-last-modal/repeat-last-modal.page';

@Component({
  selector: 'app-store',
  templateUrl: './store.page.html',
  styleUrls: ['./store.page.scss'],
})
export class StorePage implements OnInit, OnDestroy {
  popover;
  @ViewChild('content', { static: false }) content;
  @ViewChild('searchStore') searchInput!: IonSearchbar;
  fetchCategoryObs: Observable<any>;
  fetchItemsObs: Observable<any>;
  items: ItemMenuCategory[] = [];
  mealTimeItems: MealTimeCategory[] = [];
  itemsAll: ItemMenu[] = [];
  itemSearch: ItemMenuCategory[] = [];
  prevCategory: number;
  categories: any = [];
  headerDisplay = true;
  baseUrl = environment.base_url;
  vendorStoreName: any;
  isSearchActive = false;
  loadingView = true;
  cartCount: number;
  currentItemDetail;
  cartData = [];
  showSearchBar = false;
  slideOpts = {
    initialSlide: 0,
    speed: 400,
  };
  showCustomerInfo = false;
  storageData: StorageInterface;
  fetchCustDetailObs: Observable<any>;
  cartTotalPrice = 0;
  reloadCustomerSelectHeaderData$: Subject<boolean> = new Subject<boolean>();
  activeCategory: string;
  // TO make it more optimize emit only in desktop mode
  storeEventsToCart$: Subject<string> = new Subject();

  constructor(
    private storeService: StoreService,
    private _cartService: CartService,
    private _storageService: StorageService,
    private router: Router,
    private alertController: AlertController,
    private _orderService: OrderService,
    private _uiService: UiService,
    public modalController: ModalController
  ) {
    storeService.scrollToCategory.subscribe((res) => {
      this.categoryByScroll(res);
    });

    _cartService.cartDataChanged.subscribe((data) => {
      console.log('Cart Data changed: ', data);
      this.cartData = data;
      this.updateCartCountBadge();
      this.syncUIWithCart();
    });
    _cartService.setCartData();
    this.cartData = _cartService.getFullCartData();
    this.cartCount = _cartService.getCartLength();
  }

  ngOnInit() {}

  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      if (!this.storageData?.userDetail?.VendorIdPK) {
        this.router.navigate(['/oops']);
        this.ngOnDestroy();
        return;
      }
      this.vendorStoreName = this.storageData.userDetail.vendorStoreName;
      this.fetchItems();
    });
  }

  // fetchAllTables() {
  //   this._orderService
  //     .fetchAllTables('' + this.storageData?.userDetail?.VendorIdPK)
  //     .subscribe(
  //       (result: any) => {
  //         //console.log(result);
  //         if (this.storageData.userDetail.vendorStoreType === 'SINGLETABLE') {
  //           this.storageData.tableIdPK = result[0].TableIdPK;
  //           this.storageData.tableNumber = result[0].tableNumber;
  //           this._storageService
  //             .setObject(STORAGE_CONSTANT, this.storageData)
  //             .then((res) => {});
  //         } else {
  //           // this._uiService.presentToast('Need to handle MULTITABLE');
  //         }
  //       },
  //       (error) => {
  //         this._uiService.presentToast(error);
  //       }
  //     );
  // }

  continueWithoutMobile() {
    this.storageData.customerIdPK = null;
    this.storageData.customerStatus = null;
    this.storageData.customerMobileNumber = 'Not Available';
    this.storageData.customerName = 'Not Available';
    this._storageService
      .setObject(STORAGE_CONSTANT, this.storageData)
      .then((res) => {
        this.showCustomerInfo = !this.showCustomerInfo;
      });
  }

  changeCustomerMobile() {
    this.showCustomerInfo = !this.showCustomerInfo;
  }

  async presentCategoryPopover(ev: any) {
    this.popover = await this.modalController.create({
      component: MenuPopoverComponent,
      animated: true,
      cssClass: this.cartCount ? 'menu-pop shift-menu-pop' : 'menu-pop',
    });
    return await this.popover.present(ev);
  }

  updateCartCountBadge() {
    this.cartData = this._cartService.getFullCartData();
    this.cartCount = this._cartService.getCartLength();
    //console.log('cartcount', this.cartCount);
    if (this.cartCount === 0) {
      this.cartCount = undefined;
    }
  }

  addItemToCart(data: ItemMenu): void {
    // If item has variants and/or add on avilalble, open the this.modalController, else directly add to the card
    if (
      (data.variantIds !== '' && data.variantIds !== null) ||
      data.isAddOnExists
    ) {
      this.openItemCustomizationModal(data);
    } else {
      this._cartService.setCartData();
      data.qty = 1;
      //console.log(data);
      this.currentItemDetail = data;
      this.updateCartData(data.qty);
      this.storeEventsToCart$.next('ITEM_ADD');
    }
  }

  async openItemCustomizationModal(data, isIllChoose = false) {
    const modal = await this.modalController.create({
      component: ItemCustomizationModalComponent,
      cssClass: 'item-customization-modal',
      componentProps: { data },
    });
    modal.onDidDismiss().then((res) => {
      //console.log('receivedObj', res);
      if (res.data) {
        this._cartService.setCartData();

        if (isIllChoose) {
          data.qty += 1;
        } else {
          data.qty = 1; // this is for ui to change button
        }

        this._cartService.manageCustomizableItem(data, res);
        this.storeEventsToCart$.next('ITEM_ADD');
      }
    });

    return await modal.present();
  }

  updateCartData(qty) {
    this._cartService.updateCartData({
      ItemIdPK: this.currentItemDetail.ItemIdPK,
      qty: qty,
      isExtra: false,
      price: +this.currentItemDetail.price,
      ...(this.currentItemDetail.InventoryItemMasterIdFK && {
        InventoryItemMasterIdFK: this.currentItemDetail.InventoryItemMasterIdFK,
      }),
    });
  }

  increaseItemQty(data) {
    console.log('increase', data);

    if (
      (data.variantIds !== '' && data.variantIds !== null) ||
      data.isAddOnExists
    ) {
      this.openRepeatLastModal(data);
    } else {
      ++data.qty;
      this.currentItemDetail = data;
      this.updateCartData(data.qty);
    }

    this.storeEventsToCart$.next('ITEM_INCREMENT');
  }

  async openRepeatLastModal(data) {
    const modal = await this.modalController.create({
      component: RepeatLastModalPage,
      cssClass: 'repeat-customization-modal',
      componentProps: { data },
    });

    modal.onDidDismiss().then((res) => {
      //console.log('repeat-res', res);
      if (res.data.isRepeat) {
        // repeat cart last item
        this._cartService.repeatCartItem(data.ItemIdPK);
        // data.qty += 1;
      } else {
        // I'll choose

        this.openItemCustomizationModal(data, true);
      }
    });
    return await modal.present();
  }

  decreaseItemQty(data) {
    //console.log('decrease cart qty');

    if (
      (data.variantIds !== '' && data.variantIds !== null) ||
      data.isAddOnExists
    ) {
      // check for customization
      if (this._cartService.isItemRemovePossible(data.ItemIdPK)) {
        // remove then
        // data.qty -= 1;
        if (data.qty <= 1) {
          this.promotedItemRemovedFromCart(data.ItemIdPK);
        }
        this._cartService.decreaseQtyOfCustomizableItem(data.ItemIdPK);
      } else {
        // navigate to cart
        this._uiService.presentActionSheet(
          'This item has multiple customizations added. Proceed cart to remove item?',
          [
            {
              text: 'Yes',
              handler: () => {
                this.router.navigateByUrl('/cart');
              },
            },
            {
              text: 'No',
              role: 'cancel',
            },
          ]
        );
      }
    } else {
      --data.qty;
      this.currentItemDetail = data;
      if (data.qty <= 0) {
        this.removeItemFromCart();
        this.promotedItemRemovedFromCart(data.ItemIdPK);
      } else {
        this.updateCartData(data.qty);
      }
    }

    this.storeEventsToCart$.next('ITEM_DECREMENT');
  }

  // this function will take care if same item removed from promoted or normal menu
  promotedItemRemovedFromCart(ItemIdPK) {
    this.items.forEach((item) => {
      item.items.forEach((menuItem) => {
        if (menuItem.ItemIdPK === +ItemIdPK) {
          menuItem.qty = 0;
        }
      });
    });

    if (this.mealTimeItems) {
      this.mealTimeItems.forEach((item) => {
        item.items.forEach((menuItem) => {
          if (menuItem.ItemIdPK === +ItemIdPK) {
            menuItem.qty = 0;
          }
        });
      });
    }
  }

  removeItemFromCart() {
    this._cartService.deleteCartData(this.currentItemDetail.ItemIdPK);
  }

  // isHardReload basically skip check if item already fetch and fetch it anyways
  fetchItems(isHardReload = false) {
    this.fetchItemsObs = this.storeService.getMenu({
      vendorId: this.storageData?.userDetail?.VendorIdPK,
    });

    if (this.items.length > 0 && !isHardReload) {
      return;
    }
    this.fetchItemsObs.subscribe((res: StoreMenuInterface) => {
      this.items = res.categories;
      this.itemSearch = JSON.parse(JSON.stringify(this.items));

      this.mealTimeItems = res.mealTimes;

      if (this.cartData.length) {
        this.syncUIWithCart();
      }
      this.categories = this.storeService.getCategories();
    });
  }

  syncUIWithCart() {
    //console.log('sync-ui');
    this.itemsAll = [];

    this.items.forEach((item) => {
      item.items.forEach((menuItem) => {
        menuItem.qty = 0;
      });
    });
    this.cartData.map((data) => {
      this.items.forEach((item) => {
        item.items.forEach((menuItem) => {
          if (data.ItemIdPK === menuItem.ItemIdPK) {
            menuItem.qty = data.qty;
          }
          this.itemsAll.push(menuItem);
        });
      });

      if (this.mealTimeItems) {
        this.mealTimeItems.forEach((mealTimeItem) => {
          mealTimeItem.items.forEach((menuItem) => {
            if (data.ItemIdPK === menuItem.ItemIdPK) {
              menuItem.qty = data.qty;
            }
          });
        });
      }
    });

    // This to handle scenario when order placed & in subject we recieved empty cart so we have to reset all the qtys to 0
    if (this.cartData.length === 0) {
      this.items.forEach((item) => {
        item.items.forEach((menuItem) => {
          menuItem.qty = 0;
          this.itemsAll.push(menuItem);
        });
      });

      if (this.mealTimeItems) {
        this.mealTimeItems.forEach((mealTimeItem) => {
          mealTimeItem.items.forEach((menuItem) => {
            menuItem.qty = 0;
          });
        });
      }
    }

    this.cartTotalPrice = this._cartService.getCartTotalPriceForStore();
  }

  categoryByScroll(categoryData) {
    this.activeCategory = categoryData.CategoryIdPK;
    let y = document.getElementById(categoryData.CategoryIdPK).offsetTop;
    this.content.scrollToPoint(0, y - 80, 1000);
  }

  ngOnDestroy() {
    //   this.subscriptionPopOverScroll.unsubscribe();
    //   this.subscriptionModalDismiss.unsubscribe();
    this.storeEventsToCart$.unsubscribe();
  }

  onSearch(event) {
    if (!event.target.value || event.target.value === '') {
      this.items = this.itemSearch;
    } else {
      let matches: ItemMenuCategory[] = JSON.parse(
        JSON.stringify(this.itemSearch)
      );
      this.items = [];

      matches.forEach((item) => {
        let removeIndexes = [];

        item.items.forEach((menuItem, i) => {
          if (
            !menuItem.name
              .toLowerCase()
              .includes(event.target.value.toLowerCase())
          ) {
            removeIndexes.push(i);
          }
        });

        if (removeIndexes.length) {
          let count = 0;
          removeIndexes.forEach((index) => {
            item.items.splice(index - count, 1);
            count++;
          });
        }
      });

      matches.forEach((item, i) => {
        if (item.hasOwnProperty('items')) {
          if (item.items.length === 0) {
            matches.splice(i, 1);
          }
        }
      });

      this.items = JSON.parse(JSON.stringify(matches));
    }
  }

  resetItems() {
    this.items = this.itemSearch;
  }

  cancelSearch() {
    this.showSearchBar = false;
    this.resetItems();
  }

  // changeSegmentValue() {
  //   this.segmentValue = 'ORDER';
  // }

  ionViewWillEnter() {
    // - //console.log(this.segmentValue);
    // - this.segmentValue = 'MENU';
    this.updateCartCountBadge();
    this.setStorageData();
    this.reloadCustomerSelectHeaderData$.next(true);
  }

  async onRescan() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirm!',
      message: 'Are you sure you want to rescan qr code!!!',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: 'Okay',
          handler: () => {
            // this.storageService.clearCartFromStorage();
            this.router.navigateByUrl('/landing', { replaceUrl: true });
          },
        },
      ],
    });

    await alert.present();
  }

  // New UI Specific
  addToCart() {}

  toggleSearchBar(e) {
    this.showSearchBar = !this.showSearchBar;
    setTimeout(() => {
      this.searchInput.setFocus();
    }, 150);
  }

  // async openScanner() {
  //   const modal = await this.modalController.create({
  //     component: ScannerComponent,
  //     cssClass: 'scanner-modal',
  //   });
  //   return await modal.present();
  // }

  onImageLoadError(e: any) {
    e.target.src = environment.base_url + 'images/defaultitem.png';
  }

  openQuickOrder() {
    this.router.navigateByUrl('/store/quick-order');
  }

  resetCart() {
    // Present confirmation alert
    this._cartService.clearCartFromStorage();
    this.storeEventsToCart$.next();
    this.fetchItems();
  }

  goToCart() {
    this.router.navigate(['/cart'], {
      queryParams: {
        from: '/store',
      },
    });
  }
  toggleCustomerInfo(value: boolean) {
    this.showCustomerInfo = value;
  }
  onCategoryImageLoadError(e: any) {
    e.target.src = '../../../assets/images/icons/menu.png';
  }
}
