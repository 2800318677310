import { Location } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InventoryCategoryCreatePage } from '../inventory-category-create/inventory-category-create.page';
import {
  InventoryCategories,
  InventoryReportInterface,
  InventoryService,
  InventoryStatsResponse,
} from '../../shared/services/inventory.service';
import {
  StorageInterface,
  StorageService,
} from 'src/app/shared/services/storage.service';
import { STORAGE_CONSTANT } from 'src/app/shared/constants/app.constants';
import { UiService } from 'src/app/shared/services/ui.service';
import { StockInPage } from '../stock-in/stock-in.page';
import { StockOutPage } from '../stock-out/stock-out.page';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inventory-dashboard',
  templateUrl: './inventory-dashboard.page.html',
  styleUrls: ['./inventory-dashboard.page.scss'],
})
export class InventoryDashboardPage implements OnInit {
  _service = inject(InventoryService);
  _storageService = inject(StorageService);
  _uiService = inject(UiService);
  _router = inject(Router);
  categories: InventoryCategories[] = [];
  report: InventoryReportInterface = {} as InventoryReportInterface;

  storageData!: StorageInterface;

  constructor(private _location: Location, private _modal: ModalController) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.setStorageData();
  }
  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res: any) => {
      this.storageData = res;
      this.fetchCategories();
      this.fetchInventoryStats();
    });
  }

  fetchCategories() {
    this._service
      .fetchInventoryCategoriesWithItemLength(
        this.storageData?.userDetail?.VendorIdPK,
        {
          status: 'ACTIVE',
          VendorIdFK: this.storageData?.userDetail?.VendorIdPK,
        }
      )
      .subscribe({
        next: (_) => {
          this.categories = _.data;
        },
        error: (_) => {
          this._uiService.presentToast(_);
        },
      });
  }

  fetchInventoryStats() {
    this._service
      .fetchInventoryStats(this.storageData?.userDetail?.VendorIdPK)
      .subscribe({
        next: (_: InventoryStatsResponse) => {
          this.report = _.data;
        },
        error: (_) => {
          this._uiService.presentToast(_);
        },
      });
  }

  goToPreviousScreen() {
    this._location.back();
  }

  async openAddCategoryModal(category?: InventoryCategories) {
    const modal = await this._modal.create({
      component: InventoryCategoryCreatePage,
      cssClass: 'add-table-modal',
      animated: true,
      componentProps: {
        VendorIdFK: this.storageData?.userDetail?.VendorIdPK,
        category,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchCategories();
      }
    });
    modal.present();
  }

  async openAddStockInModal() {
    const modal = await this._modal.create({
      component: StockInPage,
      animated: true,
      componentProps: {
        vendorId: this.storageData?.userDetail?.VendorIdPK,
        createdBy: this.storageData?.userDetail?.UserIdFK,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchCategories();
      }
    });
    modal.present();
  }
  async openAddStockOutModal() {
    const modal = await this._modal.create({
      component: StockOutPage,
      animated: true,
      componentProps: {
        vendorId: this.storageData?.userDetail?.VendorIdPK,
        userId: this.storageData?.userDetail?.UserIdFK,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchCategories();
      }
    });
    modal.present();
  }

  onOpenListByCategory(Category: InventoryCategories) {
    this._router.navigate(['/inventory/list'], {
      queryParams: {
        categoryId: Category.InventoryCategoryMasterIdPK,
      },
    });
  }

  navigateToListBySegment(segment: string) {
    this._router.navigate(['/inventory/list'], {
      queryParams: {
        segment,
      },
    });
  }
}
