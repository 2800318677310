import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DeliveryPersonListingPageRoutingModule } from './delivery-person-listing-routing.module';

import { DeliveryPersonListingPage } from './delivery-person-listing.page';
import { NoDataFoundModule } from 'src/app/shared/components/no-data-found/no-data-found.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    DeliveryPersonListingPageRoutingModule,
    NoDataFoundModule
  ],
  declarations: [DeliveryPersonListingPage],
})
export class DeliveryPersonListingPageModule {}
