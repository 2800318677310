import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { CouponCreateComponent } from '../coupon-create/coupon-create.component';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import { StoreService } from '../store/store.service';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.page.html',
  styleUrls: ['./coupons.page.scss'],
})
export class CouponsPage {
  coupons: CouponInterface[] = [];
  fetchCoupons$: Observable<any>;
  deleteCoupon$: Observable<any>;
  storageData: StorageInterface;
  constructor(
    private modalCtrl: ModalController,
    private _uiService: UiService,
    private _storeService: StoreService,
    private _storageService: StorageService
  ) {}

  ionViewWillEnter() {
    this.setStorageData();
  }
  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res: any) => {
      this.storageData = res;
      this.fetchCoupons();
    });
  }

  async fetchCoupons() {
    this.fetchCoupons$ = await this._storeService.fetchCoupons(
      this.storageData?.userDetail?.VendorIdPK
    );
    this.fetchCoupons$.subscribe((res) => {
      this.coupons = res;
    });
  }

  async openCouponModal(mode, coupon?: CouponInterface) {
    const modal = await this.modalCtrl.create({
      component: CouponCreateComponent,
      animated: true,
      cssClass: 'coupons-modal',
      componentProps: {
        storageData: this.storageData,
        ...(coupon && { coupon: coupon }),
        mode: mode,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchCoupons();
      }
    });
    modal.present();
  }
}
export interface CouponInterface {
  CouponIdPK: number;
  VendorIdFK: number;
  code: string;
  discount: number;
  discountType: string;
  appVisibility: boolean;
  expiryDate: string;
  status: string;
  createdAt: string;
  cartMinValue: number;
  cartMaxValue: number;
  maximumAllowedDiscount: number;
}
