import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IonDatetime, ModalController } from '@ionic/angular';
import {
  Merchant,
  MerchantsFetchApiResponse,
} from 'src/app/shared/models/merchants.model';
import {
  InventoryCategories,
  InventoryItem,
  InventoryItemMaster,
  InventoryService,
  InvenyoryUnit,
} from 'src/app/shared/services/inventory.service';
import { MerchantService } from 'src/app/shared/services/merchant.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-stock-out',
  templateUrl: './stock-out.page.html',
  styleUrls: ['./stock-out.page.scss'],
})
export class StockOutPage implements OnInit {
  @Input() vendorId!: number;
  @Input() userId!: number;

  baseUrl = environment.base_url;
  itemMasters: InventoryItemMaster[] = [];
  allItemMasters: InventoryItemMaster[] = [];
  selectedItemMaster!: InventoryItemMaster;
  inventoryItems: InventoryItem[] = [];
  allInventoryItems: InventoryItem[] = [];
  selectedItem!: InventoryItem;

  stockOutFG!: FormGroup;
  _formBuilder = inject(FormBuilder);
  _uiService = inject(UiService);
  _service = inject(InventoryService);
  _merchantService = inject(MerchantService);
  units: InvenyoryUnit[] = [];
  merchants: Merchant[] = [];

  constructor(private _modal: ModalController) {}

  ngOnInit() {
    this.buildForm();
    this.fetchItemMasters();
  }

  ionViewWillEnter() {}

  buildForm() {
    this.stockOutFG = this._formBuilder.group({
      itemMasterSelection: new FormControl(null),
      inventoryItemSelection: new FormControl(null),
      qty: new FormControl(null, [Validators.required]), // this control will use for ui puprose to show proper name or other fields
      createdBy: new FormControl(this.userId),
    });
  }

  fetchItemMasters() {
    this._service
      .fetchInventoryMasterItem({
        vendorId: this.vendorId,
      })
      .subscribe({
        next: (_) => {
          this.itemMasters = _.data;
          this.allItemMasters = JSON.parse(JSON.stringify(_.data));
        },
      });
  }

  // addNewItem() {
  //   if (!this.itemInventoryFG.valid) {
  //     console.log(this.itemInventoryFG);

  //     this._uiService.presentToast('Please fill all the data ');
  //     return;
  //   }
  //   const formData = new FormData();
  //   for (const key in this.itemInventoryFG.value) {
  //     if (
  //       Object.prototype.hasOwnProperty.call(this.itemInventoryFG.value, key)
  //     ) {
  //       formData.append(key, this.itemInventoryFG.value[key]);
  //     }
  //   }
  //   this._service.createInventoryItem(formData).subscribe({
  //     next: (_) => {
  //       this._uiService.presentToast('Item Created Successfully');
  //       this.itemInventoryFG.reset();
  //       this._modal.dismiss(true);
  //     },
  //     error: (_) => {
  //       this._uiService.presentToast(_);
  //     },
  //   });
  // }

  onItemMasterSearch(e: any) {
    this.itemMasters = this.allItemMasters.filter((item) => {
      if (item && e) {
        return item.name.toLowerCase().includes(e.toLowerCase());
      }
    });
  }

  onItemMasterSelect(e: InventoryItemMaster) {
    this.stockOutFG.get('inventoryItemSelection').reset();
    this.selectedItemMaster = undefined;
    this.inventoryItems = [];
    if (e) {
      this.units = [];
      this.units.push(e.inventory_item_unit);
      this.fetchInventoryItems(e.InventoryItemMasterIdPK);
      this.selectedItemMaster = e;
    }
  }

  fetchInventoryItems(itemMasterIdPK: number) {
    this._service
      .fetchInventoryItems({
        itemMasterId: itemMasterIdPK,
        vendorId: this.vendorId,
      })
      .subscribe({
        next: (_) => {
          this.inventoryItems = _.data;
          this.allInventoryItems = JSON.parse(JSON.stringify(_.data));
        },
        error: (_) => {
          this._uiService.presentToast(_);
        },
      });
  }

  onInventoryItemSearch(e: any) {
    this.inventoryItems = this.allInventoryItems.filter((item) => {
      if (item && e) {
        return item.name.toLowerCase().includes(e.toLowerCase());
      }
    });
  }

  onInventoryItemSelect(e: InventoryItem) {
    this.selectedItem = undefined;
    if (e) this.selectedItem = e;
  }

  onStockOut() {
    if (!this.selectedItem) {
      this._uiService.presentToast('Select inventory item first ');
      return;
    }
    if (!this.stockOutFG.valid) {
      this._uiService.presentToast('Enter qty');
      return;
    }

    this._service
      .manualStockOut(this.selectedItem.InventoryItemIdPK, {
        createdBy: this.stockOutFG.value.createdBy,
        qty: this.stockOutFG.value.qty,
      })
      .subscribe({
        next: (_) => {
          this._uiService.presentToast('Stock updated sucessfully');
          this._modal.dismiss(true);
        },
        error: (_) => {
          this._uiService.presentToast(_);
        },
      });
  }

  dismiss() {
    this._modal.dismiss();
  }
}
