import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { CustomersPage } from 'src/app/customers/customers.page';
import { STORAGE_CONSTANT } from '../../constants/app.constants';
import { OrderService } from '../../services/order.service';
import {
  StorageInterface,
  StorageService,
} from '../../services/storage.service';
import { UiService } from '../../services/ui.service';
import { AskCustomerNamePage } from '../ask-customer-name/ask-customer-name.page';

@Component({
  selector: 'app-customer-select-header',
  templateUrl: './customer-select-header.page.html',
  styleUrls: ['./customer-select-header.page.scss'],
})
export class CustomerSelectHeaderPage implements OnInit {
  mobileFormControl: FormControl = new FormControl(null, [
    Validators.required,
    Validators.pattern(/^[6-9]\d{9}$/),
  ]);
  fetchCustDetail$: Observable<any>;
  showCustomerInfo = false;
  storageData: StorageInterface;
  customerName: string;
  @Output() toggleCustomerInfo$: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() reloadData$: Subject<boolean> = new Subject();

  constructor(
    private modalController: ModalController,
    private _orderService: OrderService,
    private _storageService: StorageService,
    private _uiService: UiService
  ) {}

  ngOnInit() {
    this.reloadData$.subscribe((res) => {
      console.log('load', res);

      this.setStorageData();
    });
  }

  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      if (this.storageData.customerMobileNumber) {
        this.showCustomerInfo = true;
        this.toggleCustomerInfo$.emit(this.showCustomerInfo);
        this.mobileFormControl.setValue(this.storageData.customerMobileNumber);
      } else {
        this.showCustomerInfo = false;
        this.toggleCustomerInfo$.emit(this.showCustomerInfo);
        this.mobileFormControl.patchValue(null);
      }
      if (
        this.storageData.customerName &&
        this.storageData.customerName !== 'Not Available'
      ) {
        this.customerName = this.storageData.customerName;
      } else {
        this.customerName = null;
      }
    });
  }

  async openCustomerList() {
    let isModal = true;
    const modal = await this.modalController.create({
      component: CustomersPage,
      cssClass: 'customer-list-modal',
      componentProps: { isModal },
    });

    modal.onDidDismiss().then((res) => {
      if (res.data) {
        this.storageData.customerIdPK = res.data.CustomerIdPK;
        this.storageData.customerMobileNumber = res.data.mobile;
        this.mobileFormControl.patchValue(res.data.mobile);
        this.storageData.customerStatus = res.data.status;
        if (res.data.name === null) {
          this.openAskCustomerNameModal();
          this.storageData.customerName = 'Not Available';
        } else {
          this.storageData.customerName = res.data.name;
          this.customerName = this.storageData.customerName;
        }
        this._storageService
          .setObject(STORAGE_CONSTANT, this.storageData)
          .then((res) => {
            this.showCustomerInfo = true;
            this.toggleCustomerInfo$.emit(this.showCustomerInfo);
          });
      }
    });
    return await modal.present();
  }

  changeCustomerMobile() {
    this.storageData.customerName = null;
    this.storageData.customerMobileNumber = null;
    this.storageData.customerIdPK = null;
    this.customerName = null;
    this._storageService
      .setObject(STORAGE_CONSTANT, this.storageData)
      .then((res) => {
        this.showCustomerInfo = false;
        this.toggleCustomerInfo$.emit(this.showCustomerInfo);
        this.mobileFormControl.patchValue(null);
      });
  }

  fetchCustomerInfo() {
    //console.log();
    if (this.mobileFormControl.valid) {
      this.fetchCustDetail$ = this._orderService.getOrAddCustomer({
        mobile: this.mobileFormControl.value,
        vendorId: this.storageData.userDetail.VendorIdPK,
      });

      this.fetchCustDetail$.subscribe(
        (responseData) => {
          //console.log(responseData);
          if (responseData.statusCode === 200) {
            if (responseData.data.name === null) {
              this.openAskCustomerNameModal();
              this.storageData.customerName = 'Not Available';
            } else {
              this.storageData.customerName = responseData.data.name;
              this.customerName = this.storageData.customerName;
            }
            this.storageData.customerIdPK = responseData.data.CustomerIdPK;
            this.storageData.customerMobileNumber = responseData.data.mobile;
            this.storageData.customerStatus = responseData.data.status;
            this._storageService
              .setObject(STORAGE_CONSTANT, this.storageData)
              .then((res) => {
                this.showCustomerInfo = !this.showCustomerInfo;
                this.toggleCustomerInfo$.emit(this.showCustomerInfo);
              });
          }
        },
        (err) => {
          this._uiService.presentToast(err);
        }
      );
    } else {
      this._uiService.presentToast('Invalid Customer Mobile Number!');
      return;
    }
  }

  async openAskCustomerNameModal() {
    const modal = await this.modalController.create({
      component: AskCustomerNamePage,
      cssClass: 'item-customization-modal',
      componentProps: { mobileNumber: this.mobileFormControl.value },
    });

    modal.onDidDismiss().then((res) => {
      if (res.data) {
        this.storageData.customerName = res.data.name;
        this.customerName = res.data.name;
        this._storageService
          .setObject(STORAGE_CONSTANT, this.storageData)
          .then((res) => {});
      }
    });

    await modal.present();
  }
}
