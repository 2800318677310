import { Component, OnInit } from '@angular/core';
import { ItemReorderEventDetail, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { ItemCreateComponent } from '../item-create/item-create.component';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';
import { MenuItem } from '../shared/models/menu.model';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import {
  Category,
  ItemMenu,
  ItemMenuCategory,
  MealTimeList,
  StoreService,
} from '../store/store.service';
import { ItemComboCreatePage } from '../item-combo-create/item-combo-create.page';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-items-list',
  templateUrl: './items-list.page.html',
  styleUrls: ['./items-list.page.scss'],
})
export class ItemsListPage implements OnInit {
  items: ItemMenuCategory[] = [];
  allItems: ItemMenuCategory[] = [];
  baseUrl = environment.base_url;
  fetchMenuItems$: Observable<any>;
  fetchCategory$: Observable<any>;
  mealTimeFetch$: Observable<any>;
  itemUpdate$: Observable<any>;
  storageData: StorageInterface;
  allVariantIdsItems: any = [];
  categories: Category[] = [];
  mealTimes: MealTimeList[] = [];
  reOrderDisabled = true;
  searchFC = new FormControl(null);

  constructor(
    private _storeService: StoreService,
    private _storageService: StorageService,
    private modalCtrl: ModalController,
    private _uiService: UiService
  ) {
    _storeService.itemChanged.subscribe((res) => {
      if (res) {
        this.fetchInitialData();
      }
    });
  }

  ngOnInit(): void {
    this.loadSearchFilter();
  }

  ionViewWillEnter() {
    this.setStorageData();
  }
  setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      this.fetchInitialData();
      this.fetchMealTimes();
      this.fetchCategories();
    });
  }

  loadSearchFilter() {
    this.searchFC.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap((_) => {
          if (!_ || _ === '') {
            this.items = this.allItems;
          } else {
            let matches: ItemMenuCategory[] = JSON.parse(
              JSON.stringify(this.allItems)
            );
            this.items = [];

            matches.forEach((item) => {
              let removeIndexes = [];

              item.items.forEach((menuItem, i) => {
                if (!menuItem.name.toLowerCase().includes(_.toLowerCase())) {
                  removeIndexes.push(i);
                }
              });

              if (removeIndexes.length) {
                let count = 0;
                removeIndexes.forEach((index) => {
                  item.items.splice(index - count, 1);
                  count++;
                });
              }
            });

            matches.forEach((item, i) => {
              if (item.hasOwnProperty('items')) {
                if (item.items.length === 0) {
                  matches.splice(i, 1);
                }
              }
            });

            this.items = JSON.parse(JSON.stringify(matches));
            console.log('After search', this.items);
          }
        })
      )
      .subscribe();
  }

  fetchInitialData() {
    this.fetchMenuItems$ = this._storeService.getMenu({
      vendorId: this.storageData?.userDetail?.VendorIdPK,
      fetchInActive: true,
    });
    this.fetchMenuItems$.subscribe((res) => {
      this.items = res.categories;
      this.allItems = JSON.parse(JSON.stringify(res.categories));
    });
  }

  fetchCategories() {
    const categorySendData = {
      currentPage: 1,
      itemsPerPage: 100,
      VendorIdFK: this.storageData?.userDetail?.VendorIdPK,
    };

    this.fetchCategory$ = this._storeService.fetchCategory(categorySendData);
    this.fetchCategory$.subscribe((res) => {
      this.categories = res.data;
    });
  }

  fetchMealTimes() {
    this.mealTimeFetch$ = this._storeService.fetchMealTimes();

    this.mealTimeFetch$.subscribe((res) => {
      this.mealTimes = res.data;
    });
  }

  async openAddItemModal(mode: string, type?: string, item?: MenuItem) {
    if (item) {
      if (item.comboIds) type = 'COMBO';
    }

    const modal = await this.modalCtrl.create({
      component: type === 'COMBO' ? ItemComboCreatePage : ItemCreateComponent,
      animated: true,
      componentProps: {
        mode: mode,
        type: type,
        data: {
          categories: this.categories,
          mealTimes: this.mealTimes,
          storageData: this.storageData,
          ...(item && { item: item }),
        },
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchInitialData();
      }
    });
    modal.present();
  }

  async openAddComboModal(mode: string, type?: string, item?: MenuItem) {
    const modal = await this.modalCtrl.create({
      component: ItemComboCreatePage,
      animated: true,
      componentProps: {
        mode: mode,
        type: type,
        data: {
          categories: this.categories,
          mealTimes: this.mealTimes,
          storageData: this.storageData,
          ...(item && { item: item }),
        },
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchInitialData();
      }
    });
    modal.present();
  }

  openEditModal(item) {}

  onImageLoadError(e: any) {
    e.target.src = '../../assets/images/icons/menu.png';
  }

  onChangeItemStatus(item: ItemMenu) {
    this.itemUpdate$ = this._storeService.updateItemStatus({
      VendorIdPK: this.storageData?.userDetail?.VendorIdPK,
      ItemIdPK: item.ItemIdPK,
      status: item.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
    });

    this.itemUpdate$.subscribe({
      next: (res) => {
        this._uiService.presentToast('Item Status Updated Successfully!');
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  onReArrangeItems() {
    this.reOrderDisabled = false;
  }

  onSaveSortOrder() {
    let sentObj = {
      sortOrders: [],
    };

    sentObj.sortOrders = [];

    this.items.forEach((item, i) => {
      if (item.isReorderEnable) {
        item.items.forEach((nesItem, j) => {
          nesItem.sortOrder = j;
          sentObj.sortOrders.push({
            ItemIdPK: nesItem.ItemIdPK,
            sortOrder: nesItem.sortOrder,
          });
        });
      }
    });

    this.itemUpdate$ = this._storeService.updateItemSortOrder(sentObj);

    this.itemUpdate$.subscribe(
      (res) => {
        this.reOrderDisabled = true;
      },
      (err) => {
        this._uiService.presentToast(err);
      }
    );
  }

  handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    ev.detail.complete();
  }
}
