import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-web-qr',
  templateUrl: './web-qr.page.html',
  styleUrls: ['./web-qr.page.scss'],
})
export class WebQrPage implements OnInit {
  route = inject(ActivatedRoute);
  currentQrName!: string;
  storeUrl!: string;
  constructor() {}

  ngOnInit() {
    this.route.queryParams.subscribe({
      next: (_) => {
        this.currentQrName = _.currentQrName;
        this.storeUrl = _.storeUrl;
      },
    });
  }
}
