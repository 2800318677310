import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { STORAGE_CONSTANT } from 'src/app/shared/constants/app.constants';
import {
  CategoryReportFetchApiResponse,
  TopSellingCategories,
  TopSellingCategory,
} from 'src/app/shared/models/category-report.model';
import {
  OrderApiResponse,
  ProductReportFetchApiResponse,
  TopSellingItem,
  TopSellingItems,
} from 'src/app/shared/models/product-report.model';
import { OrderService } from 'src/app/shared/services/order.service';
import {
  StorageInterface,
  StorageService,
} from 'src/app/shared/services/storage.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-reports-dashboard',
  templateUrl: './reports-dashboard.page.html',
  styleUrls: ['./reports-dashboard.page.scss'],
})
export class ReportsDashboardPage implements OnInit {
  currentSelectedTab = 'WEEK';
  filterStartDate: any;
  filterEndDate: any;
  salesItemReportData: TopSellingItem[] = [];
  categoryReportData: TopSellingCategory[] = [];
  dashboardStats: OrderApiResponse = {} as OrderApiResponse;
  storageData: StorageInterface;
  constructor(
    private _orderService: OrderService,
    private _storageService: StorageService,
    private _uiService: UiService
  ) {}

  ngOnInit() {}
  ionViewWillEnter() {
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res: any) => {
      this.storageData = res;
      this.calculateDateFilter();
    });
  }

  segmentChanged(e) {
    this.currentSelectedTab = e.detail.value;
    console.log(this.currentSelectedTab);
    this.calculateDateFilter();
  }

  async calculateDateFilter() {
    const today = new Date();
    if (this.currentSelectedTab === 'WEEK') {
      const pastWeekStartDate = new Date(today);
      pastWeekStartDate.setDate(today.getDate() - 7);
      this.filterStartDate = formatDate(
        pastWeekStartDate,
        'yyyy-MM-dd',
        'en-US'
      );
    } else if (this.currentSelectedTab === 'MONTH') {
      this.filterStartDate = formatDate(
        new Date(today.getFullYear(), today.getMonth(), 1),
        'yyyy-MM-dd',
        'en-US'
      );
    } else if (this.currentSelectedTab === 'YEAR') {
      this.filterStartDate = formatDate(
        new Date(today.getFullYear(), 0, 1),
        'yyyy-MM-dd',
        'en-US'
      );
    }

    this.filterEndDate = formatDate(today, 'yyyy-MM-dd', 'en-US');

    this.fetchOrderReport();
  }
  async fetchOrderReport() {
    this._orderService
      .fetchOrderReport(this.storageData?.userDetail?.VendorIdPK, {
        ...(this.filterStartDate && {
          startDate: this.filterStartDate,
        }),
        ...(this.filterEndDate && {
          endDate: this.filterEndDate,
        }),
      })
      .subscribe((responseData: OrderApiResponse) => {
        if (responseData.statusCode === 200) {
          this.dashboardStats = responseData;
          this.salesItemReportData = Object.values(
            responseData.data.topSellingItems
          );
          this.categoryReportData = Object.values(
            responseData.data.topSellingCategories
          );
        }
      });
  }
}
