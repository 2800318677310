import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { Observable } from 'rxjs';
import { DeliveryPersonInterface } from 'src/app/shared/models/delivery-person.model';
import { DeliveryPersonService } from 'src/app/shared/services/delivery-person.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { FormService } from 'src/app/shared/services/form.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-delivery-person-create',
  templateUrl: './delivery-person-create.component.html',
  styleUrls: ['./delivery-person-create.component.scss'],
})
export class DeliveryPersonCreateComponent implements OnInit {
  deliveryPersonFormGroup!: FormGroup;
  @Input() details: DeliveryPersonInterface = {} as DeliveryPersonInterface;
  @Input() VendorIdPK: string;
  addDeliveryPerson$!: Observable<any>;
  constructor(
    private _modal: ModalController,
    private _formErrorService: FormErrorService,
    private _formBuilder: FormBuilder,
    private _formService: FormService,
    private _deliverPersonService: DeliveryPersonService,
    private _uiService: UiService
  ) {}

  ngOnInit() {
    this.buildForm();

    // Waiting for Fetch Api Works
    if (this.details) {
      this.deliveryPersonFormGroup.patchValue(this.details);
    }
  }
  buildForm() {
    this.deliveryPersonFormGroup = this._formBuilder.group({
      name: new FormControl('', [Validators.required]),
      designation: new FormControl('DELIVERY_PERSON'),
      type: new FormControl('DELIVERY_PERSON'),
      mobile: new FormControl('', [Validators.required]),
      userLoginId: new FormControl(''),
      salary: new FormControl('', [Validators.required]),
      password: new FormControl('', []),
    });
  }
  dismiss() {
    this._modal.dismiss();
  }
  onAddNewPerson() {
    this._formService.markFormAsTouched(this.deliveryPersonFormGroup);
    if (!this.deliveryPersonFormGroup.valid) {
      return;
    }
    // *********For Checking VendorIdPK is exists ************
    if (this.VendorIdPK) {
      this.deliveryPersonFormGroup.value['VendorIdPK'] = this.VendorIdPK;
    } else {
      this._uiService.presentToast('Vendor Id Not Found');
      return;
    }
    if (!this.details) {
      this.addDeliveryPerson$ = this._deliverPersonService.createDeliveryPerson(
        this.deliveryPersonFormGroup.value
      );
    } else {
      this.deliveryPersonFormGroup.removeControl('password'),
        this.deliveryPersonFormGroup.removeControl('userLoginId'),
        (this.deliveryPersonFormGroup.value['StaffIdPK'] =
          this.details.StaffIdPK);
      this.addDeliveryPerson$ = this._deliverPersonService.updateDeliveryPerson(
        this.deliveryPersonFormGroup.value
      );
    }

    this.addDeliveryPerson$.subscribe({
      next: (res) => {
        this._uiService.presentToast(
          !this.details
            ? 'Account Created Successfully'
            : 'Updated Successfully'
        );
        this.deliveryPersonFormGroup.reset();
        this._modal.dismiss(true);
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }
  public getErrorMessage(fieldName: string, error: string): string {
    return this._formErrorService.getErrorMessage(fieldName, error);
  }
}
