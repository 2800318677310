import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { FormErrorService } from '../shared/services/form-error.service';
import { FormService } from '../shared/services/form.service';
import { StorageInterface } from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import { StoreService, UpSellList } from '../store/store.service';

@Component({
  selector: 'app-upsell-offer-create',
  templateUrl: './upsell-offer-create.component.html',
  styleUrls: ['./upsell-offer-create.component.scss'],
})
export class UpsellOfferCreateComponent implements OnInit {
  @Input() mode;
  @Input() data: {
    storageData: StorageInterface;
    upSell: UpSellList;
  };
  createUpSellFromGroup: FormGroup;
  itemFetch$: Observable<any>;
  items: any[] = [];
  allItems: any[] = [];
  creaetUpSell$: Observable<any>;
  searchFormControl = new FormControl(null);
  constructor(
    public modalCtrl: ModalController,
    private _formBuilder: FormBuilder,
    private _formService: FormService,
    private _formErrorService: FormErrorService,
    private _storeService: StoreService,
    private _uiService: UiService,
    private _changeDetRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    console.log(this.data);

    this.buildForm();
    this.fetchItems();
  }

  buildForm() {
    this.createUpSellFromGroup = this._formBuilder.group({
      upSellName: new FormControl(null, Validators.required),
      status: new FormControl(null, Validators.required),
      cartMinValue: new FormControl(null, Validators.required),
      cartMaxValue: new FormControl(null, Validators.required),
      upSellItems: new FormControl(null),
      upSellItemsArray: this._formBuilder.array([this.createUpSellItem()]),
    });

    if (this.mode === 'EDIT') {
      this.data.upSell.upSellItems.forEach((upsell, i) => {
        if (i !== 0) {
          this.upSellItemsFormArray.push(this.createUpSellItem());
        }
      });
    }
  }

  get upSellItemsFormArray(): FormArray {
    return this.createUpSellFromGroup.get('upSellItemsArray') as FormArray;
  }

  addUpSellItem() {
    this._formService.markFormAsTouched(this.upSellItemsFormArray);
    // this.upSellItemsFormArray.markAllAsTouched();
    if (!this.upSellItemsFormArray.valid) {
      return;
    }
    this.upSellItemsFormArray.push(this.createUpSellItem());
  }

  createUpSellItem(item?, price?): FormGroup {
    return this._formBuilder.group({
      item: new FormControl(item ? item : null, Validators.required),
      price: new FormControl(price ? price : null, [
        Validators.required,
        Validators.min(0),
      ]),
    });
  }

  formGroupByAbstract(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  onRemoveUpSellItem(i: number) {
    this.upSellItemsFormArray.removeAt(i);
  }

  fetchItems(search = '') {
    const itemSendData = {
      // currentPage: 1,
      // itemsPerPage: 10,
      searchData: search,
      CategoryIdPK: '',
      VendorIdPK: this.data.storageData.userDetail.VendorIdPK,
      skipParentVariant: true,
    };

    this.itemFetch$ = this._storeService.fetchItem(itemSendData);
    this.itemFetch$.subscribe(
      (responseData) => {
        this.items = responseData.data;
        this.allItems = JSON.parse(JSON.stringify(this.items));

        if (this.mode === 'EDIT') {
          this.createUpSellFromGroup.patchValue(this.data.upSell);
          this.patchUpSellFormArray();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onItemSearch(e) {
    this.items = this.allItems.filter((item) => {
      if (item && e) {
        return item.name.toLowerCase().includes(e.toLowerCase());
      }
    });
  }

  onCreateUpsellOffer() {
    this._formService.markFormAsTouched(this.createUpSellFromGroup);

    if (!this.createUpSellFromGroup.valid) {
      return;
    }

    console.log('value', this.createUpSellFromGroup.value);

    this.prepareCreateUpSellData();

    if (this.mode === 'EDIT') {
      this.creaetUpSell$ = this._storeService.updateUpSell(
        this.data.upSell.UpsellIdPK,
        this.createUpSellFromGroup.value
      );
    } else {
      this.creaetUpSell$ = this._storeService.createUpSell(
        this.createUpSellFromGroup.value
      );
    }

    this.creaetUpSell$.subscribe({
      next: (res) => {
        if (res) {
          this.createUpSellFromGroup.reset();
          if (this.mode === 'EDIT') {
            this._uiService.presentToast('Upsell Updated Successfully!');
          } else {
            this._uiService.presentToast('Upsell Created Successfully!');
          }
          this.modalCtrl.dismiss(true);
        }
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  prepareCreateUpSellData() {
    let upSellItems = [];
    this.upSellItemsFormArray.controls.forEach((upSellItemCntrl) => {
      upSellItems.push({
        ItemIdPK: upSellItemCntrl.get('item').value.ItemIdPK,
        price: upSellItemCntrl.get('price').value,
      });
    });

    this.createUpSellFromGroup
      .get('upSellItems')
      .setValue(JSON.stringify(upSellItems));
  }

  patchUpSellFormArray() {
    this.data.upSell.upSellItems.forEach((upsell, i) => {
      this.upSellItemsFormArray.controls[i].patchValue({
        item: upsell,
        price: upsell.price,
      });
    });
  }
  close() {
    this.modalCtrl.dismiss();
  }

  onDeleteUpsell() {
    this.creaetUpSell$ = this._storeService.deleteUpSell(
      this.data.upSell.UpsellIdPK
    );

    this._uiService.presentActionSheet('Are you sure to delete this UpSell!', [
      {
        text: 'Yes',
        handler: () => {
          this.creaetUpSell$.subscribe({
            next: (res) => {
              this._uiService.presentToast('Upsell Deleted Duccessfully!');
              this.modalCtrl.dismiss(true);
            },
            error: (err) => {
              this._uiService.presentToast(err);
            },
          });
        },
      },
      {
        text: 'No',
        role: 'cancel',
      },
    ]);
  }

  public getErrorMessage(fieldName: string, error: string): string {
    return this._formErrorService.getErrorMessage(fieldName, error);
  }
}
