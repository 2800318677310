import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from './errorhandler.service';
import { environment } from 'src/environments/environment.prod';
import { MERCHANTS_API } from '../constants/apis.constant';

@Injectable({
  providedIn: 'root',
})
export class MerchantService {
  constructor(private http: HttpClient, private errorHandler: ErrorHandler) {}

  fetchMerchant(data?: any): Observable<any> {
    return this.http
      .get(`${environment.base_url}${MERCHANTS_API}`, {
        params: data,
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  createMerchant(userData: any): Observable<any> {
    return this.http
      .post(`${environment.base_url}${MERCHANTS_API}`, userData)
      .pipe(catchError((error) => this.handleError(error)));
  }

  updateMerchant(userData: any, id: string): Observable<any> {
    return this.http
      .patch(`${environment.base_url}${MERCHANTS_API}/${id}`, userData)
      .pipe(catchError((error) => this.handleError(error)));
  }

  deleteMerchant(merchantId: number): Observable<any> {
    return this.http
      .delete(`${environment.base_url}${MERCHANTS_API}/${merchantId}`)
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(error: any): Observable<any> {
    // Handle error here, you can log or perform any custom logic
    console.error('An error occurred:', error);
    // Forward the error to the errorHandler if needed
    this.errorHandler.handleError(error);
    // Rethrow the error to propagate it down the observable chain
    return throwError(error);
  }
}
