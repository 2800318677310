import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { IonModal, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import {
  ORDER_STATUS_ENUMS,
  ORDER_TYPES_ENUM,
  STORAGE_CONSTANT,
} from '../../constants/app.constants';
import { OrderInterface, OrderService } from '../../services/order.service';
import { UiService } from '../../services/ui.service';
import { InvoiceComponent } from '../invoice/invoice.component';
import { PaymentModalComponent } from '../payment-modal/payment-modal.component';
import { Share } from '@capacitor/share';
import { environment } from 'src/environments/environment.prod';
import { DeliveryPersonService } from '../../services/delivery-person.service';
import {
  StorageInterface,
  StorageService,
} from '../../services/storage.service';
import { DeliveryPersonInterface } from '../../models/delivery-person.model';
import { SELECT_DEVLIVERY_PERSON_ERROR_MSG } from '../../constants/message.constant';
import { Staffs } from '../../models/staff.model';

@Component({
  selector: 'app-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss'],
})
export class OrderCardComponent implements OnInit {
  @Input() ordersData: OrderInterface[] = [] as OrderInterface[];
  @Input() kitchenUsers: Staffs[] = [];

  @ViewChild(IonModal) modal: IonModal;

  storageData: StorageInterface;
  orderStatusEnum = ORDER_STATUS_ENUMS;
  orderTypeEnum = ORDER_TYPES_ENUM;
  orderUpdate$!: Observable<any>;
  cancellationReason!: FormControl;
  openSelectDeliveryPerson = false;
  presentingDeliveryPersonElement = null;
  deliveryPersons: DeliveryPersonInterface[] = [];
  selectedPersonFC: FormControl = new FormControl(null, [Validators.required]);
  selectedOrder!: OrderInterface;

  @ViewChild('popover') popover;
  contactInfoIsOpen = false;

  constructor(
    private modalCtrl: ModalController,
    private _orderService: OrderService,
    private _uiService: UiService,
    private _deliveryPerson: DeliveryPersonService,
    private _storageService: StorageService
  ) {}

  ngOnInit() {
    this.setStorageData();
    this.cancellationReason = new FormControl(null);
  }

  setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      this.fetchDeliveryPersons();
    });
  }

  toggleOrderedItemsListView(i: number) {
    this.ordersData[i].showList = !this.ordersData[i].showList;
    this.ordersData[i].showReject = false;
  }

  toggleCancellationView(i: number) {
    this.ordersData[i].showList = false;
    this.ordersData[i].showReject = !this.ordersData[i].showReject;
  }

  async openPaymentModal(order: OrderInterface) {
    // To manage home delivery
    if (
      order.type === ORDER_TYPES_ENUM.HOME_DELIVERY &&
      order.status === ORDER_STATUS_ENUMS.ACCEPTED
    ) {
      this.openSelectDeliveryPerson = true;
      this.modal.onDidDismiss().then((res) => {
        this.openSelectDeliveryPerson = false;
        if (res.data) {
          this.onUpdateOrderToOutForDelivery(order, res.data);
        }
      });
      return;
    }
    if (order.status === ORDER_STATUS_ENUMS.PARTIALLY_ACCEPTED) {
      this.onUpdatePartiallyAcceptedOrder(order);
      return;
    }

    let isAnyChecked = false;
    order.orderdetails.forEach((detail) => {
      if (detail.checked) {
        isAnyChecked = true;
      }
    });

    if (!isAnyChecked) {
      this._uiService.presentToast('Accept at least one item to continue.');
      return;
    }
    if (order.paymentMode === 'CASH' || order.paymentMode === 'ONLINE') {
      this._uiService.presentActionSheet(
        'Are you sure you want to complete this order?',
        [
          {
            text: 'Yes',
            handler: () => {
              this.onCompleteAcceptedOrder(order);
            },
          },
          {
            text: 'No',
            role: 'cancel',
          },
        ]
      );
      return;
    }

    order.orderdetails.forEach((detail) => {
      detail.InventoryItemMasterIdFK = detail.item.InventoryItemMasterIdFK;
    });
    const modal = await this.modalCtrl.create({
      component: PaymentModalComponent,
      cssClass: 'payment-modal',
      componentProps: {
        order,
        kitchenUsers: this.kitchenUsers,
      },
    });
    modal.present();
  }

  async onViewInvoice(data: OrderInterface) {
    const modal = await this.modalCtrl.create({
      component: InvoiceComponent,
      componentProps: {
        order: data,
      },
      // cssClass: 'invoice-modal',
    });
    modal.present();
  }

  onChangeAcceptance(e, order: OrderInterface) {
    order.actualWaitingPrice = 0;
    order.actualAcceptedPrice = 0;
    //console.log(order);

    order.orderdetails.forEach((elementNested) => {
      if (elementNested.status === ORDER_STATUS_ENUMS.ACCEPTED) {
        order.actualAcceptedPrice =
          order.actualAcceptedPrice + +elementNested.price;
      }

      if (
        elementNested.status === ORDER_STATUS_ENUMS.WAITING &&
        elementNested.checked
      ) {
        order.actualWaitingPrice =
          order.actualWaitingPrice + +elementNested.price;
      }
    });
  }

  onCompleteAcceptedOrder(order: OrderInterface) {
    this.orderUpdate$ = this._orderService.updateAcceptedOrder(order);

    this.orderUpdate$.subscribe(
      (res) => {
        //console.log(res);
        if (res) {
          this._uiService.presentToast('Order Completed Successfully!');
        }
      },
      (error) => {
        this._uiService.presentToast(error);
      }
    );
  }

  onUpdatePartiallyAcceptedOrder(order: OrderInterface) {
    this.orderUpdate$ = this._orderService.updatePartiallyAcceptedOrder(order);

    this.orderUpdate$.subscribe(
      (res) => {
        //console.log(res);
        if (res) {
          this._uiService.presentToast('Order Updated Successfully!');
        }
      },
      (error) => {
        this._uiService.presentToast(error);
      }
    );
  }

  onUpdateOrderToOutForDelivery(
    order: OrderInterface,
    deliveryPersonId: number
  ) {
    this.orderUpdate$ = this._orderService.updateAcceptedOrderToOutForDelivery(
      order,
      deliveryPersonId
    );

    this.orderUpdate$.subscribe(
      (res) => {
        //console.log(res);
        if (res) {
          this._uiService.presentToast('Order Assigned Successfully!');
        }
      },
      (error) => {
        this._uiService.presentToast(error);
      }
    );
  }

  async onShareInvoice(data: OrderInterface) {
    try {
      await this._uiService.share({
        title: data.orderPlacedId,
        text:
          'Thank you for ordering with us. You can view the detailed invoice at: ' +
          'https://easeinn.app/invoice/' +
          data.OrderIdPK,
        url: 'https://easeinn.app/invoice/' + data.OrderIdPK,
        dialogTitle: 'Order Invoice',
        ...(data.customer.mobile && { customerNumber: data.customer.mobile }),
      });
    } catch (e) {
      this._uiService.presentToast(e);
    }
  }

  onRejectOrder(order: OrderInterface) {
    order.cancellationReason = this.cancellationReason.value;
    this.orderUpdate$ = this._orderService.rejectOrder(order);

    this.orderUpdate$.subscribe({
      next: (res) => {
        console.log('Order Rejected Successfully!');
      },
      error: (_) => {
        this._uiService.presentToast(_);
      },
    });
  }

  cancelDeliverySelectionModal() {
    this.modal.dismiss();
  }

  confirmDeliverySelection() {
    if (!this.selectedPersonFC.valid) {
      this._uiService.presentToast(SELECT_DEVLIVERY_PERSON_ERROR_MSG);
      return;
    }
    this.modal.dismiss(this.selectedPersonFC.value);
  }

  fetchDeliveryPersons() {
    this._deliveryPerson
      .fetchDeliveryPerson({
        VendorIdPK: this.storageData?.userDetail?.VendorIdPK,
        type: 'DELIVERY_PERSON',
      })
      .subscribe({
        next: (res) => {
          this.deliveryPersons = res.data;
        },
      });
  }

  selctionChange(i: number) {
    this.deliveryPersons.forEach((person) => {
      person.isSelected = false;
    });

    this.deliveryPersons[i].isSelected = true;
  }

  showContactInfo(e: Event, order: OrderInterface) {
    if (!order.customer) {
      return;
    }
    this.selectedOrder = order;
    this.popover.event = e;
    this.contactInfoIsOpen = true;
  }

  onContactCustomer(mode: 'CALL' | 'WHATSAPP') {
    if (mode == 'CALL') {
      window.open('tel:' + this.selectedOrder.customer.mobile);
    }
    if (mode === 'WHATSAPP') {
      window.open(
        `https://api.whatsapp.com/send?phone=+91${this.selectedOrder.customer.mobile}&text=Hello`
      );
    }
  }
}
