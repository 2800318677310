import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { STORAGE_CONSTANT } from 'src/app/shared/constants/app.constants';
import {
  StorageInterface,
  StorageService,
} from 'src/app/shared/services/storage.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { environment } from 'src/environments/environment.prod';
import { DeliveryPersonCreateComponent } from '../delivery-person-create/delivery-person-create.component';
import { DeliveryPersonService } from 'src/app/shared/services/delivery-person.service';
import {
  DeliveryPersonFetch,
  DeliveryPersonInterface,
} from 'src/app/shared/models/delivery-person.model';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-delivery-person-listing',
  templateUrl: './delivery-person-listing.page.html',
  styleUrls: ['./delivery-person-listing.page.scss'],
})
export class DeliveryPersonListingPage {
  deliveryPersons: DeliveryPersonInterface[] = [];
  baseUrl = environment.base_url;
  fetchDeliveryPersons$: Observable<any>;
  updateDeliveryPersons$: Observable<any>;
  searchFormControl = new FormControl(null);
  storageData: StorageInterface;
  searchText = '';
  constructor(
    private _deliveryPerson: DeliveryPersonService,
    private _storageService: StorageService,
    private modalCtrl: ModalController,
    private _uiService: UiService
  ) {}

  ionViewWillEnter() {
    this.setStorageData();
    this.loadSearchFilter();
  }
  setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      this.fetchDeliveryPersonData();
    });
  }

  fetchDeliveryPersonData() {
    this.fetchDeliveryPersons$ = this._deliveryPerson.fetchDeliveryPerson({
      VendorIdPK: this.storageData?.userDetail?.VendorIdPK,
      type: 'DELIVERY_PERSON',
      ...(this.searchText && { search: this.searchText }),
    });
    this.fetchDeliveryPersons$.subscribe({
      next: (res: DeliveryPersonFetch) => {
        this.deliveryPersons = res.data;
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  async openAddDeliveryPersonsModal(userDetails?: DeliveryPersonInterface) {
    const modal = await this.modalCtrl.create({
      component: DeliveryPersonCreateComponent,
      animated: true,
      componentProps: {
        VendorIdPK: this.storageData?.userDetail?.VendorIdPK,
        details: userDetails,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchDeliveryPersonData();
      }
    });
    modal.present();
  }
  loadSearchFilter() {
    this.searchFormControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((res: any) => {
          this.searchText = res;
          this.fetchDeliveryPersonData();
        })
      )
      .subscribe();
  }
}
