import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { STORAGE_CONSTANT } from '../shared/constants/app.constants';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import { StoreService } from '../store/store.service';
import { AddTableComponent } from './add-table/add-table.component';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.page.html',
  styleUrls: ['./tables.page.scss'],
})
export class TablesPage implements OnInit {
  @Input() isModal: boolean = false;
  tableStatusFilter = 'ALL';
  storageData: StorageInterface;
  fetchTables$: Observable<any>;
  tables: TableInterface[] = [];
  tableStatusCounts: {
    all: number;
    occupied: number;
    vacant: number;
  } = { all: 0, occupied: 0, vacant: 0 };

  constructor(
    private _modal: ModalController,
    private _storeService: StoreService,
    private _storageService: StorageService,
    private _uiService: UiService,
    private _location: Location
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      this.fetchAllTables();
    });
  }

  fetchAllTables() {
    this.tableStatusCounts = { all: 0, occupied: 0, vacant: 0 };
    this.fetchTables$ = this._storeService.getAllTablesOfStore(
      this.storageData?.userDetail?.VendorIdPK
    );

    this.fetchTables$.subscribe((res) => {
      this.tables = res;

      this.tables.forEach((table) => {
        if (table.floorName !== 'Not Available') {
          table.seats.forEach((seat) => {
            if (seat.tableStatus === 'ENGAGED') {
              this.tableStatusCounts.occupied += 1;
            }
            if (
              seat.tableStatus === 'AVAILABLE' ||
              seat.tableStatus === 'ACTIVE'
            ) {
              this.tableStatusCounts.vacant += 1;
            }

            this.tableStatusCounts.all += 1;
          });
        }
      });
    });
  }

  dimissModal() {
    this._modal.dismiss();
  }

  filterTablesByStatus(status) {
    this.tableStatusFilter = status;
    let removeTableStatus =
      status === 'ALL'
        ? 'ALL'
        : status === 'OCCUPIED'
        ? 'ENGAGED'
        : 'AVAILABLE';

    this.tables.forEach((table) => {
      table.seats.forEach((seat) => {
        seat.isVisible = true;

        if (removeTableStatus === 'ALL') {
          seat.isVisible = true;
        } else if (removeTableStatus === 'AVAILABLE') {
          if (
            seat.tableStatus !== 'ACTIVE' &&
            seat.tableStatus !== 'AVAILABLE'
          ) {
            seat.isVisible = false;
          }
        } else if (seat.tableStatus !== removeTableStatus) {
          seat.isVisible = false;
        }
      });
    });
  }

  async openAddTableModal(seat?: Seat) {
    const modal = await this._modal.create({
      component: AddTableComponent,
      cssClass: 'add-table-modal',
      componentProps: { storageData: this.storageData, ...(seat && { seat }) },
    });

    modal.onDidDismiss().then((res) => {
      if (res.data) {
        this.fetchAllTables();
      }
    });
    modal.present();
  }

  onSelectTable(seat: Seat) {
    if (this.isModal === true) {
      if (seat.tableStatus === 'INACTIVE') {
        this._uiService.presentToast(
          'Unable to select this table beacuse it is inactive currently.'
        );
        return;
      }
      this._modal.dismiss(seat);
    } else {
      this.openAddTableModal(seat);
    }
  }

  goToPreviosScreen() {
    this._location.back();
  }
}

export interface TableInterface {
  floorName: string;
  seats: Seat[];
}
export interface DiningArea {
  DiningAreaIdPK: number;
  VendorIdFK: number;
  name: string;
}
export interface Seat {
  TableIdPK: number;
  VendorIdFK: number;
  OrderIdFK?: number;
  tableNumber: number;
  tableStatus: string;
  tableShape: string;
  maxCapacity: number;
  tableDescription: string;
  DiningAreaIdFK?: number;
  dining_area: DiningArea;
  styleClass: string;
  tableDisplayStatus: string;
  tableIconName: string;
  isVisible: boolean;
}
