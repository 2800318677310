import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { StoreService } from 'src/app/store/store.service';
import { UiService } from '../../services/ui.service';
import {
  StorageInterface,
  StorageService,
} from '../../services/storage.service';
import { STORAGE_CONSTANT } from '../../constants/app.constants';

@Component({
  selector: 'app-item-customization-modal',
  templateUrl: './item-customization-modal.component.html',
  styleUrls: ['./item-customization-modal.component.scss'],
})
export class ItemCustomizationModalComponent implements OnInit {
  @Input() data: any;
  fetchItems$: Observable<any>;
  fetchAddOns$: Observable<any>;
  items: any[] = [];
  addOns: any[] = [];
  variantFormControl = new FormControl(null, Validators.required);
  addOnFormGroup: FormGroup;
  isVariant = false;
  storageData: StorageInterface;

  constructor(
    public _modalCtrl: ModalController,
    private _storeService: StoreService,
    private _formBuilder: FormBuilder,
    private _uiService: UiService,
    private _storageService: StorageService
  ) {}

  ngOnInit() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
    });

    if (this.data.variantIds !== '' && this.data.variantIds !== null) {
      this.isVariant = true;
      this.fetchItems();
    }

    if (this.data.isAddOnExists) {
      this.buildAddOnForm();
      this.fetchAddOns();
    }
  }

  buildAddOnForm() {
    this.addOnFormGroup = this._formBuilder.group({
      addOns: this._formBuilder.array([]),
    });
  }

  get addOnsFormArray(): FormArray {
    return this.addOnFormGroup.get('addOns') as FormArray;
  }

  fetchItems() {
    this.fetchItems$ = this._storeService.fetchItemByIds({
      itemIds: this.data.variantIds,
    });

    this.fetchItems$.subscribe((res) => {
      if (res) {
        this.items = res;
      }
    });
  }

  fetchAddOns() {
    this.fetchAddOns$ = this._storeService.fetchAddOns({
      ItemIdPK: this.data.ItemIdPK,
    });

    this.fetchAddOns$.subscribe((res) => {
      if (res) {
        this.addOns = res;

        this.addOns.forEach((addOn) => {
          this.addOnsFormArray.push(
            this._formBuilder.group({
              name: new FormControl(addOn.name),
              price: new FormControl(addOn.price),
              checked: new FormControl(false),
              InventoryItemMasterIdFK: new FormControl(
                addOn.InventoryItemMasterIdFK
              ),
              quantity: new FormControl(addOn.quantity),
            })
          );
        });
      }
    });
  }

  onAddItemToCart() {
    let sentObj: {
      variant: any;
      addOnDetails: any[];
      InventoryItemMasterIdFK?: number;
    } = {} as any;

    sentObj.addOnDetails = [];
    if (this.addOnFormGroup) {
      this.addOnsFormArray.controls.forEach((cntrl) => {
        if (cntrl.value.checked) {
          sentObj.addOnDetails.push({
            name: cntrl.value.name,
            price: cntrl.value.price,
            ...(cntrl.value.InventoryItemMasterIdFK && {
              InventoryItemMasterIdFK: cntrl.value.InventoryItemMasterIdFK,
            }),
            ...(cntrl.value.InventoryItemMasterIdFK && {
              quantity: cntrl.value.quantity,
            }),
          });
        }
      });
    }

    if (this.isVariant) {
      if (!this.variantFormControl.valid) {
        this._uiService.presentToast('Select at least one variant');
        return;
      }

      sentObj.variant = {
        ItemIdPK: +this.variantFormControl.value,
        price: this.getVariantPrice,
      };

      const foundIndex = this.items.findIndex(
        (_) => +_.ItemIdPK === +this.variantFormControl.value
      );
      if (foundIndex && this.items[foundIndex].InventoryItemMasterIdFK)
        sentObj.InventoryItemMasterIdFK =
          this.items[foundIndex].InventoryItemMasterIdFK;
    }

    this._modalCtrl.dismiss(sentObj);
  }

  get totalPrice(): number {
    return +this.getVariantPrice + this.getAddOnCheckedPrice;
  }

  get getAddOnCheckedPrice(): number {
    let total = 0;
    if (this.addOnFormGroup) {
      this.addOnsFormArray.controls.forEach((cntrl) => {
        if (cntrl.value.checked) {
          total += +cntrl.value.price;
        }
      });
    }

    return total;
  }

  get getVariantPrice() {
    // this condition when no variant only addons are there then we will have base item price
    if (!this.isVariant) {
      return this.data.price;
    }
    let index = this.items.find(
      (item) => +item.ItemIdPK === +this.variantFormControl?.value
    );
    return index ? index.price : 0;
  }
}
