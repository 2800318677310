import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-custom-ng-select',
  templateUrl: './custom-ng-select.component.html',
  styleUrls: ['./custom-ng-select.component.scss'],
})
export class CustomNgSelectComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() isMultiple: boolean = false;
  @Input() fieldName: string;
  @Input() formGroup: FormGroup;
  @Input() isSearchable = true;
  @Output() searchEmitter: EventEmitter<any> = new EventEmitter();
  @Output() selectionEmitter: EventEmitter<any> = new EventEmitter();
  @Input() isAddTag = false;
  selectedValue: any;
  searchInput$ = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Output() addNewEventEmitter: EventEmitter<string> =
    new EventEmitter<string>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Output() selectedValue$: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    console.log('inside-add', this.formGroup, this.fieldName, this.data);
  }

  ngOnInit() {
    this.loadSearchFilter();
  }

  loadSearchFilter() {
    this.searchInput$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((res) => {
          console.log(res);
          this.searchEmitter.emit(res);
        })
      )
      .subscribe();
  }

  onCreateNew = (e) => {
    this.addNewEventEmitter.emit(e);
  };

  onSelectItem(e: any) {
    this.selectionEmitter.emit(this.selectedValue);
  }
}
