import { Component, OnInit } from '@angular/core';
import {
  CURRENCY_TYPE,
  STORAGE_CONSTANT,
} from '../shared/constants/app.constants';
import { StoreService } from '../store/store.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormErrorService } from '../shared/services/form-error.service';
import { FormService } from '../shared/services/form.service';
import { UiService } from '../shared/services/ui.service';
import {
  StorageInterface,
  StorageService,
} from '../shared/services/storage.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
})
export class SettingsPage implements OnInit {
  currency = CURRENCY_TYPE;
  preferencesFormGroup: FormGroup;
  whatsappPrefFC = new FormControl(null);
  defaultQuickOrderPrefFC = new FormControl(null);
  storageData: StorageInterface;
  themeToggle = false;
  constructor(
    private _formErrorService: FormErrorService,
    private _formBuilder: FormBuilder,
    private _formService: FormService,
    private _storeService: StoreService,
    private _uiService: UiService,
    private _storageService: StorageService
  ) {}

  ngOnInit() {}
  ionViewWillEnter() {
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(STORAGE_CONSTANT).then((res: any) => {
      this.storageData = res;
      this.setPrefs();
      this.onCheckDarkMode();
    });
  }

  toggleChange(ev) {
    this.toggleDarkTheme(ev.detail.checked);
  }

  async toggleDarkTheme(shouldAdd: boolean) {
    this.themeToggle = shouldAdd;
    this.storageData.isDarkMode = shouldAdd;
    await this._storageService.setObject(STORAGE_CONSTANT, this.storageData);
    document.body.classList.toggle('dark', shouldAdd);
  }

  
  onCheckDarkMode() {
    if (this.storageData && this.storageData.isDarkMode !== undefined) {
      this.initializeDarkTheme(this.storageData.isDarkMode);
      if (this.storageData.isDarkMode) {
        this.themeToggle = true; // Set themeToggle to true if dark mode is enabled
      }
    } else {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      this.initializeDarkTheme(prefersDark.matches);
      if (prefersDark.matches) {
        this.themeToggle = true; // Set themeToggle to true if dark mode is enabled
      }
      prefersDark.addEventListener('change', (mediaQuery) =>
        this.initializeDarkTheme(mediaQuery.matches)
      );
    }
  }
  initializeDarkTheme(isDark: boolean) {
    console.log('isInitial', isDark);
    this.themeToggle = isDark;
    this.toggleDarkTheme(isDark);
  }
  // to set default view
  setPrefs() {
    if (!this.storageData.appPrefs) {
      this.storageData.appPrefs = {
        whatsappPref: false,
        defaultQuickOrder: false,
      };
      return;
    }
    if (this.storageData.appPrefs.whatsappPref) {
      this.whatsappPrefFC.patchValue(true);
    }
    if (this.storageData.appPrefs.defaultQuickOrder) {
      this.defaultQuickOrderPrefFC.patchValue(true);
    }
  }

  async onSetWhatsappPref(e: any) {
    this.whatsappPrefFC.patchValue(e.detail.checked);
    this.storageData.appPrefs.whatsappPref = e.detail.checked;

    await this._storageService.setObject(STORAGE_CONSTANT, this.storageData);
  }

  async onSetdefaultQuickOrderPref(e: any) {
    this.defaultQuickOrderPrefFC.patchValue(e.detail.checked);
    this.storageData.appPrefs.defaultQuickOrder = e.detail.checked;
    await this._storageService.setObject(STORAGE_CONSTANT, this.storageData);
  }

  buildForm() {
    this.preferencesFormGroup = this._formBuilder.group({});
  }
}
