import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { CouponInterface } from '../coupons/coupons.page';
import { FormErrorService } from '../shared/services/form-error.service';
import { FormService } from '../shared/services/form.service';
import { UiService } from '../shared/services/ui.service';
import { StoreService } from '../store/store.service';

@Component({
  selector: 'app-coupon-create',
  templateUrl: './coupon-create.component.html',
  styleUrls: ['./coupon-create.component.scss'],
})
export class CouponCreateComponent implements OnInit {
  @Input() coupon: any;
  mode;
  addCouponFormGroup!: FormGroup;
  addCoupon$!: Observable<any>;
  updateCoupon$!: Observable<any>;
  deleteCoupon$!: Observable<any>;
  constructor(
    public modalCtrl: ModalController,
    private _formBuilder: FormBuilder,
    private _uiService: UiService,
    private _storeService: StoreService,
    private _formErrorService: FormErrorService,
    private _formService: FormService
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.existingCoupon();
  }

  existingCoupon() {
    if (this.coupon) {
      this.addCouponFormGroup.patchValue(this.coupon);
      this.addCouponFormGroup
        .get('appVisibility')
        .setValue(this.coupon.appVisibility ? '1' : '0');
      this.addCouponFormGroup
        .get('expiryDateView')
        .setValue(this.coupon.expiryDate.split(' ')[0]);
      this.addCouponFormGroup
        .get('expiryTimeView')
        .setValue(this.coupon.expiryDate.split(' ')[1]);
    }
  }

  buildForm() {
    this.addCouponFormGroup = this._formBuilder.group({
      code: new FormControl('', [Validators.required]),
      discount: new FormControl('', [Validators.required]),
      discountType: new FormControl('PERCENTAGE', [Validators.required]),
      appVisibility: new FormControl('1', [Validators.required]),
      expiryDateView: new FormControl('',[Validators.required]),
      expiryTimeView: new FormControl('',[Validators.required]),
      status: new FormControl('ACTIVE', [Validators.required]),
      cartMinValue: new FormControl('', [Validators.required]),
      cartMaxValue: new FormControl('', [Validators.required]),
      maximumAllowedDiscount: new FormControl('', [Validators.required]),
    });
  }

  addCoupon() {
    this._formService.markFormAsTouched(this.addCouponFormGroup);
    if (!this.addCouponFormGroup.valid) {
      this._uiService.presentToast('Enter Valid Data ');
      return;
    }
    this.addCouponFormGroup.value.expiryDate = this.getExpiryDateFormat();
    if (this.mode === 'EDIT') {
      this.updateCoupon$ = this._storeService.updateCoupon(
        this.coupon.CouponIdPK,
        this.addCouponFormGroup.value
      );
      this.updateCoupon$.subscribe(
        async (res) => {
          if (res) {
            await this._uiService.presentToast('Coupon Update sucessfully!');
            this.addCouponFormGroup.reset();
            this.modalCtrl.dismiss(true);
          }
        },
        (error) => {
          this._uiService.presentToast(error);
        }
      );
    } else {
      this.addCoupon$ = this._storeService.createCoupon(
        this.addCouponFormGroup.value
      );
      this.addCoupon$.subscribe(
        async (res) => {
          if (res) {
            await this._uiService.presentToast('Coupon Create sucessfully!');
            this.addCouponFormGroup.reset();
            this.modalCtrl.dismiss(true);
          }
        },
        (error) => {
          this._uiService.presentToast(error);
        }
      );
    }
  }

  getExpiryDateFormat() {
    if (this.mode === 'ADD') {
      return (
        this.addCouponFormGroup.value.expiryDateView +
        ' ' +
        this.addCouponFormGroup.value.expiryTimeView +
        ':00'
      );
    } else {
      if (
        this.addCouponFormGroup.value.expiryTimeView ===
        this.coupon.expiryDate.split(' ')[1]
      ) {
        return (
          this.addCouponFormGroup.value.expiryDateView +
          ' ' +
          this.addCouponFormGroup.value.expiryTimeView
        );
      } else {
        return (
          this.addCouponFormGroup.value.expiryDateView +
          ' ' +
          this.addCouponFormGroup.value.expiryTimeView +
          ':00'
        );
      }
    }
  }

  async onDeleteCoupon(coupon: CouponInterface) {
    if (coupon) {
      this._uiService.presentActionSheet(
        'Are you sure to delete this Coupon!',
        [
          {
            text: 'Yes',
            handler: () => {
              this.deleteCoupon(coupon);
            },
          },
          {
            text: 'No',
            role: 'cancel',
          },
        ]
      );
      return;
    }
  }
  async deleteCoupon(coupon: CouponInterface) {
    this.deleteCoupon$ = this._storeService.deletCoupon(coupon.CouponIdPK);
    this.deleteCoupon$.subscribe({
      next: (res) => {
        if (res) {
          this._uiService.presentToast('Coupon Deleted Successfully!');
          this.addCouponFormGroup.reset();
          this.modalCtrl.dismiss(true);
        }
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  public getErrorMessage(fieldName: string, error: string): string {
    return this._formErrorService.getErrorMessage(fieldName, error);
  }
}
