import { Component, Input, OnInit } from '@angular/core';
import { OrderInterface } from 'src/app/shared/services/order.service';

@Component({
  selector: 'app-completed-orders',
  templateUrl: './completed-orders.page.html',
  styleUrls: ['./completed-orders.page.scss'],
})
export class CompletedOrdersPage implements OnInit {
  @Input() orders: OrderInterface[] = [];
  constructor() {}

  ngOnInit() {}
}
