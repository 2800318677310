import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { STORAGE_CONSTANT } from '../constants/app.constants';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private storageService: StorageService, private router: Router) {}

  async canActivate() {
    if (await this.storageService.getObject(STORAGE_CONSTANT)) {
      return true;
    } else {
      this.router.navigate(['/auth']);
      return false;
    }
  }
}
