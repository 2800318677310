import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ItemAddonCreateComponent } from '../item-addon-create/item-addon-create.component';
import { MenuItem } from '../shared/models/menu.model';
import { StorageInterface } from '../shared/services/storage.service';
import {
  AddOnsList,
  Category,
  MealTimeList,
  StoreService,
} from '../store/store.service';

@Component({
  selector: 'app-item-addons',
  templateUrl: './item-addons.component.html',
  styleUrls: ['./item-addons.component.scss'],
})
export class ItemAddonsComponent implements OnInit {
  @Input() data: {
    categories: Category[];
    mealTimes: MealTimeList[];
    storageData: StorageInterface;
    item?: MenuItem;
  };
  fetchAddOns$: Observable<any>;
  addOns: AddOnsList[] = [];

  constructor(
    private modalCtrl: ModalController,
    private _storeService: StoreService
  ) {}

  ngOnInit() {
    this.fetchAddOns();
  }

  fetchAddOns() {
    this.fetchAddOns$ = this._storeService.fetchAddOns({
      ItemIdPK: this.data.item.ItemIdPK,
    });

    this.fetchAddOns$.subscribe((res) => {
      console.log('addons', res);
      this.addOns = res;
    });
  }

  async openAddonModal(mode, addOn?: AddOnsList) {
    const modal = await this.modalCtrl.create({
      component: ItemAddonCreateComponent,
      animated: true,
      cssClass: 'addon-modal',
      componentProps: {
        mode: mode,
        data: this.data,
        addOn: addOn,
      },
    });

    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchAddOns();
      }
    });
    modal.present();
  }
}
