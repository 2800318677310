import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MenuItem } from '../shared/models/menu.model';
import { StorageInterface } from '../shared/services/storage.service';
import { UiService } from '../shared/services/ui.service';
import { Category, MealTimeList, StoreService } from '../store/store.service';

@Component({
  selector: 'app-item-recommendations',
  templateUrl: './item-recommendations.component.html',
  styleUrls: ['./item-recommendations.component.scss'],
})
export class ItemRecommendationsComponent implements OnInit {
  @Input() data: {
    categories: Category[];
    mealTimes: MealTimeList[];
    storageData: StorageInterface;
    item?: MenuItem;
  };
  item$: Observable<any>;
  items: MenuItem[] = [];
  allItems: MenuItem[] = [];
  searchFormControl: FormControl = new FormControl(null);

  constructor(
    private _storeService: StoreService,
    private _uiService: UiService
  ) {}

  ngOnInit() {
    this.fetchAllItems();
  }

  loadSearchFilter() {
    this.searchFormControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((search) => {
          if (!search && search === '') {
            this.items = this.allItems;
            return;
          }

          this.items = this.allItems.filter((item) => {
            return item.name.toLowerCase().includes(search.toLowerCase());
          });
        })
      )
      .subscribe();
  }

  fetchAllItems() {
    this.item$ = this._storeService.fetchItem({
      VendorIdPK: this.data.storageData.userDetail.VendorIdPK,
    });

    this.item$.subscribe({
      next: (res) => {
        this.items = res.data;
        this.patchValues();
        this.allItems = JSON.parse(JSON.stringify(this.items));
        this.loadSearchFilter();
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  patchValues() {
    if (this.data.item.recommendedIds) {
      this.data.item.recommendedIds.split(',').forEach((item) => {
        this.items.forEach((nItem) => {
          if (nItem.ItemIdPK === +item) {
            nItem.isChecked = true;
          }
        });
      });
    }
  }

  onUpdateRecommendations() {
    let ids = [];
    this.items.forEach((nItem) => {
      if (nItem.isChecked) {
        ids.push(nItem.ItemIdPK);
      }
    });
    this.item$ = this._storeService.updateRecommendations({
      recommendedIds: ids.join(),
      ItemIdPK: this.data.item.ItemIdPK,
    });

    this.item$.subscribe({
      next: (res) => {
        if (res) {
          this._uiService.presentToast('Recommendations Updated Successfully!');
          this._storeService.itemChanged.next(true);
        }
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  onChange(e, i: number) {
    this.allItems[i].isChecked = e.detail.checked;
    this.items[i].isChecked = e.detail.checked;
  }
}
