import { Component, Input, OnInit, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { STORAGE_CONSTANT } from 'src/app/shared/constants/app.constants';
import {
  Recipes,
  RecipeFetch,
  RecipeIngredientsFetch,
} from 'src/app/shared/models/recipes.model';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { FormService } from 'src/app/shared/services/form.service';
import {
  InventoryItemMaster,
  InventoryService,
} from 'src/app/shared/services/inventory.service';
import { RecipesService } from 'src/app/shared/services/recipes.service';
import {
  StorageInterface,
  StorageService,
} from 'src/app/shared/services/storage.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { environment } from 'src/environments/environment.prod';
import { Ingredients } from '../../shared/models/recipes.model';

@Component({
  selector: 'app-recipe-ingredients',
  templateUrl: './recipe-ingredients.page.html',
  styleUrls: ['./recipe-ingredients.page.scss'],
})
export class RecipeIngredientsPage implements OnInit {
  inventoryItemMasters: InventoryItemMaster[] = [];
  ingredients: InventoryItemMaster[] = [];
  allRecipes: Recipes[] = [];
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() details: Recipes = {} as Recipes;
  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
  @Input() vendorIdPK: string;
  recipes: any[] = [];
  baseUrl = environment.base_url;
  fetchIngredients$!: Observable<RecipeIngredientsFetch>;
  storageData: StorageInterface;
  fetchRecipe$!: Observable<RecipeFetch>;
  recipeFormGroup!: FormGroup;
  _service = inject(InventoryService);
  searchInput$ = new Subject<any>();
  recipeIngredients: Ingredients[] = [];
  // isExistRecipeIngredients: boolean = false;
  constructor(
    public modalCtrl: ModalController,
    private storageService: StorageService,
    private recipeService: RecipesService,
    private uiService: UiService,
    private formErrorService: FormErrorService,
    private formBuilder: FormBuilder,
    private formService: FormService
  ) {}

  ngOnInit() {
    this.buildRecipeForm();
    this.loadSearchFilter();
    this.forUnitPatch();
    console.log(this.details);
  }
  ionViewWillEnter() {
    this.setStorageData();
  }
  buildRecipeForm() {
    this.recipeFormGroup = this.formBuilder.group({
      recipe: new FormControl(),
      recipes: this.formBuilder.array([
        this.formBuilder.group({
          InventoryItemMasterIdFK: new FormControl(''),
          quantity: new FormControl(''),
          RecipeIdFK: new FormControl(this.details.RecipeIdPK),
          unit: new FormControl(''),
        }),
      ]),
    });
  }
  onChangeUnit(e: InventoryItemMaster, i: number) {
    if (e) {
      const unitValue = e.inventory_item_unit.unit;
      const control = this.ingredientFormArray.at(i).get('unit');
      if (control) {
        control.setValue(unitValue);
      }
    } else {
      this.ingredientFormArray.at(i).get('unit').reset();
      this.getUnitValue(i);
    }
  }
  getUnitValue(index: number): string {
    const control = this.ingredientFormArray.at(index).get('unit');
    return control ? control.value : '';
  }
  onRemoveIngredient(i: number) {
    const ingredient = this.ingredientFormArray.at(i).value;

    if (ingredient?.IngredientIdPK) {
      this.onDelete(ingredient);
    } else {
      this.ingredientFormArray.removeAt(i);
    }
  }
  addIngredientFormGroup(data?: Ingredients) {
    console.log(data);

    this.ingredientFormArray.push(
      this.formBuilder.group({
        InventoryItemMasterIdFK: new FormControl(
          data ? data?.InventoryItemMasterIdFK : null
        ),
        IngredientIdPK: new FormControl(data ? data.IngredientIdPK : null),
        quantity: new FormControl(data ? data.quantity : null),
        RecipeIdFK: new FormControl(this.details?.RecipeIdPK),
        unit: new FormControl(
          data ? data.inventory_item_master.inventory_item_unit?.unit : null
        ),
      })
    );
  }

  get ingredientFormArray(): FormArray {
    return this.recipeFormGroup.get('recipes') as FormArray;
  }

  onCopyRecipe() {
    let RecipeIdPK = this.recipeFormGroup.value.recipe[0].RecipeIdPK;
    this.fetchIngredient(RecipeIdPK);
  }

  setStorageData() {
    this.storageService.getObject(STORAGE_CONSTANT).then((res) => {
      this.storageData = res;
      this.fetch();
    });
  }
  fetchIngredient(copyRecipeId?: string | number) {
    this.recipeService
      .fetchIngredients({
        ...(this.recipeIngredients.length < 1 && {
          recipeId: this.details.RecipeIdPK,
        }),
        ...(copyRecipeId && {
          recipeId: copyRecipeId,
        }),
      })
      .subscribe({
        next: (res: RecipeIngredientsFetch) => {
          this.recipeIngredients = res.data;
          if (res.data.length > 0 && !copyRecipeId) {
            // this.isExistRecipeIngredients = true;
            this.onPatchIngredient(res.data);
            this.ingredients = this.inventoryItemMasters;
          }
          if (res.data.length > 0 && copyRecipeId) {
            this.onPatchIngredient(res.data);
            this.ingredients = this.inventoryItemMasters;
          }
          if (res.data.length < 1 && copyRecipeId) {
            this.uiService.presentToast('No Ingredients Found');
            this.resetFormControls();
            // if (this.isExistRecipeIngredients) {
            //   this.isExistRecipeIngredients = false;
            // }
          }
        },
        error: (err) => {
          this.uiService.presentToast(err);
        },
      });
  }

  resetFormControls() {
    const formArray = this.ingredientFormArray;
    formArray.controls.forEach((control) => {
      control.reset({
        InventoryItemMasterIdFK: '',
        quantity: '',
        RecipeIdFK: this.details.RecipeIdPK,
        unit: '',
      });
    });

    if (formArray.length > 1) {
      while (formArray.length !== 1) {
        formArray.removeAt(1);
      }
    }
  }

  onPatchIngredient(ingredient: Ingredients[]) {
    (this.ingredientFormArray.controls = []),
      ingredient.forEach((res: Ingredients) => {
        this.addIngredientFormGroup(res);
        this.forUnitPatch();
      });
  }
  forUnitPatch() {
    const valueChangesSubscription = this.recipeFormGroup
      .get('recipes')
      .valueChanges.subscribe((recipes) => {
        const lastRecipeIndex = recipes.length - 1;
        const lastRecipe = recipes[lastRecipeIndex];

        const InventoryItemIdFK = lastRecipe.InventoryItemIdFK;

        const matchedItem = this.inventoryItemMasters.find(
          (item) => item.InventoryItemMasterIdPK === InventoryItemIdFK
        );
        const control = this.ingredientFormArray
          .at(lastRecipeIndex)
          .get('unit');

        if (matchedItem && control.value === null) {
          this.onChangeUnit(matchedItem, lastRecipeIndex);
        }

        valueChangesSubscription.unsubscribe();
      });
  }

  fetch() {
    this.fetchRecipe$ = this.recipeService.fetchRecipes({
      vendorId: this.storageData?.userDetail?.VendorIdPK,
    });
    this.fetchRecipe$.subscribe({
      next: (res: RecipeFetch) => {
        const newData = res.data;
        this.allRecipes = newData.filter(
          (recipe) => recipe.RecipeIdPK !== this.details.RecipeIdPK
        );
        this.fetchInventoryMasterItems();
      },
      error: (err) => {
        this.uiService.presentToast(err);
      },
    });
  }
  fetchInventoryMasterItems() {
    this._service
      .fetchInventoryMasterItem({
        vendorId: this.storageData?.userDetail?.VendorIdPK,
      })
      .subscribe({
        next: (_) => {
          this.inventoryItemMasters = _.data;
          this.fetchIngredient();
        },
        error: (_) => {
          this.uiService.presentToast(_);
        },
      });
  }

  onIngredientSearch(e) {
    this.ingredients = this.inventoryItemMasters.filter((item) => {
      if (item && e) {
        return item.name.toLowerCase().includes(e.toLowerCase());
      }
    });
  }

  onRecipeSearch(e) {
    this.recipes = this.allRecipes.filter((item) => {
      if (item && e) {
        return item.name.toLowerCase().includes(e.toLowerCase());
      }
    });
  }
  onCreate() {
    if (this.recipeIngredients.length > 0) {
      this.onUpdate();
      return;
    }
    const formData = this.recipeFormGroup.value.recipes.map((element) => {
      const { unit, IngredientIdPK, ...formDataWithoutUnitAndIngredientIdPK } =
        element;
      return formDataWithoutUnitAndIngredientIdPK;
    });
    this.recipeService
      .createManageIngredients({ recipes: formData })
      .subscribe({
        next: (res) => {
          this.uiService.presentToast('Created Successfully');
          this.modalCtrl.dismiss({ data: true });
        },
        error: (err) => {
          this.uiService.presentToast(err);
        },
      });
  }
  onUpdate() {
    const formData = this.recipeFormGroup.value.recipes.map((element) => {
      const { unit, ...formDataWithoutUnit } = element;
      return formDataWithoutUnit;
    });
    this.recipeService
      .updateManageIngredients({ recipes: formData })
      .subscribe({
        next: (res) => {
          this.uiService.presentToast('Updated Successfully');
          this.modalCtrl.dismiss({ data: true });
        },
        error: (err) => {
          this.uiService.presentToast(err);
        },
      });
  }
  loadSearchFilter() {
    this.searchInput$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((res) => {
          this.onIngredientSearch(res);
        })
      )
      .subscribe();
  }
  onDelete(ingredient: Ingredients) {
    this.uiService.presentActionSheet(
      'Are you sure to delete this Ingredients!',
      [
        {
          text: 'Yes',
          handler: () => {
            this.recipeService
              .deleteManageIngredients(ingredient.IngredientIdPK)
              .subscribe({
                next: (_) => {
                  this.uiService.presentToast('deleted successfully');
                  this.fetchIngredient(ingredient.RecipeIdFK);
                },
              });
          },
        },
        {
          text: 'No',
          role: 'cancel',
        },
      ]
    );
  }
}
